import { useEffect, useState } from "react";
import styled from "../SoilInformationsEdit/SoilInformationsEdit.module.scss";
import Button from "components/common/button/Button";
import commonMessages from "components/common/CommonMessages";
import { FormattedMessage, useIntl } from "react-intl";
import SoilInformationsCreateMessages from "../SoilInformationsCreate/SoilInformationsCreateMessages";
import { useNavigate, useParams } from "react-router-dom";
import MainContent from "components/MainContent/MainContent";
import { useDispatch, useSelector } from "react-redux";
import Input from "components/common/input/Input";
import CheckBox from "components/common/checkBox/CheckBox";
import { ROUTES } from "constants/routes";
import { Loader } from "components/common/loader/Loader";
import EditName from "components/EditName/EditName";
import { getSoilInformations } from "store/soil_informations/actions";
import { getSoilInformationsListState } from "store/soil_informations/selectors";

interface IDefaultSoil {
  name: String;
  rfu_water: number;
  ru_water: number;
  id: number;
  irrigation: boolean;
}

function DefaultSoilsInformations() {
  const { client_id, project_id, id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const intl = useIntl();
  const [defaultSoil, setDefaultSoil] = useState<IDefaultSoil>();
  const soil_informations = useSelector(getSoilInformationsListState);

  useEffect(() => {
    dispatch(
      getSoilInformations({ clientId: client_id, projectId: project_id })
    );
  }, [client_id, project_id]);

  useEffect(() => {
    const interestSoil = soil_informations.filter(
      (element: any) => element.id == id
    );
    if (!interestSoil) return;
    setDefaultSoil(interestSoil[0]);
  }, [soil_informations, id]);

  const onBack = () =>
    navigate(
      ROUTES.SOIL_INFORMATIONS.replace(
        ":client_id",
        client_id as string
      ).replace(":project_id", project_id as string)
    );

  if (defaultSoil)
    return (
      <div>
        <MainContent
          header={
            <>
              <p className={styled.defaultName}>
                {defaultSoil.name} - (Default)
              </p>
              <div className={styled.flex}>
                <Button variant="text" onClick={onBack}>
                  <FormattedMessage {...commonMessages.back} />
                </Button>
              </div>
            </>
          }
        >
          <div>
            <h2 className={styled["section-title"]}>
              <FormattedMessage
                {...SoilInformationsCreateMessages.infomations}
              />
            </h2>

            <div className={styled.informationContainer}>
              <Input
                name="ru_water"
                type="number"
                label={intl.formatMessage(
                  SoilInformationsCreateMessages.ru_water
                )}
                value={defaultSoil.ru_water}
                className={styled.informationsInput}
                disabled
              ></Input>
              <Input
                name="rfu_water"
                type="number"
                label={intl.formatMessage(
                  SoilInformationsCreateMessages.rfu_water
                )}
                value={defaultSoil.rfu_water}
                className={styled.informationsInput}
                disabled
              ></Input>
              <CheckBox
                label={intl.formatMessage(
                  SoilInformationsCreateMessages.irrigation
                )}
                checked={defaultSoil.irrigation}
                disabled
              />
            </div>
          </div>
        </MainContent>
      </div>
    );
  else return <Loader />;
}

export default DefaultSoilsInformations;
