import { Modal } from "react-bootstrap";
import styled from "./styled.module.scss";
import { ReactComponent as XMark } from "assets/icons/x-mark.svg";
import { ReactComponent as Check } from "assets/icons/check.svg";
import { useEffect, useState } from "react";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { getSubscriptionDatas } from "store/subscription/selector";

interface IModalSubscribe {
  isOpen: boolean;
  setOpen: (value: boolean) => void;
}

const ModalSubscribe = ({ isOpen = false, setOpen }: IModalSubscribe) => {
  const [planId, setPlanId] = useState(0);
  const subscription = useSelector(getSubscriptionDatas);

  useEffect(() => {
    if (subscription) setPlanId(subscription.plan);
  }, [subscription, isOpen]);

  return (
    <Modal
      show={isOpen}
      onHide={() => {
        setOpen(false);
      }}
      centered
    >
      <section className={styled.modalContainer}>
        <header>
          <p> Optisoleo subscription </p>
          <XMark onClick={() => setOpen(false)} />
        </header>
        <div className={styled.btnChoicesContainer}>
          <input
            type="radio"
            id="0"
            name="plan"
            className={styled.begginer}
            value={0}
            onChange={() => setPlanId(0)}
            checked={planId === 0}
          />
          <label htmlFor="0"> Beginner </label>
          <input
            type="radio"
            id="1"
            name="plan"
            className={styled.medium}
            value={1}
            onChange={() => setPlanId(1)}
            checked={planId === 1}
          />
          <label htmlFor="1"> Medium </label>
          <input
            type="radio"
            id="2"
            name="plan"
            className={styled.premium}
            value={2}
            onChange={() => setPlanId(2)}
            checked={planId === 2}
          />
          <label htmlFor="2"> Premium </label>

          <div className={styled.optionSelected}>&nbsp;</div>
        </div>
        <div className={styled.pricing}>
          <p className={styled.priceInfos}>
            {planId === 0 ? 1000 : planId === 1 ? 10000 : 100000}$/Year
          </p>
          <p className={styled.subTitle}>
            {" "}
            {planId === 0
              ? "Access to basics features, optimize your installation and access to irradiance results..."
              : planId === 1
              ? "Access to principals features, optimize your installation and access to all results..."
              : "Access to all profesionals features for customizing and optimizing your installation, access to all available results..."}
          </p>
        </div>
        <div className={styled.horizontalLimiter}>&nbsp;</div>
        <ul className={styled.featuresList}>
          <li className={styled.hasFeature}>
            <Check />
            <p> Project limit {planId === 0 ? 5 : planId === 1 ? 10 : 20} </p>
          </li>
          <li className={styled.hasFeature}>
            {" "}
            <Check />
            <p>
              {" "}
              {planId === 0 ? 12 : planId === 1 ? 18 : 24} months project
              expiration{" "}
            </p>{" "}
          </li>
          <li
            className={clsx(styled.hasFeature, {
              [styled.hasNotFeature]: planId < 0,
            })}
          >
            {" "}
            <Check />
            <p> Create custom structure </p>
          </li>
          <li
            className={clsx(styled.hasFeature, {
              [styled.hasNotFeature]: planId < 0,
            })}
          >
            {" "}
            <Check />
            <p> Create structure template </p>
          </li>
          <li
            className={clsx(styled.hasFeature, {
              [styled.hasNotFeature]: planId < 2,
            })}
          >
            {" "}
            <Check />
            <p>Create custom crop </p>
          </li>
          <li
            className={clsx(styled.hasFeature, {
              [styled.hasNotFeature]: planId < 2,
            })}
          >
            {" "}
            <Check />
            <p> Create custom dataset</p>
          </li>
          <li
            className={clsx(styled.hasFeature, {
              [styled.hasNotFeature]: planId < 1,
            })}
          >
            {" "}
            <Check />
            <p> Create yearly dataset </p>
          </li>
          <li
            className={clsx(styled.hasFeature, {
              [styled.hasNotFeature]: planId < 0,
            })}
          >
            {" "}
            <Check />
            <p> Create TMY dataset </p>
          </li>
          {/* <li>openmeteo</li>
          <li>meteonorm</li> */}
          <li
            className={clsx(styled.hasFeature, {
              [styled.hasNotFeature]: planId < 1,
            })}
          >
            {" "}
            <Check />
            <p> Create custom soil </p>
          </li>
          <li
            className={clsx(styled.hasFeature, {
              [styled.hasNotFeature]: planId < 1,
            })}
          >
            {" "}
            <Check />
            <p> Create soil with database </p>{" "}
          </li>
          <li
            className={clsx(styled.hasFeature, {
              [styled.hasNotFeature]: planId < 2,
            })}
          >
            {" "}
            <Check />
            <p> Create custom steering </p>
          </li>
          <li
            className={clsx(styled.hasFeature, {
              [styled.hasNotFeature]: planId < 0,
            })}
          >
            {" "}
            <Check />
            <p> Do irradiance simulations </p>
          </li>
          <li
            className={clsx(styled.hasFeature, {
              [styled.hasNotFeature]: planId < 1,
            })}
          >
            {" "}
            <Check />
            <p> Do production simulations </p>
          </li>
          <li
            className={clsx(styled.hasFeature, {
              [styled.hasNotFeature]: planId < 1,
            })}
          >
            {" "}
            <Check />
            <p> Do hydric simulations</p>
          </li>
          <li
            className={clsx(styled.hasFeature, {
              [styled.hasNotFeature]: planId < 1,
            })}
          >
            {" "}
            <Check />
            <p> Do thermal simulations </p>
          </li>
          <li
            className={clsx(styled.hasFeature, {
              [styled.hasNotFeature]: planId < 0,
            })}
          >
            {" "}
            <Check />
            <p> Do yield simulations </p>
          </li>
          <li
            className={clsx(styled.hasFeature, {
              [styled.hasNotFeature]: planId < 2,
            })}
          >
            {" "}
            <Check />
            <p> Do batchs simulations </p>
          </li>
          <li
            className={clsx(styled.hasFeature, {
              [styled.hasNotFeature]: planId < 2,
            })}
          >
            {" "}
            <Check />
            <p> Create batchs </p>
          </li>
        </ul>
        <footer>
          <p>
            {" "}
            <b>
              {planId === 0
                ? "Begginner "
                : planId === 1
                ? "Medium "
                : "Premium "}
            </b>{" "}
            plan selected{" "}
          </p>
          <button
            className={clsx(styled.subBtn, {
              [styled.alreadySub]: subscription && subscription.plan >= planId,
            })}
          >
            {
              <p>
                {subscription && subscription.plan >= planId
                  ? "Subscribed"
                  : "Subscribe"}{" "}
              </p>
            }
          </button>
        </footer>
      </section>
    </Modal>
  );
};

export default ModalSubscribe;
