import BatchesWaterBalanceBody, {
  IBatchesWaterBalanceState,
} from "components/BatchesWaterBalanceBody/BatchesWaterBalanceBody";
import EditName from "components/EditName/EditName";
import MainContent from "components/MainContent/MainContent";
import { IInterestNameVariantsWaterBalance } from "components/WaterBalanceParametersInterest/useParamsList";
import { Loader } from "components/common/loader/Loader";
import { ROUTES } from "constants/routes";
import { FormikHelpers, useFormik } from "formik";
import { usePrompt } from "hooks/usePromt";
import { useUpdateNavigation } from "hooks/useUpdateNavigation";
import { cloneDeep, isEqual } from "lodash";
import BatchesWaterBalanceCreateMessages from "pages/BatchesWaterBalanceCreate/BatchesWaterBalanceCreateMessages";
import BatchesWaterBalanceSchema from "pages/BatchesWaterBalanceCreate/BatchesWaterBalanceSchema";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  deleteBatchWaterBalance,
  runBatchWaterBalanceSimulation,
  updateBatchWaterBalance,
} from "store/batches_water_balance/actions";
import { getBatchWaterBalance } from "store/batches_water_balance/api";
import styled from "pages/BatchesCreate/styled.module.scss";
import { ReactComponent as Save } from "assets/icons/save.svg";
import { ReactComponent as Delete } from "assets/icons/delete.svg";
import Button from "components/common/button/Button";
import CommonMessages from "components/common/CommonMessages";
import {
  LeaveModalEnum,
  LeaveModalWindow,
} from "components/LeaveModalWindow/LeaveModalWindow";
import { getDefaultValues } from "pages/BatchesWaterBalanceCreate/BatchesWaterBalanceCreate";
import { getAllFeatures } from "store/features/selector";
import UnSubscribeButton from "components/common/unSubscribe/UnSubscribeButton";
import ModalSubscribe from "components/ModalSubscribe/ModalSubsribe";
import { getSubscription } from "store/subscription/actions";

const BatchesWaterBalanceEdit = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const features = useSelector(getAllFeatures);
  const { client_id, project_id, id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleteWarning, setIsDeleteWarning] = useState(false);
  const [initialValue, setInitialValue] = useState(getDefaultValues(intl));
  const [isNavigationReady, setIsNavigationReady] = useState(false);
  useUpdateNavigation(() => setIsNavigationReady(true));
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    dispatch(getSubscription({ clientId: client_id }));
  }, [client_id, project_id]);

  const getCurrentNavigation = () => {
    getBatchWaterBalance({
      clientId: client_id,
      projectId: project_id,
      batchWaterBalanceId: id,
    })
      .then(({ data }) => {
        const currentValue: IBatchesWaterBalanceState = {
          name: data.name,
          base_simulation_water_balance_id: data.simulation.id,
          frequency: data.frequency,
          interests: !!data.rows.length
            ? (Object.keys(data.rows[0]) as IInterestNameVariantsWaterBalance[])
            : [],
          rows: data.rows,
        };
        formik.setValues(cloneDeep(currentValue));
        setInitialValue(currentValue);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (isNavigationReady) {
      getCurrentNavigation();
    }
  }, [isNavigationReady, id]);

  const onHideWarning = () => setIsDeleteWarning(false);

  const onShowWarning = () => setIsDeleteWarning(true);

  const onBack = () =>
    navigate(
      ROUTES.BATCHES_WATER_BALANCE.replace(
        ":client_id",
        client_id as string
      ).replace(":project_id", project_id as string)
    );

  const onUpdate = (
    values: IBatchesWaterBalanceState,
    actions: FormikHelpers<IBatchesWaterBalanceState>
  ) => {
    dispatch(
      updateBatchWaterBalance({
        ...values,
        clientId: client_id,
        projectId: project_id,
        batchWaterBalanceId: id,
        callback: onBack,
        finallyCallback: () => actions.setSubmitting(false),
      })
    );
  };

  const onDelete = () => {
    dispatch(
      deleteBatchWaterBalance({
        clientId: client_id,
        projectId: project_id,
        batchWaterBalanceId: id,
        callback: onBack,
      })
    );
  };

  const formik = useFormik({
    initialValues: getDefaultValues(intl),
    onSubmit: onUpdate,
    validationSchema: BatchesWaterBalanceSchema(intl),
  });

  const isDataChanged = !isEqual(formik.values, initialValue);

  usePrompt(isDataChanged && !formik.isSubmitting && !isDeleteWarning);

  const toResultPage = (result_id: string) =>
    navigate(
      ROUTES.BATCHES_WATER_BALANCE_RESULT.replace(
        ":client_id",
        client_id as string
      )
        .replace(":project_id", project_id as string)
        .replace(":batch_water_balance_id", id as string)
        .replace(":id", result_id)
    );

  const runSimulation = () => {
    dispatch(
      runBatchWaterBalanceSimulation({
        clientId: client_id,
        projectId: project_id,
        batchWaterBalanceId: id,
        callback: toResultPage,
      })
    );
  };

  const onRunSimulation = () => {
    if (isDataChanged) {
      setInitialValue(formik.values);

      dispatch(
        updateBatchWaterBalance({
          ...formik.values,
          clientId: client_id,
          projectId: project_id,
          batchWaterBalanceId: id,
          callback: runSimulation,
        })
      );
    } else {
      runSimulation();
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      {isLoading && <Loader />}

      <MainContent
        header={
          <>
            <EditName
              onFocus={(event) => {
                event.target.select();
              }}
              placeholder={intl.formatMessage(
                BatchesWaterBalanceCreateMessages.name
              )}
              onChange={formik.handleChange}
              name="name"
              onBlur={formik.handleBlur}
              value={formik.values.name}
              errorMessage={
                formik.touched.name ? (formik.errors.name as string) : ""
              }
            />

            <div className={styled.flex}>
              <Button
                variant="text"
                iconBefore={<Delete />}
                onClick={onShowWarning}
                className={styled.deleteButton}
              >
                <FormattedMessage {...CommonMessages.delete} />
              </Button>

              <Button variant="text" onClick={onBack}>
                <FormattedMessage {...CommonMessages.cancel} />
              </Button>

              {features && features.batchs ? (
                <Button
                  iconBefore={<Save />}
                  type="submit"
                  isLoading={formik.isSubmitting}
                  disabled={formik.isSubmitting || !isDataChanged}
                >
                  <FormattedMessage {...CommonMessages.save} />
                </Button>
              ) : (
                <UnSubscribeButton
                  iconBefore={<Save />}
                  onClick={() => setIsModalOpen(!isModalOpen)}
                >
                  <FormattedMessage {...CommonMessages.save} />
                </UnSubscribeButton>
              )}
            </div>
          </>
        }
      >
        <BatchesWaterBalanceBody
          formik={formik}
          onRunSimulation={onRunSimulation}
          edit_page={true}
        />
        <LeaveModalWindow
          type={LeaveModalEnum.DELETE}
          onDelete={onDelete}
          show={isDeleteWarning}
          onHide={onHideWarning}
        />
      </MainContent>
      <ModalSubscribe isOpen={isModalOpen} setOpen={setIsModalOpen} />
    </form>
  );
};

export default BatchesWaterBalanceEdit;
