import React from "react";
import Header from "components/Header/Header";
import styled from "./styled.module.scss";
import { FormattedMessage } from "react-intl";
import { NavLink, Outlet } from "react-router-dom";
import { ROUTES } from "constants/routes";
import { useParams } from "react-router-dom";
import withAuthProtection from "hoc/authProtection";
import settingsLayoutMessages from "components/SettingsLayout/SettingsLayoutMessages";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { getUserDataState } from "store/user/selectors";

const SettingsLayout = () => {
  const { client_id } = useParams();

  const user = useSelector(getUserDataState);
  const fixLink = (link: string) =>
    link.replace(":client_id", client_id as string);

  return (
    <>
      <Header />
      <div className={styled.header}>
        <div className={styled.row}>
          <h1 className={styled.projectTitle}>
            <FormattedMessage {...settingsLayoutMessages.settings} />
          </h1>
        </div>
      </div>

      <div className={styled.content}>
        <aside className={styled.aside}>
          <h2 className={styled.asideTitle}>
            <FormattedMessage {...settingsLayoutMessages.mainSettings} />
          </h2>

          <NavLink
            to={fixLink(ROUTES.SETTING_USERS)}
            className={({ isActive }) =>
              clsx(styled.link, {
                [styled.active]: isActive,
              })
            }
          >
            <FormattedMessage {...settingsLayoutMessages.userManagement} />
          </NavLink>
          {user &&
            (user.role.name === "manager" || user.role.name === "admin") && (
              <NavLink
                to={fixLink(ROUTES.SETTING_SUBSCRIPTION)}
                className={({ isActive }) =>
                  clsx(styled.link, {
                    [styled.active]: isActive,
                  })
                }
              >
                <FormattedMessage
                  {...settingsLayoutMessages.subscriptionManagement}
                />
              </NavLink>
            )}
        </aside>
        <Outlet />
      </div>
    </>
  );
};

export default withAuthProtection(SettingsLayout);
