const MAIN_PATH = "/clients/:client_id/projects/:project_id";
const SETTING_PATH = "/clients/:client_id/setting";

export const ROUTES = {
  LOGIN: "/login",
  REGISTER: "/register",
  FORGOT: "/forgot-password",
  PROFILE: "/profile",
  MAIN: "/",
  PROJECTS_LIST: "/clients/:client_id/projects",
  CLIENTS_LIST: "/clients",

  SETTING_USERS: `${SETTING_PATH}/users`,
  SETTING_SUBSCRIPTION: `${SETTING_PATH}/subscription`,

  STRUCTURES: `${MAIN_PATH}/structures`,
  STRUCTURES_CREATE: `${MAIN_PATH}/structures/create`,
  STRUCTURES_CREATE_FROM_TEMPLATE: `${MAIN_PATH}/structure/create_from_template`,
  STRUCTURES_EDIT: `${MAIN_PATH}/structures/edit/:id`,

  DATASETS: `${MAIN_PATH}/datasets`,
  DATASETS_CREATE: `${MAIN_PATH}/datasets/create`,
  DATASETS_EDIT: `${MAIN_PATH}/datasets/edit/:id`,

  DEFAULT_SOIL_INFORMATIONS: `${MAIN_PATH}/default_soil_informations/:id`,

  CROPS: `${MAIN_PATH}/crops`,
  CROPS_CREATE: `${MAIN_PATH}/crops/create`,
  CROPS_CREATE_FROM_TEMPLATE: `${MAIN_PATH}/crops/create_from_template`,
  CROPS_EDIT: `${MAIN_PATH}/crops/edit/:id`,

  STEERING_ALGORITHM: `${MAIN_PATH}/steering_algorithms`,
  STEERING_ALGORITHM_LIST_FROM_CROP: `${MAIN_PATH}/steering_algorithms/:id`,
  STEERING_ALGORITHM_CREATE: `${MAIN_PATH}/steering_algorithms/create`,
  STEERING_ALGORITHM_CREATE_FROM_CROP: `${MAIN_PATH}/steering_algorithms/create/:id`,
  STEERING_ALGORITHM_EDIT: `${MAIN_PATH}/steering_algorithms/edit/:id`,

  SOIL_INFORMATIONS: `${MAIN_PATH}/soil_informations`,
  SOIL_INFORMATION_CREATE: `${MAIN_PATH}/soil_informations/create`,
  SOIL_INFORMATION_EDIT: `${MAIN_PATH}/soil_informations/edit/:id`,

  SIMULATIONS: `${MAIN_PATH}/simulations`,
  SIMULATIONS_CREATE: `${MAIN_PATH}/simulations/create`,
  SIMULATIONS_EDIT: `${MAIN_PATH}/simulations/edit/:id`,
  SIMULATIONS_RESULT: `${MAIN_PATH}/simulations/:simulation_id/results/:id`,

  WATER_BALANCES: `${MAIN_PATH}/water_balances`,
  WATER_BALANCES_CREATE: `${MAIN_PATH}/water_balances/create`,
  WATER_BALANCES_EDIT: `${MAIN_PATH}/water_balances/edit/:id`,
  WATER_BALANCES_RESULT: `${MAIN_PATH}/water_balances/:water_balance_id/results/:id`,

  BATCHES: `${MAIN_PATH}/batches`,
  BATCHES_CREATE: `${MAIN_PATH}/batches/create`,
  BATCHES_EDIT: `${MAIN_PATH}/batches/edit/:id`,
  BATCHES_RESULT: `${MAIN_PATH}/batches/:batch_id/results/:id`,
  BATCHES_SIMULATION_RESULT: `${MAIN_PATH}/batches/:batch_id/results/:result_id/simulations/:simulation_id`,

  BATCHES_WATER_BALANCE: `${MAIN_PATH}/batches_water_balance`,
  BATCHES_WATER_BALANCE_CREATE: `${MAIN_PATH}/batches_water_balance/create`,
  BATCHES_WATER_BALANCE_EDIT: `${MAIN_PATH}/batches_water_balance/edit/:id`,
  BATCHES_WATER_BALANCE_RESULT: `${MAIN_PATH}/batches_water_balance/:batch_water_balance_id/results/:id`,
  BATCHES_WATER_BALANCE_SIMULATION_RESULT: `${MAIN_PATH}/batches_water_balance/:batch_water_balance_id/results/:result_id/simulations/:simulation_water_balance_id`,

  SIZING_OPTIMIZATION: `${MAIN_PATH}/sizing_optimization`,
  SIZING_OPTIMIZATION_CREATE: `${MAIN_PATH}/sizing_optimization/create`,
  SIZING_OPTIMIZATION_EDIT: `${MAIN_PATH}/sizing_optimization/edit/:id`,
  SIZING_OPTIMIZATION_RESULT: `${MAIN_PATH}/sizing_optimization/:sizing_optimization_id/results/:id`,
  SIZING_OPTIMIZATION_BATCH_RESULT: `${MAIN_PATH}/sizing_optimization/:sizing_optimization_id/results/:result_id/batch_result/:id`,
  SIZING_OPTIMIZATION_BATCH_SIMULATION_RESULT: `${MAIN_PATH}/sizing_optimization/:sizing_optimization_id/results/:result_id/batch_result/:batch_result_id/batch_simulation_result/:id`,

  NOT_FOUND: "/404",
};
