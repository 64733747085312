import { apiGET, apiPOST, apiDELETE, apiPATCH } from "store/services/api";

export const createSubscription = ({ clientId, ...data }) =>
  apiPOST({
    url: `/clients/${clientId}/subscriptions`,
    data,
  });

export const getSubscription = ({ clientId }) =>
  apiGET({
    url: `/clients/${clientId}/subscriptions`,
  });

export const updateSubscription = ({ clientId, subscriptionId, ...data }) =>
  apiPATCH({
    url: `/clients/${clientId}/subscriptions/${subscriptionId}`,
    data,
  });

export const getAllSubscriptions = ({ clientId }) =>
  apiGET({
    url: `/clients/${clientId}/subscriptions/all`,
  });
