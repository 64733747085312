import { toast } from "react-toastify";
import { Msg } from "components/common/msg/Msg";

interface ISingleError {
  msg?: string;
  loc?: Array<string>;
}

const onPushNotification = (error: any) => {
  toast.warning(<Msg type="error" msg={error.message} title={error.title} />, {
    position: "bottom-left",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    icon: false,
    closeButton: true,
  });
};

export const errorNotifications = (error: string | Array<object>) => {
  if (Array.isArray(error)) {
    error.forEach((err: ISingleError) =>
      onPushNotification({
        title: err.loc
          ? err.loc[1]
              .replace("_", " ")
              .replace(/(^|\s)\S/g, (a) => a.toUpperCase())
          : null,
        message: err.msg,
      })
    );
  } else {
    onPushNotification({ message: error });
  }
};
