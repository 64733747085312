import { combineReducers } from "redux";
// reducers
import projects from "./projects/reducer";
import user from "./user/reducer";
import clients from "./clients/reducer";
import structures from "./structures/reducer";
import crops from "./crops/reducer";
import datasets from "./datasets/reducer";
import simulations from "./simulations/reducer";
import navigation from "./navigation/reducer";
import batches from "./batches/reducer";
import batches_water_balance from "./batches_water_balance/reducer";
import users from "./users/reducer";
import steering_algorithms from "./steering_algorithms/reducer";
import soil_informations from "./soil_informations/reducer";
import water_balances from "./water_balances/reducer";
import sizing_optimizations from "./sizing_optimizations/reducer";
import subscription from "./subscription/reducer"
import features from "./features/reducer";
import crop_pack from "./crop_pack/reducer";
import directorys from "./directorys/reducer";

const rootReducer = combineReducers({
  projects,
  user,
  clients,
  features,
  directorys,
  steering_algorithms,
  structures,
  simulations,
  subscription,
  soil_informations,
  water_balances,
  crops,
  crop_pack,
  datasets,
  navigation,
  batches,
  batches_water_balance,
  sizing_optimizations,
  users,
});

export default rootReducer;
