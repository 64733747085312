import { all, fork } from 'redux-saga/effects';
// sagas
import getStructures from 'store/structures/sagas/getStructures';
import createStructure from 'store/structures/sagas/createStructure';
import deleteStructure from 'store/structures/sagas/deleteStructure';
import updateStructure from 'store/structures/sagas/updateStructure';
import drawStructureImage from 'store/structures/sagas/drawStructureImage';
import getStructureImage from 'store/structures/sagas/getStructureImage';
import copyStructure from 'store/structures/sagas/copyStructure';
import copyStructureToOtherProject from 'store/structures/sagas/copyStructureToOtherProject';

export default function* auth() {
  yield all([
    fork(getStructures),
    fork(createStructure),
    fork(deleteStructure),
    fork(updateStructure),
    fork(drawStructureImage),
    fork(getStructureImage),
    fork(copyStructure),
    fork(copyStructureToOtherProject),
  ]);
}
