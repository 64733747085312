import { put, takeLatest, call } from "redux-saga/effects";
// actions
import { getFeatures as action } from "store/features/actions";
// api
import { getFeatures as api } from "store/features/api";
import { errorNotifications } from "utils/errorNotifications";

function* saga({ payload }) {
  try {
    yield put(action.request());
    
    const response = yield call(api, payload);
    yield put(action.success(response));
  } catch (error) {
    yield put(action.failure());
    yield call(errorNotifications, error.response.data.data);
  } finally {
    yield put(action.fulfill());
  }
}

export default function* getFeatures() {
  yield takeLatest(action.TRIGGER, saga);
}
