import { defineMessages } from "react-intl";

export default defineMessages({
  cropName: {
    id: "CropsCreateMessages.cropName",
    defaultMessage: "Crop Name",
  },
  cropUnnamed: {
    id: "CropsCreateMessages.cropUnnamed",
    defaultMessage: "Crop Unnamed",
  },
  developmentStages: {
    id: "CropsCreateMessages.developmentStages",
    defaultMessage: "Development Stages",
  },
  developmentStagesDefinitions: {
    id: "CropsCreateMessages.developmentStagesDefinitions",
    defaultMessage: "Development Stages Definitions",
  },
  degreeDaysCalculation: {
    id: "CropsCreateMessages.degreeDaysCalculation",
    defaultMessage: "Degree Days Calculation",
  },
  sowingDateHelp: {
    id: "CropsCreateMessages.sowingDateHelp",
    defaultMessage: "Sowing date's year is not taken into account",
  },
  sowingDate: {
    id: "CropsCreateMessages.sowingDate",
    defaultMessage: "Sowing Date",
  },
  baseTemperature: {
    id: "CropsCreateMessages.baseTemperature",
    defaultMessage: "Base Temperature",
  },
  stageName: {
    id: "CropsCreateMessages.stageName",
    defaultMessage: "Stage Name",
  },
  cropHeight: {
    id: "CropsCreateMessages.cropHeight",
    defaultMessage: "Crop Height (m)",
  },
  startDate: {
    id: "CropsCreateMessages.startDate",
    defaultMessage: "Start Date",
  },
  endDate: {
    id: "CropsCreateMessages.endDate",
    defaultMessage: "End Date",
  },
  startDegreeDay: {
    id: "CropsCreateMessages.startDegreeDay",
    defaultMessage: "Start Degree Day",
  },
  endDegreeDay: {
    id: "CropsCreateMessages.endDegreeDay",
    defaultMessage: "End Degree Day",
  },
  culturalCoefficient: {
    id: "CropsCreateMessages.culturalCoefficient",
    defaultMessage: "Cultural Coefficient",
  },
  addNewStage: {
    id: "CropsCreateMessages.addNewStage",
    defaultMessage: "Add new Stage",
  },
  selectCropTemplate: {
    id: "CropsCreateMessages.selectCropTemplate",
    defaultMessage: "Select Crop Template",
  },
  mustContain: {
    id: "CropsCreateMessages.mustContain",
    defaultMessage: "There must be at least one stage.",
  },
  temperatureThreshold: {
    id: "CropsCreateMessages.temperatureThreshold",
    defaultMessage: "Temperature Threshold",
  },
  cropTypeDef: {
    id: "CropsCreateMessages.cropTypeDef",
    defaultMessage: "Crop Type Definitions",
  },
});
