import { defineMessages } from "react-intl";

export default defineMessages({
  soilInformationName: {
    id: "SoilInformationCreateMessages.soilInformationName",
    defaultMessage: "Soil Information Name",
  },
  soilInformationUnnamed: {
    id: "SoilInformationCreateMessages.soilInformationUnnamed",
    defaultMessage: "Soil Information Unnamed",
  },
  selectCrop: {
    id: "SoilInformationCreateMessages.selectCrop",
    defaultMessage: "Select Crop",
  },
  selectSteeringAlgorithm: {
    id: "SoilInformationCreateMessages.selectSteeringAlgorithm",
    defaultMessage: "Select Steering Algorithm",
  },
  infomations: {
    id: "SoilInformationCreateMessages.infomations",
    defaultMessage: "Infomation",
  },
  rfu_water: {
    id: "SoilInformationCreateMessages.rfu_water",
    defaultMessage: "RFU (mm)",
  },
  ru_water: {
    id: "SoilInformationCreateMessages.ru_water",
    defaultMessage: "RU (mm)",
  },
  irrigation: {
    id: "SoilInformationCreateMessages.irrigation",
    defaultMessage: "Irrigation",
  },
  createFromPosition: {
    id: "SoilInformationCreateMessages.createFromPosition",
    defaultMessage: "Create from project position",
  }
});
