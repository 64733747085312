import { put, takeLatest, call } from "redux-saga/effects";
// actions
import { getFeatures as action } from "store/features/actions";
// api
import { getFeatures as api } from "store/features/api";
import { errorNotifications } from "utils/errorNotifications";

function* saga({ payload }) {
    yield put(action.fulfill(payload));
}

export default function* setFeatures() {
  yield takeLatest(action.TRIGGER, saga);
}
