import { useEffect, useState } from "react";
import styled from "./styled.module.scss";
import { FormattedMessage, useIntl } from "react-intl";
import commonMessages from "components/common/CommonMessages";
import Button from "components/common/button/Button";
import { ReactComponent as Plus } from "assets/icons/plus.svg";
import { ROUTES } from "constants/routes";
import { useNavigate, useParams } from "react-router-dom";
import { getCropsListState, getIsLoading } from "store/crops/selectors";
import { useDispatch, useSelector } from "react-redux";
import MainContent from "components/MainContent/MainContent";
import {
  LeaveModalEnum,
  LeaveModalWindow,
} from "components/LeaveModalWindow/LeaveModalWindow";
import { getCrops } from "store/crops/actions";
import SteeringAlgorithmCropsCard from "components/SteeringAlgorithmCropsCard/SteeringAlgorithmCropsCard";
import SteeringAlgorithmsCard from "components/SteeringAlgorithmsCard/SteeringAlgorithmsCard";
import { getSteeringAlgorithmsListState } from "store/steering_algorithms/selectors";
import {
  copySteeringAlgorithm,
  deleteSteeringAlgorithm,
  getSteeringAlgorithms,
} from "store/steering_algorithms/actions";
import { IRowField } from "pages/SteeringAlgorithmsCreate/SteeringAlgorithmsCreateTypes";
import { ICrops } from "pages/Crops/Crops";
import { getAllFeatures } from "store/features/selector";
import UnSubscribeButton from "components/common/unSubscribe/UnSubscribeButton";
import ModalSubscribe from "components/ModalSubscribe/ModalSubsribe";
import { getSubscription } from "store/subscription/actions";

export interface ISteeringAlgorithm {
  created_at: string;
  deleted: boolean;
  id: number;
  name: string;
  crop_id: number;
  rows: Array<IRowField>;
  updated_at: string;
}

function SteeringAlgorithm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const intl = useIntl();
  const { client_id, project_id, id } = useParams();
  const [show, onShow] = useState(false);
  const [currentId, setCurrentId] = useState(NaN);
  const crops = useSelector(getCropsListState);
  const steering_algorithms = useSelector(getSteeringAlgorithmsListState);
  const [isCropSelectorLoading, setIsCropSelectorLoading] = useState(true);
  const features = useSelector(getAllFeatures);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    dispatch(getCrops({ clientId: client_id, projectId: project_id }));
    dispatch(
      getSteeringAlgorithms({ clientId: client_id, projectId: project_id })
    );
    dispatch(getSubscription({ clientId: client_id }));
  }, [client_id, project_id]);

  useEffect(() => {
    if (id != undefined) {
      if (
        crops.length &&
        !crops.find((crop: { id: number | string }) => crop.id == id)
      )
        navigate(ROUTES.NOT_FOUND);
      else setIsCropSelectorLoading(false);
    }
  }, [crops, id]);

  const onDeleteSteeringAlgorithm = (id: number | string) => {
    dispatch(
      deleteSteeringAlgorithm({
        clientId: client_id,
        projectId: project_id,
        steeringAlgorithmId: id,
        callback: () => onShow(false),
      })
    );
  };

  const onAddSteeringAlgorithmFromCrop = (crop_id: number) => {
    navigate(
      ROUTES.STEERING_ALGORITHM_CREATE_FROM_CROP.replace(
        ":client_id",
        client_id as string
      )
        .replace(":project_id", project_id as string)
        .replace(":id", String(crop_id))
    );
  };

  const onShowWarning = (id: number) => {
    setCurrentId(id);
    onShow(true);
  };

  const onCreateNavigate = () => {
    if (id == undefined)
      navigate(
        ROUTES.STEERING_ALGORITHM_CREATE.replace(
          ":client_id",
          client_id as string
        ).replace(":project_id", project_id as string)
      );
    else
      navigate(
        ROUTES.STEERING_ALGORITHM_CREATE_FROM_CROP.replace(
          ":client_id",
          client_id as string
        )
          .replace(":project_id", project_id as string)
          .replace(":id", id as string)
      );
  };

  const onEditSteeringAlgorithmNavigate = (id: number) => {
    navigate(
      ROUTES.STEERING_ALGORITHM_EDIT.replace(":client_id", client_id as string)
        .replace(":project_id", project_id as string)
        .replace(":id", String(id))
    );
  };

  const onCropCardClick = (id: number) => () => {
    navigate(
      ROUTES.STEERING_ALGORITHM_LIST_FROM_CROP.replace(
        ":client_id",
        client_id as string
      )
        .replace(":project_id", project_id as string)
        .replace(":id", String(id))
    );
  };

  const onCopy = (id: string | number) => () => {
    dispatch(
      copySteeringAlgorithm({
        clientId: client_id,
        projectId: project_id,
        steeringAlgorithmId: id,
        callback: () => {
          dispatch(
            getSteeringAlgorithms({
              clientId: client_id,
              projectId: project_id,
            })
          );
          onShow(false);
        },
      })
    );
  };

  if (isCropSelectorLoading && id != undefined) return <></>;

  return (
    <MainContent
      header={
        <>
          <h2 className={styled.title}>
            {id == undefined ? (
              <FormattedMessage {...commonMessages.steering} />
            ) : (
              <>
                {intl
                  .formatMessage(commonMessages.steeringFromCrop)
                  .replace(
                    ":crop_name",
                    crops.find((crop: { id: number | string }) => crop.id == id)
                      ? crops.find(
                          (crop: { id: number | string }) => crop.id == id
                        ).name
                      : ""
                  )}
              </>
            )}
          </h2>

          {features && features.custom_steering ? (
            <Button iconBefore={<Plus />} onClick={onCreateNavigate}>
              <FormattedMessage {...commonMessages.createNew} />
            </Button>
          ) : (
            <UnSubscribeButton
              iconBefore={<Plus />}
              onClick={() => setIsModalOpen(!isModalOpen)}
            >
              <FormattedMessage {...commonMessages.createNew} />
            </UnSubscribeButton>
          )}
        </>
      }
    >
      <div className={styled.list}>
        {crops
          .filter(
            (crop: { id: number | string }) =>
              crop.id == (id != undefined ? id : crop.id)
          )
          .map((crop: ICrops, index: number) => (
            <div key={index.toString()}>
              {!id ? (
                <SteeringAlgorithmCropsCard
                  key={crop.id}
                  name={crop.name}
                  date={crop.created_at}
                  onAddSteeringAlgorithm={() =>
                    onAddSteeringAlgorithmFromCrop(crop.id)
                  }
                  onClick={onCropCardClick(crop.id)}
                  onDelete={() => onShowWarning(crop.id)}
                >
                  {steering_algorithms
                    .filter(
                      (steering_algorithm: ISteeringAlgorithm) =>
                        steering_algorithm.crop_id == crop.id
                    )
                    .map((steering_algorithm: ISteeringAlgorithm) => (
                      <SteeringAlgorithmsCard
                        key={steering_algorithm.id}
                        name={steering_algorithm.name}
                        date={steering_algorithm.created_at}
                        onEdit={() =>
                          onEditSteeringAlgorithmNavigate(steering_algorithm.id)
                        }
                        onCopy={() => {
                          onCopy(steering_algorithm.id)();
                        }}
                        onDelete={() =>
                          onDeleteSteeringAlgorithm(steering_algorithm.id)
                        }
                      />
                    ))}
                </SteeringAlgorithmCropsCard>
              ) : (
                steering_algorithms
                  .filter(
                    (steering_algorithm: ISteeringAlgorithm) =>
                      steering_algorithm.crop_id == crop.id
                  )
                  .map((steering_algorithm: ISteeringAlgorithm) => (
                    <SteeringAlgorithmsCard
                      key={steering_algorithm.id}
                      name={steering_algorithm.name}
                      date={steering_algorithm.created_at}
                      onEdit={() =>
                        onEditSteeringAlgorithmNavigate(steering_algorithm.id)
                      }
                      onCopy={() => {
                        onCopy(steering_algorithm.id)();
                      }}
                      onDelete={() =>
                        onDeleteSteeringAlgorithm(steering_algorithm.id)
                      }
                    />
                  ))
              )}
            </div>
          ))}
        <LeaveModalWindow
          type={LeaveModalEnum.DELETE}
          onDelete={() => {}}
          show={show}
          onHide={() => onShow(false)}
        />
      </div>
      <ModalSubscribe isOpen={isModalOpen} setOpen={setIsModalOpen} />
    </MainContent>
  );
}

export { SteeringAlgorithm };
