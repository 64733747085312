import { Form } from "react-bootstrap";

const Toggle = (props: {
  label: string;
  checked: boolean;
  disabled: boolean;
  onClick: (event: React.MouseEvent<HTMLInputElement>) => void;
}) => {
  const { onClick, ...rest } = props;
  return (
    <Form.Check type="switch" {...rest} onChange={(e) => onClick(e as any)} />
  );
};

export default Toggle;
