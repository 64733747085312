import { useEffect, useState } from "react";
import styled from "./styled.module.scss";
import { FormattedMessage, useIntl } from "react-intl";
import commonMessages from "components/common/CommonMessages";
import Button from "components/common/button/Button";
import { ReactComponent as Plus } from "assets/icons/plus.svg";
import { ROUTES } from "constants/routes";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import MainContent from "components/MainContent/MainContent";
import {
  LeaveModalEnum,
  LeaveModalWindow,
} from "components/LeaveModalWindow/LeaveModalWindow";
import { getSoilInformationsListState } from "store/soil_informations/selectors";
import {
  copySoilInformation,
  copySoilInformationToOtherProject,
  createCurrentSoilInformationByLocalization,
  deleteSoilInformation,
  getSoilInformations,
} from "store/soil_informations/actions";
import SoilInformationsCard from "components/SoilInformationsCard/SoilInformationsCard";
import { getProjectsListState } from "store/projects/selectors";
import { useOptionData } from "hooks/useOptionData";
import { Modal } from "react-bootstrap";
import { ICON_NAMES_ENUM, Icon } from "components/common/icon/Icon";
import CommonMessages from "components/common/CommonMessages";
import Select from "components/common/select/Select";
import { successNotifications } from "utils/successNotifications";
import UnSubscribeButton from "components/common/unSubscribe/UnSubscribeButton";
import { getAllFeatures } from "store/features/selector";
import SoilInformationsCreateMessages from "pages/SoilInformationsCreate/SoilInformationsCreateMessages";
import ModalSubscribe from "components/ModalSubscribe/ModalSubsribe";
import { getSubscription } from "store/subscription/actions";
export interface ISoilInformation {
  id: number;
  name: string;
  ru_water: number;
  rfu_water: number;
  irrigation: boolean;
  is_default: boolean;
  is_copy: boolean;
  deleted: boolean;
  created_at: string;
  updated_at: string;
}

export interface IDefaultSoilInformation {
  id: number;
  name: string;
  ru_water: number;
  rfu_water: number;
  irrigation: boolean;
}

function SoilInformations() {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { client_id, project_id } = useParams();
  const [show, onShow] = useState(false);
  const soil_informations = useSelector(getSoilInformationsListState);
  const [projectId, setProjectId] = useState(0);
  const [copyToOtherProjectId, setCopyToOtherProjectId] = useState<
    number | null
  >(null);
  const projectList = useSelector(getProjectsListState);
  const projectOptions = useOptionData(projectList);
  const [onCreatingCurrentSoil, setOnCreatingCurrentSoil] = useState(false);
  const features = useSelector(getAllFeatures);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    dispatch(
      getSoilInformations({ clientId: client_id, projectId: project_id })
    );
    dispatch(getSubscription({ clientId: client_id }));
  }, [client_id, project_id]);

  const onDeleteSoilInformation = (id: number | string) => {
    dispatch(
      deleteSoilInformation({
        clientId: client_id,
        projectId: project_id,
        soilInformationId: id,
        callback: () => onShow(false),
      })
    );
  };

  const onCreateNavigate = () => {
    navigate(
      ROUTES.SOIL_INFORMATION_CREATE.replace(
        ":client_id",
        client_id as string
      ).replace(":project_id", project_id as string)
    );
  };

  const onEditNavigate = (id: number) => {
    navigate(
      ROUTES.SOIL_INFORMATION_EDIT.replace(":client_id", client_id as string)
        .replace(":project_id", project_id as string)
        .replace(":id", String(id))
    );
  };

  const onViewDefaultNavigate = (id: number) => {
    navigate(
      ROUTES.DEFAULT_SOIL_INFORMATIONS.replace(
        ":client_id",
        client_id as string
      )
        .replace(":project_id", project_id as string)
        .replace(":id", String(id))
    );
  };

  const onCopy = (id: string | number) => () => {
    dispatch(
      copySoilInformation({
        clientId: client_id,
        projectId: project_id,
        soilInformationId: id,
        callback: () => {
          dispatch(
            getSoilInformations({
              clientId: client_id,
              projectId: project_id,
            })
          );
          onShow(false);
        },
      })
    );
  };

  const onCreateCurrentSoilInformationByLocalization = () => {
    setOnCreatingCurrentSoil(true);
    dispatch(
      createCurrentSoilInformationByLocalization({
        clientId: client_id,
        projectId: project_id,
        callback: () => {
          successNotifications({
            title: intl.formatMessage(CommonMessages.create),
            message: intl.formatMessage(CommonMessages.successCreate, {
              objet_type: intl.formatMessage(CommonMessages.soil_information),
            }),
          });
        },
        finallyCallback: () => setOnCreatingCurrentSoil(false),
      })
    );
  };

  return (
    <MainContent
      header={
        <>
          <h2 className={styled.title}>
            <FormattedMessage {...commonMessages.soil_information} />
          </h2>

          <div className={styled.flex}>
            {features && features.database_soil ? (
              <Button
                iconBefore={<Plus />}
                onClick={onCreateCurrentSoilInformationByLocalization}
                isLoading={onCreatingCurrentSoil}
                disabled={onCreatingCurrentSoil}
              >
                <FormattedMessage
                  {...SoilInformationsCreateMessages.createFromPosition}
                />
              </Button>
            ) : (
              <UnSubscribeButton
                iconBefore={<Plus />}
                onClick={() => setIsModalOpen(!isModalOpen)}
              >
                <FormattedMessage
                  {...SoilInformationsCreateMessages.createFromPosition}
                />
              </UnSubscribeButton>
            )}

            {features && features.custom_soil ? (
              <Button iconBefore={<Plus />} onClick={onCreateNavigate}>
                <FormattedMessage {...commonMessages.createNew} />
              </Button>
            ) : (
              <UnSubscribeButton
                iconBefore={<Plus />}
                onClick={() => setIsModalOpen(!isModalOpen)}
              >
                <FormattedMessage {...commonMessages.createNew} />
              </UnSubscribeButton>
            )}
          </div>
        </>
      }
    >
      <div className={styled.list}>
        {soil_informations
          .filter((soil: ISoilInformation) => soil.is_default === false)
          .map((soilInformation: ISoilInformation, index: number) => (
            <SoilInformationsCard
              key={index.toString()}
              name={soilInformation.name}
              date={soilInformation.updated_at}
              onDelete={() => onDeleteSoilInformation(soilInformation.id)}
              onEdit={() => onEditNavigate(soilInformation.id)}
              onCopy={onCopy(soilInformation.id)}
              onCopyToOtherProject={() => {
                setCopyToOtherProjectId(soilInformation.id);
              }}
            />
          ))}
        <h3 className={styled.defaultSoilsTitle}> DEFAULT SOILS </h3>
        {soil_informations
          .filter((soil: ISoilInformation) => soil.is_default === true)
          .map((soilInformation: IDefaultSoilInformation, index: number) => (
            <SoilInformationsCard
              key={index}
              name={soilInformation.name}
              isDefault={true}
              onEdit={() => onViewDefaultNavigate(soilInformation.id)}
            />
          ))}
        <LeaveModalWindow
          type={LeaveModalEnum.DELETE}
          onDelete={() => {}}
          show={show}
          onHide={() => onShow(false)}
        />
        <Modal
          show={copyToOtherProjectId != null}
          onHide={() => setCopyToOtherProjectId(null)}
          centered
        >
          <div className={styled.container}>
            <Icon name={ICON_NAMES_ENUM.warning} />
            <h3 className={styled.modal_title}>
              <FormattedMessage {...CommonMessages.copy_to_other_project} />
            </h3>
            <Select
              label={"Select project"}
              options={projectOptions}
              onChange={(e: any) => {
                setProjectId(e.value);
              }}
            />
            <div className={styled.row}>
              <Button
                onClick={() => {
                  dispatch(
                    copySoilInformationToOtherProject({
                      clientId: client_id,
                      projectId: project_id,
                      soilInformationId: copyToOtherProjectId,
                      projectToId: projectId,
                      callback: () => {
                        successNotifications({
                          title: intl.formatMessage(
                            CommonMessages.copy_to_other_project
                          ),
                          message: intl.formatMessage(
                            CommonMessages.successCopyToOtherProject,
                            {
                              objet_type: intl.formatMessage(
                                CommonMessages.soil_information
                              ),
                              copied_project: projectList.find(
                                (elem: any) => elem.id === projectId
                              ).name,
                            }
                          ),
                        });
                        setCopyToOtherProjectId(null);
                      },
                    })
                  );
                }}
              >
                Copy
              </Button>
              <Button
                type="submit"
                onClick={() => {
                  setCopyToOtherProjectId(null);
                }}
              >
                <FormattedMessage {...CommonMessages.cancel} />
              </Button>
            </div>
          </div>
        </Modal>
      </div>
      <ModalSubscribe isOpen={isModalOpen} setOpen={setIsModalOpen} />
    </MainContent>
  );
}

export { SoilInformations };
