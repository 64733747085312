import { getFeatures, setFeatures, editFeatures } from "./actions";

const INITIAL_STATE = {
  features: null,
  isLoading: false,
};

const featuresReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case getFeatures.TRIGGER: {
      return {
        ...state,
        features: null,
        isLoading: true,
      };
    }

    case getFeatures.SUCCESS: {
      return {
        ...state,
        features: payload,
        isLoading: false,
      };
    }

    case getFeatures.FULFILL: {
      return {
        ...state,
      };
    }

    case getFeatures.FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case editFeatures.TRIGGER: {
      return {
        ...state,
        features: null,
        isLoading: true,
      };
    }

    case editFeatures.SUCCESS: {
      return {
        ...state,
        features: payload,
        isLoading: false,
      };
    }

    case editFeatures.FULFILL: {
      return {
        ...state,
      };
    }

    case editFeatures.FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case setFeatures.FULFILL: {
      return {
        ...state,
        features: payload.features,
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default featuresReducer;
