import { defineMessages } from "react-intl";

export default defineMessages({
  subscriptionManagement: {
    id: "SettingUsersMessages.subscriptionManagement",
    defaultMessage: "Subscription Management",
  },
  newSubscription: {
    id: "SettingUsersMessages.newSubscription",
    defaultMessage: "Subscribe",
  },
  editSubscription: {
    id: "SettingUsersMessages.editSubscription",
    defaultMessage: "Edit subscription plan",
  },
  currentSubscription: {
    id: "SettingUsersMessages.currentSubscription",
    defaultMessage: "Current Subscription",
  },
  noSubscription: {
    id: "SettingUsersMessages.noSubscription",
    defaultMessage: "You don't have a subscription plan.",
  },
  selectPlan: {
    id: "SettingUsersMessages.selectPlan",
    defaultMessage: "Select Plan",
  },
  editCropPacks: {
    id: "SettingUsersMessages.editCropPacks",
    defaultMessage: "Edit Crop Packs",
  },
  editFeatures: {
    id: "SettingUsersMessages.editFeatures",
    defaultMessage: "Edit Features",
  },
});
