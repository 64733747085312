import React, { useEffect, useState } from "react";
import styled from "./styled.module.scss";
import CardMenu from "components/CardMenu/CardMenu";
import moment from "moment";
import clientCardMessages from "components/ClientCard/ClientCardMessages";
import { FormattedMessage } from "react-intl";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "constants/routes";
import { useIntl } from "react-intl";
import commonMessages from "components/common/CommonMessages";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import clsx from "clsx";
import { useDrag } from "react-dnd";

export interface IProject {
  created_at: string;
  id: number;
  lat: number;
  long: number;
  name: string;
  updated_at: string;
  expiration_date: string;
}

interface IProjectCard {
  project: IProject;
  onEdit: () => void;
  onDelete: () => void;
  inDirectory?: boolean;
}

const ProjectCard = ({
  project,
  onEdit,
  onDelete,
  inDirectory = false,
}: IProjectCard) => {
  const intl = useIntl();
  const { client_id } = useParams();
  const [daysLeft, setDaysLeft] = useState<number | null>(null);
  const [createdAt, setCreatedAt] = useState<Date>();
  const [specialCaseMsg, setSpecialCaseMsg] = useState<string | null>(null);
  const linkPath = ROUTES.STRUCTURES.replace(
    ":client_id",
    client_id as string
  ).replace(":project_id", String(project.id));

  const specialCaseDate = (daysDiff: number) => {
    if (daysDiff < 0) setDaysLeft(-1);
    else {
      setDaysLeft(0);
      const now = moment();
      const expiration = moment.utc(project.expiration_date).local();
      setSpecialCaseMsg(`${expiration.to(now, true)} left`);
    }
  };

  const valids_days = () => {
    const dateExp = new Date(project.expiration_date);
    const today = new Date();

    const msDiff = dateExp.getTime() - today.getTime();
    // (1 day = 86400000 ms)
    const daysDiff = Math.ceil(msDiff / (1000 * 60 * 60 * 24));
    if (daysDiff <= 0) return specialCaseDate(daysDiff);
    setDaysLeft(daysDiff);
  };

  useEffect(() => {
    if (project) {
      valids_days();
      const date = new Date(project.created_at);
      setCreatedAt(date);
    }
  }, [project]);

  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
  };

  const navigate = useNavigate();

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: inDirectory ? "PROJECT_CARD_DIRECTORY" : "PROJECT_CARD",
      item: { id: project.id, inDirectory },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [inDirectory, project.id]
  );

  const navigateToProject = () => {
    document.body.style.overflow = "auto";

    navigate(
      ROUTES.STRUCTURES.replace(":client_id", client_id as string).replace(
        ":project_id",
        String(project.id)
      )
    );
  };

  return (
    <div
      className={styled.container}
      style={{ opacity: isDragging ? 0.5 : 1 }}
      ref={drag}
      onClick={navigateToProject}
    >
      {daysLeft && daysLeft < 0 ? (
        <p className={clsx(styled.expiration_date, styled.red)}>Expired</p>
      ) : daysLeft != null && daysLeft <= 7 ? (
        <p className={clsx(styled.expiration_date, styled.orange)}>
          {" "}
          {daysLeft > 0 ? `${daysLeft} days left` : specialCaseMsg}{" "}
        </p>
      ) : null}
      <div>
        <div className={styled.name}>{project.name}</div>
        <span className={styled.date}>
          <FormattedMessage {...clientCardMessages.created} />
          {": "}
          {createdAt ? createdAt.toLocaleDateString("en-US", options) : ""}
        </span>
      </div>
      <Tooltip title={intl.formatMessage(commonMessages.delete)}>
        <CardMenu onEdit={onEdit} onDelete={onDelete} />
      </Tooltip>
    </div>
  );
};

export default ProjectCard;
