import React, { useEffect } from "react";
import {
  Route,
  Routes,
  useLocation,
  Location,
  Navigate,
} from "react-router-dom";
import { IntlProvider } from "react-intl";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Provider, useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "react-toastify/dist/ReactToastify.css";

import { ROUTES } from "constants/routes";
import { TranslationProvider } from "./translations";
import store from "store";

import Main from "pages/Main";
import ProjectsList from "pages/ProjectsList/ProjectsList";
import ClientsList from "pages/ClientsList/ClientsList";
import Login from "pages/Login/Login";
import Register from "pages/Register/Register";
import Forgot from "pages/Forgot/Forgot";

import MainLayout from "components/MainLayout/MainLayout";
import Structures from "pages/Structures/Structures";
import StructuresCreate from "pages/StructuresCreate/StructuresCreate";
import StructuresEdit from "pages/StructuresEdit/StructuresEdit";
import { Datasets } from "pages/Datasets/Datasets";
import { DatasetsCreate } from "pages/DatasetsCreate/DatasetsCreate";
import { DatasetEdit } from "pages/DatasetEdit/DatasetEdit";
import { Crops } from "pages/Crops/Crops";
import { CropsCreate } from "pages/CropsCreate/CropsCreate";
import { CropsEdit } from "pages/CropsEdit/CropsEdit";
import Simulations from "pages/Simulations/Simulations";
import SimulationCreate from "pages/SimulationCreate/SimulationCreate";
import SimulationEdit from "pages/SimulationEdit/SimulationEdit";
import SimulationResult from "pages/SimulationResult/SimulationResult";
import Batches from "pages/Batches/Batches";
import BatchesCreate from "pages/BatchesCreate/BatchesCreate";
import BatchesEdit from "pages/BatchesEdit/BatchesEdit";
import BatchesResult from "pages/BatchesResult/BatchesResult";
import BatchesSimulationResult from "pages/BatchesSimulationResult/BatchesSimulationResult";
import DefaultSoilsInformations from "pages/DefaultSoilsInformations/DefaultSoilsInformations";
import ProfilePage from "pages/ProfilePage/ProfilePage";
import NotFound from "pages/NotFound/NotFound";
import SettingUsers from "pages/SettingUsers/SettingUsers";
import SettingsLayout from "components/SettingsLayout/SettingsLayout";
import { SteeringAlgorithm } from "pages/SteeringAlgorithms/SteeringAlgorithms";
import { SteeringAlgorithmsCreate } from "pages/SteeringAlgorithmsCreate/SteeringAlgorithmsCreate";
import { SteeringAlgorithmsEdit } from "pages/SteeringAlgorithmsEdit/SteeringAlgorithmsEdit";
import { SoilInformations } from "pages/SoilInformations/SoilInformations";
import { SoilInformationsCreate } from "pages/SoilInformationsCreate/SoilInformationsCreate";
import { SoilInformationsEdit } from "pages/SoilInformationsEdit/SoilInformationsEdit";
import WaterBalances from "pages/WaterBalances/WaterBalances";
import WaterBalanceCreate from "pages/WaterBalanceCreate/WaterBalanceCreate";
import WaterBalanceEdit from "pages/WaterBalanceEdit/WaterBalanceEdit";
import WaterBalanceResult from "pages/WaterBalanceResult/WaterBalanceResult";
import BatchesWaterBalance from "pages/BatchesWaterBalance/BatchesWaterBalance";
import BatchesWaterBalanceCreate from "pages/BatchesWaterBalanceCreate/BatchesWaterBalanceCreate";
import BatchesWaterBalanceEdit from "pages/BatchesWaterBalanceEdit/BatchesWaterBalanceEdit";
import BatchesWaterBalanceResult from "pages/BatchesWaterBalanceResult/BatchesWaterBalanceResult";
import BatchesWaterBalanceSimulationResult from "pages/BatchesWaterBalanceSimulationResult/BatchesWaterBalanceSimulationResult";
import SizingEvaluation from "pages/SizingEvaluation/SizingEvaluation";
import ResizingOptimizationCreate from "pages/SizingOptimizationCreate/SizingOptimizationCreate";
import SizingOptimizationEdit from "pages/SizingOptimizationEdit/SizingOptimizationEdit";
import SizingOptimizationResult from "pages/SizingOptimizationResult/SizingOptimizationResult";
import SizingOptimizationBatchResult from "pages/SizingOptimizationBatchResult/SizingOptimizationBatchResult";
import SizingOptimizationBatchSimulationResult from "pages/SizingOptimizationBatchSimulationResult/SizingOptimizationBatchSimulationResult";
import { getUserDataState } from "store/user/selectors";
import auth from "store/projects/sagas";
import SettingSubscription from "pages/SettingSubscription/SettingSubscription";
import { DndProvider } from "react-dnd";

const routes = [
  {
    path: ROUTES.MAIN,
    element: <Main />,
  },
  {
    path: ROUTES.LOGIN,
    element: <Login />,
  },
  {
    path: ROUTES.REGISTER,
    element: <Register />,
  },
  {
    path: ROUTES.FORGOT,
    element: <Forgot />,
  },
  {
    path: ROUTES.CLIENTS_LIST,
    element: <ClientsList />,
  },
  {
    path: ROUTES.PROJECTS_LIST,
    element: <ProjectsList />,
  },
  {
    path: ROUTES.NOT_FOUND,
    element: <NotFound />,
  },
];

const mainRoutes = [
  {
    path: ROUTES.STRUCTURES,
    element: <Structures />,
  },
  {
    path: ROUTES.STRUCTURES_CREATE,
    element: <StructuresCreate />,
  },
  {
    path: ROUTES.STRUCTURES_CREATE_FROM_TEMPLATE,
    element: <StructuresCreate />,
  },
  {
    path: ROUTES.STRUCTURES_EDIT,
    element: <StructuresEdit />,
  },
  {
    path: ROUTES.DATASETS,
    element: <Datasets />,
  },
  {
    path: ROUTES.DATASETS_CREATE,
    element: <DatasetsCreate />,
  },
  {
    path: ROUTES.DATASETS_EDIT,
    element: <DatasetEdit />,
  },
  {
    path: ROUTES.DEFAULT_SOIL_INFORMATIONS,
    element: <DefaultSoilsInformations />,
  },
  {
    path: ROUTES.CROPS,
    element: <Crops />,
  },
  {
    path: ROUTES.CROPS_CREATE,
    element: <CropsCreate />,
  },
  {
    path: ROUTES.CROPS_CREATE_FROM_TEMPLATE,
    element: <CropsCreate />,
  },
  {
    path: ROUTES.CROPS_EDIT,
    element: <CropsEdit />,
  },
  {
    path: ROUTES.STEERING_ALGORITHM,
    element: <SteeringAlgorithm />,
  },
  {
    path: ROUTES.STEERING_ALGORITHM_LIST_FROM_CROP,
    element: <SteeringAlgorithm />,
  },
  {
    path: ROUTES.STEERING_ALGORITHM_CREATE,
    element: <SteeringAlgorithmsCreate />,
  },
  {
    path: ROUTES.STEERING_ALGORITHM_CREATE_FROM_CROP,
    element: <SteeringAlgorithmsCreate />,
  },
  {
    path: ROUTES.STEERING_ALGORITHM_EDIT,
    element: <SteeringAlgorithmsEdit />,
  },
  {
    path: ROUTES.SOIL_INFORMATIONS,
    element: <SoilInformations />,
  },
  {
    path: ROUTES.SOIL_INFORMATION_CREATE,
    element: <SoilInformationsCreate />,
  },
  {
    path: ROUTES.SOIL_INFORMATION_EDIT,
    element: <SoilInformationsEdit />,
  },
  {
    path: ROUTES.STEERING_ALGORITHM_EDIT,
    element: <SteeringAlgorithmsEdit />,
  },
  {
    path: ROUTES.SIMULATIONS,
    element: <Simulations />,
  },
  {
    path: ROUTES.SIMULATIONS_CREATE,
    element: <SimulationCreate />,
  },
  {
    path: ROUTES.SIMULATIONS_EDIT,
    element: <SimulationEdit />,
  },
  {
    path: ROUTES.SIMULATIONS_RESULT,
    element: <SimulationResult />,
  },
  {
    path: ROUTES.WATER_BALANCES,
    element: <WaterBalances />,
  },
  {
    path: ROUTES.WATER_BALANCES_CREATE,
    element: <WaterBalanceCreate />,
  },
  {
    path: ROUTES.WATER_BALANCES_EDIT,
    element: <WaterBalanceEdit />,
  },
  {
    path: ROUTES.WATER_BALANCES_RESULT,
    element: <WaterBalanceResult />,
  },
  {
    path: ROUTES.BATCHES,
    element: <Batches />,
  },
  {
    path: ROUTES.BATCHES_CREATE,
    element: <BatchesCreate />,
  },
  {
    path: ROUTES.BATCHES_EDIT,
    element: <BatchesEdit />,
  },
  {
    path: ROUTES.BATCHES_RESULT,
    element: <BatchesResult />,
  },
  {
    path: ROUTES.BATCHES_SIMULATION_RESULT,
    element: <BatchesSimulationResult />,
  },
  {
    path: ROUTES.BATCHES_WATER_BALANCE,
    element: <BatchesWaterBalance />,
  },
  {
    path: ROUTES.BATCHES_WATER_BALANCE_CREATE,
    element: <BatchesWaterBalanceCreate />,
  },
  {
    path: ROUTES.BATCHES_WATER_BALANCE_EDIT,
    element: <BatchesWaterBalanceEdit />,
  },
  {
    path: ROUTES.BATCHES_WATER_BALANCE_RESULT,
    element: <BatchesWaterBalanceResult />,
  },
  {
    path: ROUTES.BATCHES_WATER_BALANCE_SIMULATION_RESULT,
    element: <BatchesWaterBalanceSimulationResult />,
  },
  // {
  //   path: ROUTES.SIZING_OPTIMIZATION,
  //   element: <SizingEvaluation/>,
  // },
  // {
  //   path: ROUTES.SIZING_OPTIMIZATION_CREATE,
  //   element: <ResizingOptimizationCreate/>,
  // },
  // {
  //   path: ROUTES.SIZING_OPTIMIZATION_EDIT,
  //   element: <SizingOptimizationEdit/>,
  // },
  // {
  //   path: ROUTES.SIZING_OPTIMIZATION_RESULT,
  //   element: <SizingOptimizationResult/>,
  // },
  // {
  //   path: ROUTES.SIZING_OPTIMIZATION_BATCH_RESULT,
  //   element: <SizingOptimizationBatchResult/>,
  // },
  // {
  //   path: ROUTES.SIZING_OPTIMIZATION_BATCH_SIMULATION_RESULT,
  //   element: <SizingOptimizationBatchSimulationResult/>,
  // },
];

const settingRoutes = [
  {
    path: ROUTES.SETTING_USERS,
    element: <SettingUsers />,
  },
  {
    path: ROUTES.SETTING_SUBSCRIPTION,
    element: <SettingSubscription />,
  },
];

function App() {
  const location = useLocation();
  // const locale = (process.env.REACT_APP_LOCALE as Languages) ?? Languages.EN;

  const state = location.state as { background?: Location };
  const background = state?.background;

  return (
    <DndProvider backend={HTML5Backend}>
      <Provider store={store}>
        <TranslationProvider>
          <ToastContainer icon={false} />
          <Routes location={background || location}>
            {routes.map(({ path, element }) => (
              <Route key={path} path={path} element={element} />
            ))}

            <Route element={<MainLayout />}>
              {mainRoutes.map(({ path, element }) => (
                <Route key={path} path={path} element={element} />
              ))}
            </Route>

            <Route element={<SettingsLayout />}>
              {settingRoutes.map(({ path, element }) => (
                <Route key={path} path={path} element={element} />
              ))}
            </Route>

            <Route path="*" element={<Navigate to={ROUTES.NOT_FOUND} />} />
          </Routes>

          {!!background && (
            <Routes>
              <Route path={ROUTES.PROFILE} element={<ProfilePage />} />
            </Routes>
          )}
        </TranslationProvider>
      </Provider>
    </DndProvider>
  );
}

export default App;
