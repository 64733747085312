import { useOptionData } from "hooks/useOptionData";
import { ICrops } from "pages/Crops/Crops";
import { useEffect, useMemo, useState } from "react";
import styled from "./styled.module.scss";
import { ReactComponent as Crops } from "assets/icons/crops.svg";
import clsx from "clsx";
import { FormattedMessage, useIntl } from "react-intl";
import CropTemplateModalMessages from "./CropTemplateModalMessages";
import { ICON_NAMES_ENUM, Icon } from "components/common/icon/Icon";
import CommonMessages from "components/common/CommonMessages";
import { ReactComponent as Sun } from "assets/icons/sun.svg";
import { ReactComponent as Calendar } from "assets/icons/calendar2.svg";
import { useSelector } from "react-redux";
import { getAllCropPack } from "store/crop_pack/selector";
import Button from "components/common/button/Button";
import { ICropsTemplate } from "pages/CropsCreate/CropsCreateTypes";
import { ICropType } from "pages/CropsCreate/CropsCreateTypes";
import { Tooltip } from "@mui/material";

type ICropTemplateModal = {
  isOpen: boolean;
  date_templates: ICropsTemplate[];
  degree_templates: ICropsTemplate[];
  onHideModal: () => void;
  onSelectCropTemplate: (template: ICropsTemplate) => void;
  onBack: () => void;
  onToggleType: () => void;
};

const CropTemplateModal = ({
  isOpen,
  date_templates,
  degree_templates,
  onToggleType,
  onHideModal,
  onSelectCropTemplate,
  onBack,
}: ICropTemplateModal) => {
  const [templateSelected, setTemplateSelected] =
    useState<ICropsTemplate | null>(null);
  const [yearToEdit, setYearToEdit] = useState(2020);
  const [hasPack, setHasPack] = useState(false);
  const cropPack = useSelector(getAllCropPack);

  const monthList = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const displayDate = (date: string | undefined) => {
    if (!date) return "";
    const month = monthList[Number(date.substring(5).split("-")[0]) - 1];
    return `${date.substring(5).split("-")[1]}  ${month}`;
  };

  const intl = useIntl();

  const toggle = () => {
    setTemplateSelected(null);
    onToggleType();
  };

  useEffect(() => {
    if (!isOpen) setTemplateSelected(null);
  }, [isOpen]);

  useEffect(() => {
    const onKeyUp = (e: KeyboardEvent) => {
      if (e.key == "Escape") {
        onHideModal();
      }
    };
    if (isOpen) window.addEventListener("keyup", onKeyUp);
    return () => window.removeEventListener("keyup", onKeyUp);
  }, [isOpen]);

  const selectCropTemplate = (crop: ICropsTemplate) => {
    setHasPack(false);
    if (crop.crop_type === "grassland" && cropPack.prairie_pack)
      setHasPack(true);
    else if (crop.crop_type === "GC" && cropPack.big_crop) setHasPack(true);
    else if (crop.crop_type === "viti" && cropPack.viticole) setHasPack(true);
    else if (crop.crop_type === "arbo" && cropPack.arbo) setHasPack(true);
    else if (crop.crop_type === "maraichage" && cropPack.maraichage)
      setHasPack(true);
    else if (crop.crop_type === "custom") setHasPack(true);
    setTemplateSelected(crop);
  };

  useEffect(() => {
    if (templateSelected?.periods[0]) {
      if (templateSelected?.periods[0].start_date) {
        setTemplateSelected((prev) => {
          return {
            ...prev!,
            periods: prev!.periods.map((period) => {
              let start = period.start_date!.split("-");
              let end = period.end_date!.split("-");
              end[0] = start[0] = String(yearToEdit);
              return {
                ...period,
                start_date: start.join("-"),
                end_date: end.join("-"),
              };
            }),
          };
        });
      } else {
        setTemplateSelected((prev) => {
          return {
            ...prev!,
            periods: prev!.periods.map((period) => {
              let sowing_date = period.sowing_date!.split("-");
              sowing_date[0] = String(yearToEdit);
              return {
                ...period,
                sowing_date: sowing_date.join("-"),
              };
            }),
          };
        });
      }
    }
  }, [yearToEdit]);

  if (!isOpen) return <></>;
  return (
    <div className={styled.absolute}>
      <div className={styled.modalBackground}>
        <div className={styled.modalContainer}>
          <div className={styled.leftSide}>
            <div className={styled.templateSelectorLabel}>
              <FormattedMessage {...CropTemplateModalMessages.selectTemplate} />
            </div>
            <div className={styled.littleLimiter} />
            <div className={styled.templateSelector}>
              <div className={styled.titleSelect}>
                <Sun />
                <FormattedMessage {...CropTemplateModalMessages.cropDegree} />
              </div>
              {degree_templates.map((crop) => (
                <div
                  key={crop.name}
                  className={clsx(styled.templateSelectorItem, {
                    [styled.selected]:
                      templateSelected && templateSelected.name == crop.name,
                  })}
                  onClick={() => selectCropTemplate(crop)}
                >
                  <div className={styled.iconCrop}>
                    <Crops />
                  </div>
                  <p className={styled.cropNameSelected}>{crop.name}</p>
                  {!!crop.tooltip && (
                    <Tooltip title={crop.tooltip}>
                      <div
                        className={styled["help-icon"]}
                        style={{ width: "20px", marginLeft: "auto" }}
                      >
                        <Icon
                          name={ICON_NAMES_ENUM.help_circle}
                          className={styled.file__icon}
                        />
                      </div>
                    </Tooltip>
                  )}
                </div>
              ))}
            </div>
            <div className={styled.templateSelector}>
              <div className={styled.titleSelect}>
                <Calendar />
                <FormattedMessage {...CropTemplateModalMessages.cropDate} />
              </div>
              {date_templates.map((crop) => (
                <div
                  key={crop.name}
                  className={clsx(styled.templateSelectorItem, {
                    [styled.selected]:
                      templateSelected && templateSelected.name == crop.name,
                  })}
                  onClick={() => selectCropTemplate(crop)}
                >
                  <Crops />
                  <p className={styled.cropNameSelected}>{crop.name}</p>
                  {!!crop.tooltip && (
                    <Tooltip title={crop.tooltip}>
                      <div
                        className={styled["help-icon"]}
                        style={{ width: "20px", marginLeft: "auto" }}
                      >
                        <Icon
                          name={ICON_NAMES_ENUM.help_circle}
                          className={styled.file__icon}
                        />
                      </div>
                    </Tooltip>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className={styled.rightSide}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <input
                className={styled.templateLabel}
                disabled
                value={templateSelected ? templateSelected.name : ""}
                placeholder={intl.formatMessage(
                  CropTemplateModalMessages.selectTemplate
                )}
              />
              <div style={{ display: "flex", gap: "1rem" }}>
                <div className={styled.cancelButton} onClick={onBack}>
                  <FormattedMessage {...CommonMessages.cancel} />{" "}
                </div>
                <div
                  className={clsx(styled.selectButton, {
                    [styled.disabled]: templateSelected == null || !hasPack,
                  })}
                  onClick={() => {
                    if (hasPack) onSelectCropTemplate(templateSelected!);
                  }}
                >
                  <FormattedMessage
                    {...CropTemplateModalMessages.selectTemplate}
                  />{" "}
                </div>
              </div>
            </div>

            <div className={styled.littleLimiter} />

            <div className={styled.tableContainer}>
              <table
                className={clsx(styled.table, {
                  [styled.blur]: !hasPack,
                })}
              >
                <thead>
                  <tr>
                    <th>
                      <FormattedMessage
                        {...CropTemplateModalMessages.stageName}
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        {...CropTemplateModalMessages.cropHeight}
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        {...(templateSelected &&
                        templateSelected.periods[0] &&
                        templateSelected.periods[0].start_date
                          ? CropTemplateModalMessages.startDate
                          : CropTemplateModalMessages.startDegreeDay)}
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        {...(templateSelected &&
                        templateSelected.periods[0] &&
                        templateSelected.periods[0].start_date
                          ? CropTemplateModalMessages.endDate
                          : CropTemplateModalMessages.endDegreeDay)}
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        {...CropTemplateModalMessages.culturalCoefficient}
                      />
                    </th>
                    <>
                      <th>
                        <FormattedMessage
                          {...CropTemplateModalMessages.sowingDate}
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          {...CropTemplateModalMessages.baseTemperature}
                        />
                      </th>
                    </>
                  </tr>
                </thead>
                <tbody>
                  {templateSelected &&
                    hasPack &&
                    templateSelected.periods.map((item) => (
                      <tr key={`template ${item.name}`}>
                        <td>
                          <div className={styled["td-element"]}>
                            {item.name}
                          </div>
                        </td>
                        <td>
                          <div className={styled["td-element"]}>
                            {item.crop_height ? item.crop_height : 0}
                          </div>
                        </td>
                        <td>
                          <div className={styled["td-element"]}>
                            {templateSelected &&
                            templateSelected.periods[0] &&
                            templateSelected.periods[0].start_date
                              ? displayDate(item.start_date)
                              : item.start_degree_day}
                          </div>
                        </td>
                        <td>
                          <div className={styled["td-element"]}>
                            {item.start_date
                              ? displayDate(item.end_date)
                              : item.end_degree_day}
                          </div>
                        </td>
                        <td>
                          <div className={styled["td-element"]}>
                            {item.crop_coefficient}
                          </div>
                        </td>
                        {item.start_degree_day !== undefined ? (
                          <>
                            <td>{displayDate(item.sowing_date)}</td>
                            <td>{item.base_degree_day}</td>
                          </>
                        ) : (
                          <>
                            {/* <div className={styled.emptyTD}> */}
                            <td className={styled.emptyTD} />
                            <td className={styled.emptyTD} />

                            {/* </div> */}
                          </>
                        )}
                      </tr>
                    ))}
                  {!hasPack &&
                    Array.from({ length: 7 }).map((_, index) => (
                      <tr key={`template-default-${index}`}>
                        <td>
                          <div className={styled["td-element"]}>
                            Default-{index}
                          </div>
                        </td>
                        <td>
                          <div className={styled["td-element"]}>0</div>
                        </td>
                        <td>
                          <div className={styled["td-element"]}>0</div>
                        </td>
                        <td>
                          <div className={styled["td-element"]}>0</div>
                        </td>
                        <td>
                          <div className={styled["td-element"]}>0</div>
                        </td>
                        <td>0</td>
                        <td>0</td>
                      </tr>
                    ))}
                </tbody>
              </table>
              {hasPack ? null : (
                <div className={styled.upgradeBtnContainer}>
                  <Button
                    variant="fill"
                    className={styled.upgradeBtn}
                    onClick={() => {}}
                  >
                    <FormattedMessage {...CommonMessages.upgradePack} />
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CropTemplateModal;
