import React, { useEffect, useState } from "react";
import styled from "./SoilInformationsEdit.module.scss";
import EditName from "components/EditName/EditName";
import Button from "components/common/button/Button";
import commonMessages from "components/common/CommonMessages";
import { FormattedMessage, useIntl } from "react-intl";
import SoilInformationsCreateMessages from "../SoilInformationsCreate/SoilInformationsCreateMessages";
import { useNavigate } from "react-router-dom";
import { Icon, ICON_NAMES_ENUM } from "components/common/icon/Icon";
import MainContent from "components/MainContent/MainContent";
import {
  LeaveModalEnum,
  LeaveModalWindow,
} from "components/LeaveModalWindow/LeaveModalWindow";
import { usePrompt } from "hooks/usePromt";
import { isEqual } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { getSoilInformationsItemState } from "store/soil_informations/selectors";
import { SoilInformationsEditHook } from "./SoilInformationsEditHook";
import Input from "components/common/input/Input";
import CheckBox from "components/common/checkBox/CheckBox";
import { ISoilInformations } from "pages/SoilInformationsCreate/SoilInformationsCreateTypes";
import { getAllFeatures } from "store/features/selector";
import UnSubscribeButton from "components/common/unSubscribe/UnSubscribeButton";
import ModalSubscribe from "components/ModalSubscribe/ModalSubsribe";
import { getSubscription } from "store/subscription/actions";

function SoilInformationsEdit() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const features = useSelector(getAllFeatures);
  const intl = useIntl();
  const [show, onShow] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    models: { client_id, project_id, id, isLoading, formik },
    commands: { onDeleteSoilInformation, onBack },
  } = SoilInformationsEditHook();
  const soil_information = useSelector(getSoilInformationsItemState);

  const fixValues = {
    name: formik.values.name,
    ru_water: formik.values.ru_water,
    rfu_water: formik.values.rfu_water,
    irrigation: formik.values.irrigation,
  };

  const isDataChanged = !isEqual(fixValues, {
    name: soil_information.name,
    ru_water: soil_information.ru_water,
    rfu_water: soil_information.rfu_water,
    irrigation: soil_information.irrigation,
  });

  useEffect(() => {
    dispatch(getSubscription({ clientId: client_id }));
  }, [client_id, project_id]);

  usePrompt(isDataChanged && !formik.isSubmitting && !show);

  const onChangeCheckBox =
    (key: keyof ISoilInformations) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      formik.setFieldValue(key, e.target.checked);
    };

  return (
    <form onSubmit={formik.handleSubmit}>
      <MainContent
        header={
          <>
            <EditName
              onFocus={(event) => {
                event.target.select();
              }}
              placeholder={intl.formatMessage(
                SoilInformationsCreateMessages.soilInformationName
              )}
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
              errorMessage={formik.touched.name ? formik.errors.name : ""}
              onBlur={formik.handleBlur}
            />
            <div className={styled.flex}>
              <Button
                onClick={() => onShow(true)}
                iconBefore={<Icon name={ICON_NAMES_ENUM.delete} />}
                variant="text"
                className={styled.deleteButton}
              >
                <FormattedMessage {...commonMessages.delete} />
              </Button>
              <Button variant="text" onClick={onBack}>
                <FormattedMessage {...commonMessages.cancel} />
              </Button>

              {features && features.custom_soil ? (
                <Button
                  iconBefore={<Icon name={ICON_NAMES_ENUM.save} />}
                  type="submit"
                  isLoading={formik.isSubmitting}
                  disabled={formik.isSubmitting || !isDataChanged}
                >
                  <FormattedMessage {...commonMessages.save} />
                </Button>
              ) : (
                <UnSubscribeButton
                  iconBefore={<Icon name={ICON_NAMES_ENUM.save} />}
                  onClick={() => setIsModalOpen(!isModalOpen)}
                >
                  <FormattedMessage {...commonMessages.save} />
                </UnSubscribeButton>
              )}
            </div>
          </>
        }
      >
        <div>
          <h2 className={styled["section-title"]}>
            <FormattedMessage {...SoilInformationsCreateMessages.infomations} />
          </h2>

          <div className={styled.informationContainer}>
            <Input
              name="ru_water"
              type="number"
              label={intl.formatMessage(
                SoilInformationsCreateMessages.ru_water
              )}
              value={formik.values.ru_water}
              onChange={formik.handleChange}
              errorMessage={formik.touched.name ? formik.errors.ru_water : ""}
              step={0.01}
              className={styled.informationsInput}
            ></Input>
            <Input
              name="rfu_water"
              type="number"
              label={intl.formatMessage(
                SoilInformationsCreateMessages.rfu_water
              )}
              value={formik.values.rfu_water}
              onChange={formik.handleChange}
              errorMessage={formik.touched.name ? formik.errors.rfu_water : ""}
              step={0.01}
              className={styled.informationsInput}
            ></Input>
            <CheckBox
              label={intl.formatMessage(
                SoilInformationsCreateMessages.irrigation
              )}
              checked={formik.values.irrigation}
              onChange={onChangeCheckBox("irrigation")}
            />
          </div>
        </div>
      </MainContent>
      <LeaveModalWindow
        type={LeaveModalEnum.DELETE}
        onSave={formik.handleSubmit}
        onDelete={onDeleteSoilInformation}
        onClose={() => onShow(false)}
        show={show}
        onHide={() => onShow(false)}
      />
      <ModalSubscribe isOpen={isModalOpen} setOpen={setIsModalOpen} />
    </form>
  );
}

export { SoilInformationsEdit };
