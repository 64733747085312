import { put, takeLatest, call } from "redux-saga/effects";
// actions
import { getSubscription as action } from "store/subscription/actions";
import { setFeatures as actionFeature } from "store/features/actions";
import { setCropPack as actionCropPack } from "store/crop_pack/actions";
// api
import { getSubscription as api } from "store/subscription/api";
import { errorNotifications } from "utils/errorNotifications";

function* saga({ payload }) {
  try {
    yield put(action.request());

    const response = yield call(api, payload);
    yield put(actionFeature.fulfill(response));
    yield put(actionCropPack.fulfill(response));
    yield put(action.success(response));
  } catch (error) {
    yield put(action.failure());
    yield call(errorNotifications, error.response.data.data);
  } finally {
    yield put(action.fulfill());
  }
}

export default function* getSubscription() {
  yield takeLatest(action.TRIGGER, saga);
}
