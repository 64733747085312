import styled from "./styled.module.scss";
import { ReactComponent as Lock } from "assets/icons/lock-closed.svg";
import { ReactComponent as Open } from "assets/icons/lock-open.svg";
import { useState } from "react";

interface IUnlockbtn {
  onClick: () => void;
}

const UnlockBtn = ({ onClick }: IUnlockbtn) => {
  const [open, setOpen] = useState(false);

  return (
    <button
      className={styled.unlockBtn}
      onClick={onClick}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      <p>Unlock Results</p>
      {open ? <Open /> : <Lock />}
    </button>
  );
};

export default UnlockBtn;
