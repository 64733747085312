import React, { useEffect, useState } from "react";
import styled from "./SoilInformationsCreate.module.scss";
import EditName from "components/EditName/EditName";
import Button from "components/common/button/Button";
import commonMessages from "components/common/CommonMessages";
import { FormattedMessage, useIntl } from "react-intl";
import soilInformationsCreateMessages from "./SoilInformationsCreateMessages";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "constants/routes";
import { Icon, ICON_NAMES_ENUM } from "components/common/icon/Icon";
import {
  SoilInformationsCreateHook,
  getInitialFormValue,
} from "./SoilInformationsCreateHook";
import MainContent from "components/MainContent/MainContent";
import { usePrompt } from "hooks/usePromt";
import { isEqual } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { ISoilInformations } from "./SoilInformationsCreateTypes";
import Input from "components/common/input/Input";
import CheckBox from "components/common/checkBox/CheckBox";
import { errorNotifications } from "utils/errorNotifications";
import { getAllFeatures } from "store/features/selector";
import { Loader } from "@react-three/drei";
import { getSubscription } from "store/subscription/actions";

function SoilInformationsCreate() {
  const dispatch = useDispatch();
  const { client_id, project_id, id } = useParams();
  const navigate = useNavigate();
  const intl = useIntl();
  const {
    models: { formik },
    commands: { onBack },
  } = SoilInformationsCreateHook();
  const features = useSelector(getAllFeatures);
  const [isDisplayed, setIsDisplayed] = useState(false);

  useEffect(() => {
    dispatch(getSubscription({ clientId: client_id }));
  }, [client_id, project_id]);

  useEffect(() => {
    if (features)
      if (!features.custom_soil) {
        errorNotifications(
          "You don't have the right underwriting plan to create custom soil."
        );
        navigate(
          ROUTES.SOIL_INFORMATIONS.replace(
            ":client_id",
            client_id as string
          ).replace(":project_id", project_id as string)
        );
      } else setIsDisplayed(true);
  }, [features]);

  usePrompt(
    !isEqual(formik.values, getInitialFormValue(intl)) && !formik.isSubmitting
  );

  const onChangeCheckBox =
    (key: keyof ISoilInformations) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      formik.setFieldValue(key, e.target.checked);
    };

  if (isDisplayed === true)
    return (
      <div>
        <form onSubmit={formik.handleSubmit}>
          <MainContent
            header={
              <>
                <EditName
                  autoFocus
                  onFocus={(event) => {
                    event.target.select();
                  }}
                  placeholder={intl.formatMessage(
                    soilInformationsCreateMessages.soilInformationName
                  )}
                  name="name"
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  errorMessage={formik.touched.name ? formik.errors.name : ""}
                  onBlur={formik.handleBlur}
                />
                <div className={styled.flex}>
                  <Button variant="text" onClick={onBack}>
                    <FormattedMessage {...commonMessages.cancel} />
                  </Button>
                  <Button
                    iconBefore={<Icon name={ICON_NAMES_ENUM.save} />}
                    type="submit"
                    isLoading={formik.isSubmitting}
                    disabled={formik.isSubmitting}
                  >
                    <FormattedMessage {...commonMessages.save} />
                  </Button>
                </div>
              </>
            }
          >
            <div>
              <h2 className={styled["section-title"]}>
                <FormattedMessage
                  {...soilInformationsCreateMessages.infomations}
                />
              </h2>

              <div className={styled.informationContainer}>
                <Input
                  name="ru_water"
                  type="number"
                  label={intl.formatMessage(
                    soilInformationsCreateMessages.ru_water
                  )}
                  value={formik.values.ru_water}
                  onChange={formik.handleChange}
                  errorMessage={
                    formik.touched.name ? formik.errors.ru_water : ""
                  }
                  step={0.01}
                  className={styled.informationsInput}
                ></Input>
                <Input
                  name="rfu_water"
                  type="number"
                  label={intl.formatMessage(
                    soilInformationsCreateMessages.rfu_water
                  )}
                  value={formik.values.rfu_water}
                  onChange={formik.handleChange}
                  errorMessage={
                    formik.touched.name ? formik.errors.rfu_water : ""
                  }
                  step={0.01}
                  className={styled.informationsInput}
                ></Input>

                <CheckBox
                  label={intl.formatMessage(
                    soilInformationsCreateMessages.irrigation
                  )}
                  checked={formik.values.irrigation}
                  onChange={onChangeCheckBox("irrigation")}
                />
              </div>
            </div>
          </MainContent>
        </form>
      </div>
    );
  else return <Loader />;
}

export { SoilInformationsCreate };
