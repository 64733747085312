import { useEffect, useState } from "react";
import commonMessages from "components/common/CommonMessages";
import Button from "components/common/button/Button";
import { ReactComponent as Save } from "assets/icons/save.svg";
import { ROUTES } from "constants/routes";
import styled from "./DatasetsCreate.module.scss";
import { DatasetModel } from "components/DatasetModel/DatasetModel";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import EditName from "components/EditName/EditName";
import { FormattedMessage, useIntl } from "react-intl";
import datasetCreateMessages from "./DatasetsEditMessages";
import { createDataset } from "store/datasets/actions";
import { getTimezones } from "store/datasets/actions";
import MainContent from "components/MainContent/MainContent";
import { FormikHelpers, useFormik } from "formik";
import DatasetSchema from "./DatasetSchema";
import { IDataSet } from "./DatasetsCreateTypes";
import { usePrompt } from "hooks/usePromt";
import { isEqual } from "lodash";
import { successNotifications } from "utils/successNotifications";
import CommonMessages from "components/common/CommonMessages";
import { getProjectById } from "store/projects/selectors";
import { Loader } from "components/common/loader/Loader";
import { getAllFeatures } from "store/features/selector";
import { errorNotifications } from "utils/errorNotifications";
import { getSubscription } from "store/subscription/actions";

export const getInitialFormValue = (intl: any): IDataSet => {
  return {
    name: intl.formatMessage(datasetCreateMessages.datasetUnnamed),
    dataset_type: "universal",
    timezone: "Local time zone",
    frequency: "1H",
    upload: null,
    dataset_filename: "",
    comment: "",
  };
};

function DatasetsCreate() {
  const navigate = useNavigate();
  const intl = useIntl();
  const dispatch = useDispatch();
  const { client_id, project_id } = useParams();
  const features = useSelector(getAllFeatures);
  const actualProjetct = useSelector(getProjectById(project_id));
  const [isDisplayed, setIsDisplayed] = useState(false);

  useEffect(() => {
    dispatch(getSubscription({ clientId: client_id }));
  }, [client_id, project_id]);

  useEffect(() => {
    dispatch(getTimezones());
  }, []);

  useEffect(() => {
    if (features)
      if (!features.custom_dataset) {
        errorNotifications(
          "You don't have the right underwriting plan to create custom dataset."
        );
        navigate(
          ROUTES.DATASETS.replace(":client_id", client_id as string).replace(
            ":project_id",
            project_id as string
          )
        );
      } else setIsDisplayed(true);
  }, [features]);

  const onBack = () =>
    navigate(
      ROUTES.DATASETS.replace(":client_id", client_id as string).replace(
        ":project_id",
        project_id as string
      )
    );
  const onEditPage = (id: number | string) =>
    navigate(
      ROUTES.DATASETS_EDIT.replace(":client_id", client_id as string)
        .replace(":project_id", project_id as string)
        .replace(":id", id as string)
    );

  const onSubmit = (values: IDataSet, actions: FormikHelpers<IDataSet>) => {
    dispatch(
      createDataset({
        ...values,
        clientId: client_id,
        projectId: project_id,
        callback: ({ data }: any) => {
          successNotifications({
            title: intl.formatMessage(CommonMessages.create),
            message: intl.formatMessage(CommonMessages.successCreate, {
              objet_type: intl.formatMessage(CommonMessages.dataset),
            }),
          });
          onEditPage(data.id);
        },
        finallyCallback: () => actions.setSubmitting(false),
      })
    );
  };

  const formik = useFormik<IDataSet>({
    validationSchema: DatasetSchema(intl),
    onSubmit,
    initialValues: getInitialFormValue(intl),
  });

  usePrompt(
    !isEqual(formik.values, getInitialFormValue(intl)) && !formik.isSubmitting
  );

  if (isDisplayed === true)
    return (
      <form onSubmit={formik.handleSubmit}>
        <MainContent
          header={
            <>
              <EditName
                autoFocus
                onFocus={(event) => {
                  event.target.select();
                }}
                placeholder={intl.formatMessage(
                  datasetCreateMessages.datasetUnnamed
                )}
                name={"name"}
                onChange={formik.handleChange}
                value={formik.values.name}
                errorMessage={formik.touched.name ? formik.errors.name : ""}
                onBlur={formik.handleBlur}
              />
              <div className={styled.buttons}>
                <Button
                  onClick={() =>
                    navigate(
                      ROUTES.DATASETS.replace(
                        ":client_id",
                        client_id as string
                      ).replace(":project_id", project_id as string)
                    )
                  }
                  variant="text"
                >
                  <FormattedMessage {...commonMessages.cancel} />
                </Button>
                <Button
                  iconBefore={<Save />}
                  type="submit"
                  isLoading={formik.isSubmitting}
                  disabled={formik.isSubmitting}
                >
                  <FormattedMessage {...commonMessages.save} />
                </Button>
              </div>
            </>
          }
        >
          <div className={styled.list}>
            {actualProjetct ? (
              <DatasetModel
                handelChange={formik.setFieldValue}
                errors={formik.errors}
                formik={formik}
                utc={actualProjetct.utc}
              />
            ) : (
              <Loader />
            )}
          </div>
        </MainContent>
      </form>
    );
  else return <Loader />;
}

export { DatasetsCreate };
