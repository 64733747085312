import { defineMessages } from "react-intl";

export default defineMessages({
  projects: {
    id: "ClientCardMessages.projects",
    defaultMessage: "Projects",
  },
  lastUpdate: {
    id: "ClientCardMessages.lastUpdate",
    defaultMessage: "Last update",
  },
  created: {
    id: "ClientCardMessages.created",
    defaultMessage: "Created",
  },
});
