import { all, fork } from "redux-saga/effects";
// sagas
import getSoilInformations from "store/soil_informations/sagas/getSoilInformations";
import getSingleSoilInformation from "store/soil_informations/sagas/getSingleSoilInformation";
import createSoilInformation from "store/soil_informations/sagas/createSoilInformation";
import createCurrentSoilInformationByLocalization from "store/soil_informations/sagas/createCurrentSoilInformationByLocalization";
import copySoilInformation from "store/soil_informations/sagas/copySoilInformation";
import deleteSoilInformation from "store/soil_informations/sagas/deleteSoilInformation";
import updateSoilInformation from "store/soil_informations/sagas/updateSoilInformation";
import copySoilInformationToOtherProject from "store/soil_informations/sagas/copySoilInformationToOtherProject";

export default function* auth() {
  yield all([
    fork(getSoilInformations),
    fork(getSingleSoilInformation),
    fork(createSoilInformation),
    fork(createCurrentSoilInformationByLocalization),
    fork(copySoilInformation),
    fork(deleteSoilInformation),
    fork(updateSoilInformation),
    fork(copySoilInformationToOtherProject),
  ]);
}
