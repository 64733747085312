import { useState, useEffect } from "react";
import AddPeriodSchema from "./AddPeriodSchema";
import { useFormik } from "formik";
import { ITableFirstFields } from "./AddModalPeriodCropsTableTypes";
import { ITableField } from "pages/CropsCreate/CropsCreateTypes";
import { useIntl } from "react-intl";
import validationMessages from "components/common/ValidationMessages";
import AddModalPeriodCropsTableMessages from "./AddModalPeriodCropsTableMessages";
const getDefaultFormValue = (intl: any): ITableFirstFields => {
  return {
    name: intl.formatMessage(AddModalPeriodCropsTableMessages.stageUnnamed),
    crop_height: 0,
    dateStart: null,
    dateEnding: null,
    start_degree_day: 15,
    end_degree_day: 25,
    crop_coefficient: 0.6,
    rank: 0,
  };
};

export interface IPeriodCreateHook {
  onSave: (v: ITableField) => void;
  onEdit: (v: ITableField) => void;
  periods: Array<ITableField>;
  isEdit: boolean;
  currentPeriod?: ITableField | undefined;
  show: boolean;
  isDegreDays: boolean;
}
interface IError {
  dateStart?: string;
  dateEnding?: string;
  start_degree_day?: string;
  end_degree_day?: string;
}

function AddModalPeriodCropsTableHooks({
  onSave,
  periods,
  isEdit,
  onEdit,
  currentPeriod,
  show,
  isDegreDays,
}: IPeriodCreateHook) {
  const intl = useIntl();
  const [isDatepicker, setIsDatepicker] = useState(true);
  const onSelect = (option: any) => {
    option.value == "Dates" ? setIsDatepicker(true) : setIsDatepicker(false);
  };

  const validate = (values: ITableFirstFields) => {
    const errors: IError = {};
    if (!isDegreDays) {
      if (!values.dateStart && !values.dateEnding) {
        errors.dateEnding = intl.formatMessage(validationMessages.required);
        errors.dateStart = intl.formatMessage(validationMessages.required);
      } else if (!values.dateEnding) {
        errors.dateEnding = intl.formatMessage(validationMessages.required);
      } else if (!values.dateStart) {
        errors.dateStart = intl.formatMessage(validationMessages.required);
      } else if (values.dateStart > values.dateEnding) {
        errors.dateEnding = intl.formatMessage(
          validationMessages.invalidDateRange
        );
        errors.dateStart = intl.formatMessage(
          validationMessages.invalidDateRange
        );
      }
    } else {
      if (values.start_degree_day < 0)
        errors.start_degree_day = intl.formatMessage(
          validationMessages.minValue,
          { number: 0 }
        );
      if (values.end_degree_day < 0)
        errors.end_degree_day = intl.formatMessage(
          validationMessages.minValue,
          { number: 0 }
        );
      if (
        values.start_degree_day >= 0 &&
        values.end_degree_day >= 0 &&
        values.end_degree_day <= values.start_degree_day
      ) {
        errors.start_degree_day = intl.formatMessage(
          validationMessages.invalidDateRange
        );
        errors.end_degree_day = intl.formatMessage(
          validationMessages.invalidDateRange
        );
      }
    }
    return errors;
  };

  const onSubmit = (values: ITableFirstFields) => {
    const dataForm = {
      starting: formik.values.dateStart
        ? formik.values.dateStart?.format("yyyy-MM-DD")
        : "",
      ending: formik.values.dateEnding
        ? formik.values.dateEnding?.format("yyyy-MM-DD")
        : "",
    };

    isEdit
      ? onEdit({
          ...values,
          ...dataForm,
          id: isEdit && currentPeriod ? currentPeriod.id : periods.length,
          date: {
            dateStart: formik.values.dateStart,
            dateEnding: formik.values.dateEnding,
          },
        })
      : onSave({
          ...values,
          ...dataForm,
          id: isEdit && currentPeriod ? currentPeriod.id : periods.length,
          date: {
            dateStart: formik.values.dateStart,
            dateEnding: formik.values.dateEnding,
          },
        });

    formik.resetForm();
  };

  const formik = useFormik<ITableFirstFields>({
    validationSchema: AddPeriodSchema(intl),
    validate,
    initialValues: getDefaultFormValue(intl),
    onSubmit,
  });

  useEffect(() => {
    let defaultFormValue = { ...getDefaultFormValue(intl) };
    if (isEdit) {
      defaultFormValue.name = currentPeriod ? currentPeriod.name : "";
      defaultFormValue.dateStart = currentPeriod
        ? currentPeriod.date.dateStart
        : null;
      defaultFormValue.dateEnding = currentPeriod
        ? currentPeriod.date.dateEnding
        : null;
      defaultFormValue.crop_height =
        currentPeriod && currentPeriod.crop_height
          ? currentPeriod.crop_height
          : 0;
      defaultFormValue.crop_coefficient =
        currentPeriod && currentPeriod.crop_coefficient
          ? currentPeriod.crop_coefficient
          : getDefaultFormValue(intl).crop_coefficient;
      defaultFormValue.start_degree_day =
        currentPeriod && currentPeriod.start_degree_day != undefined
          ? currentPeriod.start_degree_day
          : getDefaultFormValue(intl).start_degree_day;
      defaultFormValue.end_degree_day =
        currentPeriod && currentPeriod.end_degree_day != undefined
          ? currentPeriod.end_degree_day
          : getDefaultFormValue(intl).end_degree_day;
    } else {
      defaultFormValue.name = getDefaultFormValue(intl).name;
      defaultFormValue.dateStart = null;
      defaultFormValue.dateEnding = null;
      defaultFormValue.start_degree_day = 0;
      defaultFormValue.end_degree_day = 0;
    }
    formik.setValues(defaultFormValue);
  }, [currentPeriod, isEdit, periods]);

  useEffect(() => {
    // if (!show) {
    //   setIsDatepicker(true);
    //   formik.values.name = currentPeriod?.name as string;
    //   formik.values.start_degree_day =
    //     currentPeriod?.start_degree_day as number;
    //   formik.values.end_degree_day = currentPeriod?.end_degree_day as number;
    //   formik.values.dateStart = currentPeriod?.date.dateStart ?? null;
    //   formik.values.dateEnding = currentPeriod?.date.dateEnding ?? null;
    //   formik.values.crop_coefficient =
    //     currentPeriod?.crop_coefficient as number;
    //   formik.values.crop_height = currentPeriod?.crop_height as number;
    // }
  }, [show]);

  return {
    models: { formik, isDatepicker },
    commands: { onSelect },
  };
}

export { AddModalPeriodCropsTableHooks };
