import { all, fork } from "redux-saga/effects";
// sagas

import createSubscription from "./createSubscription";
import getSubscription from "./getSubscription";
import updateSubscription from "./updateSubscription";

export default function* auth() {
  yield all([
    fork(createSubscription),
    fork(getSubscription),
    fork(updateSubscription),
  ]);
}
