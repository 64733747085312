import {
  createSubscription,
  getSubscription,
  updateSubscription,
  getAllSubscription,
} from "./actions";
import { logout } from "store/user/actions";

const INITIAL_STATE = {
  subscription: null,
  isLoading: false,
};

const subscriptionReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case logout.SUCCESS: {
      return {
        ...INITIAL_STATE,
      };
    }

    case createSubscription.TRIGGER: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case createSubscription.SUCCESS: {
      return {
        ...state,
        subscription: payload,
      };
    }

    case createSubscription.FULFILL: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case getSubscription.TRIGGER: {
      return {
        ...state,
        subscription: null,
        isLoading: true,
      };
    }

    case getSubscription.SUCCESS: {
      return {
        ...state,
        subscription: payload,
        isLoading: false,
      };
    }

    case getSubscription.FULFILL: {
      return {
        ...state,
      };
    }

    case getSubscription.FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case updateSubscription.TRIGGER: {
      return {
        ...state,
        subscription: null,
        isLoading: true,
      };
    }

    case updateSubscription.SUCCESS: {
      return {
        ...state,
        subscription: payload,
        isLoading: false,
      };
    }

    case updateSubscription.FULFILL: {
      return {
        ...state,
      };
    }

    case updateSubscription.FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default subscriptionReducer;
