import routine from "store/services/routine";

export const getSoilInformations = routine("GET_SOIL_INFORMATIONS");
export const getSingleSoilInformation = routine("GET_SOIL_INFORMATION");
export const createSoilInformation = routine("CREATE_SOIL_INFORMATION");
export const createCurrentSoilInformationByLocalization = routine(
  "CREATE_CURRENT_SOIL_INFORMATION_BY_LOCALIZATION"
);
export const copySoilInformation = routine("COPY_SOIL_INFORMATION");
export const deleteSoilInformation = routine("DELETE_SOIL_INFORMATION");
export const updateSoilInformation = routine("UPDATE_SOIL_INFORMATION");
export const copySoilInformationToOtherProject = routine(
  "COPY_SOIL_INFORMATION_TO_OTHER_PROJECT"
);
