import { apiGET, apiPOST, apiDELETE, apiPATCH } from "store/services/api";

export const getSoilInformations = ({ clientId, projectId }) =>
  apiGET({
    url: `/clients/${clientId}/projects/${projectId}/water_balances`,
  });

export const getSingleSoilInformation = ({
  clientId,
  projectId,
  soilInformationId,
}) =>
  apiGET({
    url: `/clients/${clientId}/projects/${projectId}/water_balances/${soilInformationId}`,
  });

export const createSoilInformation = ({ clientId, projectId, ...data }) =>
  apiPOST({
    url: `/clients/${clientId}/projects/${projectId}/water_balances`,
    data,
  });

export const createCurrentSoilInformationByLocalization = ({
  clientId,
  projectId,
}) =>
  apiPOST({
    url: `/clients/${clientId}/projects/${projectId}/water_balances/current_soil_information`,
  });

export const copySoilInformation = ({
  clientId,
  projectId,
  soilInformationId,
}) =>
  apiPOST({
    url: `/clients/${clientId}/projects/${projectId}/water_balances/${soilInformationId}/copy`,
  });

export const copySoilInformationToOtherProject = ({
  clientId,
  projectId,
  soilInformationId,
  projectToId,
}) =>
  apiPOST({
    url: `/clients/${clientId}/projects/${projectId}/water_balances/${soilInformationId}/copy/${projectToId}`,
  });

export const deleteSoilInformation = ({
  clientId,
  projectId,
  soilInformationId,
}) =>
  apiDELETE({
    url: `/clients/${clientId}/projects/${projectId}/water_balances/${soilInformationId}`,
  });

export const updateSoilInformation = ({
  clientId,
  projectId,
  soilInformationId,
  ...data
}) =>
  apiPATCH({
    url: `/clients/${clientId}/projects/${projectId}/water_balances/${soilInformationId}`,
    data,
  });
