import { useEffect, useState } from "react";
import styled from "./styled.module.scss";
import Button from "components/common/button/Button";
import { FormattedMessage, useIntl } from "react-intl";
import MainContent from "components/MainContent/MainContent";
import { ReactComponent as Edit } from "assets/icons/edit.svg";
import SettingSubscriptionMessages from "pages/SettingSubscription/SettingSubscriptionMessage";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getUserDataState } from "store/user/selectors";
import { ROUTES } from "constants/routes";
import {
  createSubscription,
  getSubscription,
  updateSubscription,
} from "store/subscription/actions";
import {
  getSubscriptionLoader,
  getSubscriptionDatas,
} from "store/subscription/selector";
import { successNotifications } from "utils/successNotifications";
import CommonMessages from "components/common/CommonMessages";
import Toggle from "components/common/toggle/Toggle";
import { getAllFeatures } from "store/features/selector";
import { getAllCropPack } from "store/crop_pack/selector";
import { editFeatures } from "store/features/actions";
import { Loader } from "components/common/loader/Loader";
import { editCropPack } from "store/crop_pack/actions";
import SubscribeContent from "components/SubscribeContent/SubscribeContent";
import { getProjectsListState } from "store/projects/selectors";
import ProgressBar from "react-bootstrap/ProgressBar";

interface ISubscriptionCreate {
  plan: number;
}

interface IFeatures {
  create_structure_template: boolean;
  create_structure: boolean;
  create_crop: boolean;
  custom_dataset: boolean;
  openmeteo: boolean;
  meteonorm: boolean;
  custom_soil: boolean;
  database_soil: boolean;
  custom_steering: boolean;
  irradiance_simulation: boolean;
  prod_simulation: boolean;
  water_simulation: boolean;
  thermal_simulation: boolean;
  yield_simulation: boolean;
  batchs: boolean;
}

interface ICropPacks {
  prairie_pack: boolean;
  big_crop: boolean;
  viticole: boolean;
  arbo: boolean;
  maraichage: boolean;
}

const defaultValueSubscription: ISubscriptionCreate = {
  plan: 0,
};

const defaultValueFeatures: IFeatures = {
  create_structure_template: false,
  create_structure: false,
  create_crop: false,
  custom_dataset: false,
  openmeteo: false,
  meteonorm: false,
  custom_soil: false,
  database_soil: false,
  custom_steering: false,
  irradiance_simulation: false,
  prod_simulation: false,
  water_simulation: false,
  thermal_simulation: false,
  yield_simulation: false,
  batchs: false,
};

const defaultValueCropPacks: ICropPacks = {
  prairie_pack: false,
  big_crop: false,
  viticole: false,
  arbo: false,
  maraichage: false,
};

const SettingSubscription = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { client_id } = useParams();
  const user = useSelector(getUserDataState);
  const subscription = useSelector(getSubscriptionDatas);
  const features = useSelector(getAllFeatures);
  const cropPacks = useSelector(getAllCropPack);
  const subscriptionLoader = useSelector(getSubscriptionLoader);
  const projectList = useSelector(getProjectsListState);

  const [featuresForm, setFeaturesForm] =
    useState<IFeatures>(defaultValueFeatures);
  const [cropPacksFrom, setCropPacksForm] = useState<ICropPacks>(
    defaultValueCropPacks
  );
  const [numberProject, setNumberProject] = useState(0);

  const navigate = useNavigate();

  const isSameFeaturesData = () => {
    return Object.entries(featuresForm).every(
      ([key, value]) => value === features[key as keyof IFeatures]
    );
  };

  const isSameCropPacksData = () => {
    return Object.entries(cropPacksFrom).every(
      ([key, value]) => value === cropPacks[key as keyof ICropPacks]
    );
  };

  const onSubscribe = (planIdSelected: number) => {
    const data = { client_id: client_id, plan: planIdSelected };
    dispatch(
      createSubscription({
        ...data,
        clientId: client_id,
        callback: () => {
          successNotifications({
            title: intl.formatMessage(CommonMessages.create),
            message: intl.formatMessage(CommonMessages.successCreate, {
              objet_type: intl.formatMessage(CommonMessages.simulation),
            }),
          });
        },
      })
    );
  };

  const SubscriptionPlans = [
    {
      name: "Abonnement 1",
      time: "12 months",
      projects: 5,
      "Creer une culture": false,
      "Créer une structure": true,
      "Structure template": true,
      "Importer son dataset": false,
      "Download TMY dataset": true,
      "Download Open-méteo datasets": false,
      "Créer un soil": false,
      "Accès database soil": false,
      "Créer son steering": false,
      "Simulations de partage lumineux": true,
      "Simulations phénoclimatiques": false,
      "Simulations de productible elec": false,
      "Faire des batchs": false,
    },
    {
      name: "Abonnement 2",
      time: "18 months",
      projects: 10,
      "Creer une culture": false,
      "Créer une structure": true,
      "Structure template": true,
      "Importer son dataset": false,
      "Download TMY dataset": true,
      "Download Open-méteo datasets": true,
      "Créer un soil": true,
      "Accès database soil": true,
      "Créer son steering": false,
      "Simulations de partage lumineux": true,
      "Simulations phénoclimatiques": true,
      "Simulations de productible elec": true,
      "Faire des batchs": false,
    },
    {
      name: "Abonnement 3",
      time: "24 months",
      projects: 20,
      "Creer une culture": true,
      "Créer une structure": true,
      "Structure template": true,
      "Importer son dataset": true,
      "Download TMY dataset": true,
      "Download Open-méteo datasets": true,
      "Créer un soil": true,
      "Accès database soil": true,
      "Créer son steering": true,
      "Simulations de partage lumineux": true,
      "Simulations phénoclimatiques": true,
      "Simulations de productible elec": true,
      "Faire des batchs": true,
    },
  ];

  useEffect(() => {
    if (
      user &&
      user.role &&
      user.role.name !== "manager" &&
      user.role.name !== "admin"
    ) {
      navigate(ROUTES.PROJECTS_LIST.replace(":client_id", client_id as string));
    }
  }, [user]);

  useEffect(() => {
    dispatch(
      getSubscription({
        clientId: client_id,
      })
    );
  }, [client_id]);

  useEffect(() => {
    if (features) setFeaturesForm(features);
    if (cropPacks) setCropPacksForm(cropPacks);
    if (subscription) setNumberProject(subscription.project_limit);
  }, [features, cropPacks]);

  const onEditFeatures = () => {
    dispatch(
      editFeatures({
        ...featuresForm,
        clientId: client_id,
        featureId: features.id,
        callback: () => {
          successNotifications({
            title: "Features Edited",
            message: `Features of client ${client_id} edited successfully`,
          });
        },
      })
    );
  };

  const onEditCropPacks = () => {
    dispatch(
      editCropPack({
        ...cropPacksFrom,
        clientId: client_id,
        cropPackId: cropPacks.id,
        callback: () => {
          successNotifications({
            title: "Crop Packs Edited",
            message: `Crop Packs of client ${client_id} edited successfully`,
          });
        },
      })
    );
  };

  const onEditSubscription = () => {
    dispatch(
      updateSubscription({
        ...{ project_limit: numberProject },
        clientId: client_id,
        subscriptionId: subscription.id,
        callback: () => {
          successNotifications({
            title: "Subscription Edited",
            message: `Subscription of client ${client_id} edited successfully`,
          });
        },
      })
    );
  };

  const isSameSubscriptionData = () => {
    return subscription.project_limit === numberProject;
  };

  return (
    <MainContent
      header={
        <>
          <h2 className={styled.headerTitle}>
            <FormattedMessage
              {...SettingSubscriptionMessages.subscriptionManagement}
            />
          </h2>
        </>
      }
    >
      {!subscription && !subscriptionLoader ? (
        <SubscribeContent onSubscribe={onSubscribe} />
      ) : subscription && features && cropPacks ? (
        <div className={styled.containerSub}>
          <div className={styled.projectsInfosContainer}>
            <div className={styled.titleSub}>
              <h5>Subscription limit </h5>
              <Button
                iconBefore={<Edit />}
                onClick={onEditSubscription}
                disabled={isSameSubscriptionData()}
              >
                <FormattedMessage
                  {...SettingSubscriptionMessages.editSubscription}
                />
              </Button>
            </div>
            <div className={styled.projectsInfos}>
              <p> Projects Numbers </p>
              <div className={styled.progressProject}>
                <button
                  onClick={() => {
                    if (numberProject > 1)
                      setNumberProject((prevState) => (prevState -= 1));
                  }}
                >
                  {" "}
                  -{" "}
                </button>{" "}
                {projectList.length > 0 ? (
                  <ProgressBar
                    now={(100 * projectList.length) / numberProject}
                    label={`${projectList.length}/${numberProject}`}
                    style={{ width: "30vw" }}
                    className="position-relative"
                  />
                ) : (
                  <ProgressBar
                    style={{ width: "30vw" }}
                    className="position-relative"
                  >
                    <div className="position-absolute d-flex justify-content-center w-100">
                      {projectList.length}/{numberProject}
                    </div>
                    <ProgressBar now={0} />
                  </ProgressBar>
                )}
                <button
                  onClick={() => {
                    setNumberProject((prevState) => (prevState += 1));
                  }}
                >
                  {" "}
                  +{" "}
                </button>
              </div>
              <p className={styled.expiration}>
                Expiration projects : {subscription.expiration_plan} months
              </p>
            </div>
          </div>
          <div className={styled.TogglesContainer}>
            <div className={styled.headerToggle}>
              <h5>Features</h5>
              <Button
                iconBefore={<Edit />}
                onClick={onEditFeatures}
                disabled={isSameFeaturesData()}
              >
                <FormattedMessage
                  {...SettingSubscriptionMessages.editFeatures}
                />
              </Button>
            </div>
            <div className={styled.allToggles}>
              {Object.entries(featuresForm).map(([key, value]) => {
                if (typeof value === "boolean")
                  return (
                    <div key={key} className={styled.featureToggles}>
                      <Toggle
                        label={key.replace(/_/g, " ")}
                        checked={value}
                        disabled={false}
                        onClick={() =>
                          setFeaturesForm((prevState) => ({
                            ...prevState,
                            [key]: !prevState[key as keyof IFeatures],
                          }))
                        }
                      />
                    </div>
                  );
              })}
            </div>
            <div className={styled.TogglesContainer}>
              <div className={styled.headerToggle}>
                <h5>Crops Packs</h5>

                <Button
                  iconBefore={<Edit />}
                  onClick={onEditCropPacks}
                  disabled={isSameCropPacksData()}
                >
                  <FormattedMessage
                    {...SettingSubscriptionMessages.editCropPacks}
                  />
                </Button>
              </div>
              <div className={styled.allToggles}>
                {Object.entries(cropPacksFrom).map(([key, value]) => {
                  if (typeof value === "boolean")
                    return (
                      <div key={key} className={styled.cropPackToggles}>
                        <Toggle
                          label={key.replace(/_/g, " ")}
                          checked={value}
                          disabled={false}
                          onClick={() => {
                            setCropPacksForm((prevState) => ({
                              ...prevState,
                              [key]: !prevState[key as keyof ICropPacks],
                            }));
                          }}
                        />
                      </div>
                    );
                })}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </MainContent>
  );
};

export default SettingSubscription;
