import routine from 'store/services/routine';

export const getStructures = routine('GET_STRUCTURES');
export const createStructure = routine('CREATE_STRUCTURE');
export const copyStructure = routine('COPY_STRUCTURE');
export const deleteStructure = routine('DELETE_STRUCTURE');
export const updateStructure = routine('UPDATE_STRUCTURE');
export const getStructureImage = routine('GET_STRUCTURE_IMAGE');
export const drawStructureImage = routine('DRAW_STRUCTURE_IMAGE');
export const copyStructureToOtherProject = routine('COPY_STRUCTURE_TO_OTHER_PROJECT');
