import { useEffect, useState } from "react";
import styled from "./styled.module.scss";
import { FormattedMessage, useIntl } from "react-intl";
import { default as TooltipMUI } from "@mui/material/Tooltip";
import { ICON_NAMES_ENUM, Icon } from "components/common/icon/Icon";
import CropCreateMessages from "pages/CropsCreate/CropCreateMessages";
import WaterBalanceResultBodyMessages from "components/WaterBalanceResultBody/WaterBalanceResultBodyMessages";
import WaterBalanceResultMenuMessages from "components/WaterBalanceResultMenu/WaterBalanceResultMenuMessages";
import chartToCsv from "utils/chartToCsv";
import BatchesResultMessages from "pages/BatchesResult/BatchesResultMessages";
import chartToPng from "utils/chartToPng";
import { useNavigate, useParams } from "react-router-dom";
import { getAnalisysTable } from "store/analisys/api";
import { ROUTES } from "constants/routes";
import { useSelector } from "react-redux";
import { getAllFeatures } from "store/features/selector";
import clsx from "clsx";
import UnlockBtn from "components/common/unlockBtn/UnlockBtn";
import ModalSubscribe from "components/ModalSubscribe/ModalSubsribe";

class AnalysisContentProps {
  result: any;
}

const AnalysisContent = ({ result }: AnalysisContentProps) => {
  const intl = useIntl();

  const [tableValues, setTableValues] = useState<any[]>([]);
  const [getError, setGetError] = useState(null);
  const [displayLockBtn, setDisplayLockBtn] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const features = useSelector(getAllFeatures);
  const {
    client_id,
    project_id,
    water_balance_id,
    id,
    simulation_water_balance_id,
  } = useParams();

  useEffect(() => {
    getAnalisysTable({
      clientId: client_id,
      projectId: project_id,
      id: id || simulation_water_balance_id,
    })
      .then((res) => setTableValues(res.data))
      .catch((err) => setGetError(err.response.data.data));
  }, [client_id, project_id, water_balance_id, id]);

  useEffect(() => {
    if (!features) return;
    if (
      !features.irradiance_simulation &&
      !features.water_simulation &&
      !features.thermal_simulation
    )
      setDisplayLockBtn(1);
    else setDisplayLockBtn(0);
  }, [features]);

  const getRowBackgroundColor = (value: number, type: string, period?: any) => {
    const sortedData = [
      ...tableValues
        .filter((val: any) => val.period.name != "Mean/Sum")
        .map((val: any) => val[type]),
    ].sort((a, b) => a - b);
    var median,
      min,
      max = 0;
    if (
      type == "days_water_stress_zt_irrigation_zt" ||
      type == "days_water_stress_agri_pv_irrigation_agri_pv"
    ) {
      const number_of_days =
        (new Date(period.end_date).getTime() -
          new Date(period.start_date).getTime()) /
        (1000 * 3600 * 24);

      median = Math.floor(number_of_days / 2);
      min = 0;
      max = number_of_days;
    } else {
      const middle = Math.floor(sortedData.length / 2);
      median =
        sortedData.length % 2 === 0
          ? (sortedData[middle - 1] + sortedData[middle]) / 2
          : sortedData[middle];

      min = sortedData[0];
      max = sortedData[sortedData.length - 1];
    }

    var green, red;

    if (value === median) {
      red = green = 255;
    } else if (value <= min) {
      green = 0;
      red = 255;
    } else if (value >= max) {
      green = 255;
      red = 0;
    } else if (value <= median) {
      const intensity = (value - min) / (median - min);
      red = 255;
      green = Math.round(255 * intensity);
    } else {
      const intensity = (value - median) / (max - median);
      red = Math.round(255 * (1 - intensity));
      green = 255;
    }

    if (type == "WATER_CONSUMPTION" || type == "RAIN") {
      return "white";
    }

    if (
      type == "days_water_stress_zt_irrigation_zt" ||
      type == "days_water_stress_agri_pv_irrigation_agri_pv"
    )
      return `rgb(${green}, ${red}, 0)`;
    else return `rgb(${red}, ${green}, 0)`;
  };

  const relaunchSimulation = () => {
    navigate(
      ROUTES.WATER_BALANCES_EDIT.replace(":client_id", client_id as string)
        .replace(":project_id", project_id as string)
        .replace(":id", water_balance_id as string)
    );
  };

  if (!tableValues || !tableValues.length)
    return getError === null ? (
      <div style={{ height: "100vh" }}></div>
    ) : (
      <div className={styled.errorToGet}>
        <p onClick={relaunchSimulation}>{getError}</p>
      </div>
    );

  return (
    <div className="list">
      <div className={styled.downloadContainer}>
        <TooltipMUI
          title={intl.formatMessage(
            WaterBalanceResultBodyMessages.hintTableToPng
          )}
        >
          <div
            className={styled.chartToPngButton}
            onClick={chartToPng("Table")}
          >
            <Icon
              name={ICON_NAMES_ENUM.download_image}
              className={styled.file__icon}
            />
          </div>
        </TooltipMUI>
        <TooltipMUI
          title={intl.formatMessage(
            WaterBalanceResultBodyMessages.hintTableToCsv
          )}
        >
          <div
            className={styled.chartToPngButton}
            onClick={() => {
              const values = tableValues.map((data: any) => ({
                ...data,
                period: data.period.name,
              }));
              chartToCsv([...values], `${result.simulation.name} table data`)();
            }}
          >
            <Icon
              name={ICON_NAMES_ENUM.download_file}
              className={styled.file__icon}
            />
          </div>
        </TooltipMUI>
      </div>
      <table
        className={clsx(styled.dataTable, {
          [styled.notAccesIrr]: !features.irradiance_simulation,
          [styled.notAccesWater]: !features.water_simulation,
          [styled.notAccesThermal]: !features.thermal_simulation,
        })}
        id="Table"
      >
        <thead>
          <tr>
            <th rowSpan={2}>
              <FormattedMessage {...CropCreateMessages.stageName} />
            </th>
            <th rowSpan={2}>
              <TooltipMUI
                title={intl.formatMessage(
                  BatchesResultMessages.irradianceFractionHelp
                )}
              >
                <div>
                  <div className={`${styled.flex} ${styled.justifyCenter}`}>
                    <FormattedMessage
                      {...BatchesResultMessages.irradianceFraction}
                    />
                    <div className={styled["help-icon"]}>
                      <Icon
                        name={ICON_NAMES_ENUM.help_circle}
                        className={styled.file__icon}
                      />
                    </div>
                  </div>
                  <div className={styled.subTitle}>
                    <FormattedMessage
                      {...BatchesResultMessages.ETPAgriPVZoneSubTitle}
                    />
                  </div>
                </div>
              </TooltipMUI>
            </th>
            <th rowSpan={2}>
              <TooltipMUI
                title={intl.formatMessage(
                  BatchesResultMessages.meanDailyPARHelp
                )}
              >
                <div>
                  <div className={`${styled.flex} ${styled.justifyCenter}`}>
                    <FormattedMessage {...BatchesResultMessages.meanDailyPAR} />

                    <div className={styled["help-icon"]}>
                      <Icon
                        name={ICON_NAMES_ENUM.help_circle}
                        className={styled.file__icon}
                      />
                    </div>
                  </div>
                  <div className={styled.subTitle}>
                    <FormattedMessage
                      {...BatchesResultMessages.ETPAgriPVZoneSubTitle}
                    />
                  </div>
                </div>
              </TooltipMUI>
            </th>
            <th colSpan={2}>
              <div>
                <FormattedMessage {...BatchesResultMessages.waterStress} />
              </div>
            </th>
            <th colSpan={2}>
              <TooltipMUI
                title={intl.formatMessage(
                  WaterBalanceResultBodyMessages.frostStressHelp
                )}
              >
                <div>
                  <div className={`${styled.flex} ${styled.justifyCenter}`}>
                    <FormattedMessage
                      {...WaterBalanceResultBodyMessages.frostStress}
                    />
                    <div className={styled["help-icon"]}>
                      <Icon
                        name={ICON_NAMES_ENUM.help_circle}
                        className={styled.file__icon}
                      />
                    </div>
                  </div>
                </div>
              </TooltipMUI>
            </th>
            <th colSpan={2}>
              <TooltipMUI
                title={intl.formatMessage(
                  WaterBalanceResultBodyMessages.heatStressHelp
                )}
              >
                <div>
                  <div className={`${styled.flex} ${styled.justifyCenter}`}>
                    <FormattedMessage
                      {...WaterBalanceResultBodyMessages.heatStress}
                    />
                    <div className={styled["help-icon"]}>
                      <Icon
                        name={ICON_NAMES_ENUM.help_circle}
                        className={styled.file__icon}
                      />
                    </div>
                  </div>
                </div>
              </TooltipMUI>
            </th>
          </tr>
          <tr className={styled.specialCaseTable}>
            <th>
              <FormattedMessage
                {...WaterBalanceResultMenuMessages.controlZoneSubTitle}
              />
            </th>
            <th>
              <FormattedMessage
                {...WaterBalanceResultMenuMessages.agriPVZoneSubTitle}
              />
            </th>
            <th>
              <FormattedMessage
                {...WaterBalanceResultMenuMessages.controlZoneSubTitle}
              />
            </th>
            <th>
              <FormattedMessage
                {...WaterBalanceResultMenuMessages.agriPVZoneSubTitle}
              />
            </th>
            <th>
              <FormattedMessage
                {...WaterBalanceResultMenuMessages.controlZoneSubTitle}
              />
            </th>
            <th>
              <FormattedMessage
                {...WaterBalanceResultMenuMessages.agriPVZoneSubTitle}
              />
            </th>
          </tr>
        </thead>

        <tbody>
          {tableValues
            .filter((val: any) => val.period.name != "Mean/Sum")
            .map((data: any, index: number, array: any) => (
              <tr
                key={data.period.id}
                className={
                  array.length === index + 1 ? styled.specialLastTr : ""
                }
              >
                <td>
                  <span>{data.period.name}</span>
                </td>
                <td
                  className={clsx({
                    [styled.blur]: !features.irradiance_simulation,
                  })}
                  style={{
                    backgroundColor: getRowBackgroundColor(
                      data.irradiance_fraction_agri_pv,
                      "irradiance_fraction_agri_pv"
                    ),
                  }}
                >
                  {
                    // IRR FRACTION
                    data.irradiance_fraction_agri_pv
                  }
                </td>
                <td
                  className={clsx({
                    [styled.blur]: !features.irradiance_simulation,
                  })}
                  style={{
                    backgroundColor: getRowBackgroundColor(
                      data.irradiance_daily_par_agri_pv,
                      "irradiance_daily_par_agri_pv"
                    ),
                  }}
                >
                  {
                    // DAILY PAR
                    data.irradiance_daily_par_agri_pv
                  }
                </td>
                <td
                  className={clsx({
                    [styled.blur]: !features.water_simulation,
                  })}
                  style={{
                    backgroundColor: getRowBackgroundColor(
                      data.days_water_stress_zt_irrigation_zt,
                      "days_water_stress_zt_irrigation_zt",
                      data.period
                    ),
                  }}
                >
                  {data.days_water_stress_zt_irrigation_zt}
                </td>
                <td
                  className={clsx({
                    [styled.blur]: !features.water_simulation,
                  })}
                  style={{
                    backgroundColor: getRowBackgroundColor(
                      data.days_water_stress_agri_pv_irrigation_agri_pv,
                      "days_water_stress_agri_pv_irrigation_agri_pv",
                      data.period
                    ),
                  }}
                >
                  {data.days_water_stress_agri_pv_irrigation_agri_pv}
                </td>
                <td
                  className={clsx({
                    [styled.blur]: !features.thermal_simulation,
                  })}
                >
                  {data.control_zone_zero_degres}
                </td>
                <td
                  className={clsx({
                    [styled.blur]: !features.thermal_simulation,
                  })}
                >
                  {data.agripv_zero_degres}
                </td>
                <td
                  className={clsx({
                    [styled.blur]: !features.thermal_simulation,
                  })}
                >
                  {data.control_zone_twenty_five_degres}
                </td>
                <td
                  className={clsx({
                    [styled.blur]: !features.thermal_simulation,
                  })}
                >
                  {data.agripv_twenty_five_degres}
                </td>
              </tr>
            ))}
          <br />
          <tr>
            <th rowSpan={2} className={styled.specialCaseTh}>
              Mean/Sum
            </th>
            <td>Mean</td>
            <td>Mean</td>
            <td>Sum</td>
            <td>Sum</td>
            <td>Sum</td>
            <td>Sum</td>
            <td>Sum</td>
            <td>Sum</td>
          </tr>
          <tr className={styled.specialCaseTable}>
            <td
              className={clsx(styled.specialCaseTd, {
                [styled.blur]: !features.irradiance_simulation,
              })}
            >
              {
                tableValues.find((item: any) => item.period.name == "Mean/Sum")
                  .irradiance_fraction_agri_pv
              }
            </td>
            <td
              className={clsx({
                [styled.blur]: !features.irradiance_simulation,
              })}
            >
              {
                tableValues.find((item: any) => item.period.name == "Mean/Sum")
                  .irradiance_daily_par_agri_pv
              }
            </td>
            <td
              className={clsx({
                [styled.blur]: !features.phenoclimatic_simulation,
              })}
            >
              {
                tableValues.find((item: any) => item.period.name == "Mean/Sum")
                  .days_water_stress_zt_irrigation_zt
              }
            </td>
            <td
              className={clsx({
                [styled.blur]: !features.phenoclimatic_simulation,
              })}
            >
              {
                tableValues.find((item: any) => item.period.name == "Mean/Sum")
                  .days_water_stress_agri_pv_irrigation_agri_pv
              }
            </td>
            <td
              className={clsx({
                [styled.blur]: !features.phenoclimatic_simulation,
              })}
            >
              {
                tableValues.find((item: any) => item.period.name == "Mean/Sum")
                  .control_zone_zero_degres
              }
            </td>
            <td
              className={clsx({
                [styled.blur]: !features.phenoclimatic_simulation,
              })}
            >
              {
                tableValues.find((item: any) => item.period.name == "Mean/Sum")
                  .agripv_zero_degres
              }
            </td>
            <td
              className={clsx({
                [styled.blur]: !features.phenoclimatic_simulation,
              })}
            >
              {
                tableValues.find((item: any) => item.period.name == "Mean/Sum")
                  .control_zone_twenty_five_degres
              }
            </td>
            <td
              className={clsx({
                [styled.blur]: !features.phenoclimatic_simulation,
              })}
            >
              {
                tableValues.find((item: any) => item.period.name == "Mean/Sum")
                  .agripv_twenty_five_degres
              }
            </td>
          </tr>
        </tbody>
      </table>
      {displayLockBtn === 1 && (
        <UnlockBtn onClick={() => setIsModalOpen(!isModalOpen)} />
      )}
      <ModalSubscribe isOpen={isModalOpen} setOpen={setIsModalOpen} />
    </div>
  );
};

export default AnalysisContent;
