import React, { forwardRef } from "react";
import { ReactComponent as Batch } from "assets/icons/batch.svg";
import { ReactComponent as BatchResult } from "assets/icons/batch-result.svg";
import { ReactComponent as Crops } from "assets/icons/crops.svg";
import { ReactComponent as DataElec } from "assets/icons/data-elec.svg";
import { ReactComponent as DataTemp } from "assets/icons/data-temp.svg";
import { ReactComponent as Simulation } from "assets/icons/simulation.svg";
import { ReactComponent as SimulationResult } from "assets/icons/simulation-result.svg";
import { ReactComponent as Structures } from "assets/icons/structures.svg";
import { ReactComponent as TerrainIcon } from "assets/icons/terrain-icon.svg";
import styled from "./styled.module.scss";
import { Link, useParams } from "react-router-dom";
import { ICON_NAMES_ENUM, Icon } from "components/common/icon/Icon";
import clsx from "clsx";

export interface IAccordionItem {
  icon: "b" | "br" | "c" | "de" | "dt" | "si" | "sr" | "s" | "so";
  name: string;
  to?: string;
  resultId?: number;
  itemId?: number;
  wbResultId?: number;
  type?: string;
}

const AccordionItem = forwardRef<HTMLDivElement, IAccordionItem>(
  ({ icon, name, to, resultId, itemId, wbResultId, type }, ref) => {
    const getIcon = (key: IAccordionItem["icon"]) => {
      switch (key) {
        case "b":
          return <Batch />;
        case "br":
          return <BatchResult />;
        case "c":
          return <Crops />;
        case "de":
          return <DataElec />;
        case "dt":
          return <DataTemp />;
        case "si":
          return <Simulation />;
        case "sr":
          return <SimulationResult />;
        case "s":
          return <Structures />;
        case "so":
          return (
            <Icon
              name={ICON_NAMES_ENUM.terrain_icon}
              className={styled.file__icon}
            />
          );
      }
    };

    const Component = !!to ? Link : "span";

    const isCurrentItem = () => {
      if (!itemId) return false;
      if (type === "structure") {
        const isStructure = window.location.pathname
          .split("/")
          .includes("structures");
        const { id } = useParams();

        return isStructure ? itemId?.toString() === id : false;
      } else if (type === "crop") {
        const isCrop = window.location.pathname.split("/").includes("crops");
        const { id } = useParams();

        return isCrop ? itemId?.toString() === id : false;
      } else if (type === "steering_algorithm") {
        const { id } = useParams();
        const pathname = window.location.pathname.split("/");

        const isSteering =
          pathname.includes("steering_algorithms") && pathname.includes("edit");

        return isSteering ? itemId?.toString() === id : false;
      } else if (type === "soil_information") {
        const { id } = useParams();
        const isSoil = window.location.pathname
          .split("/")
          .includes("soil_informations");

        return isSoil ? itemId?.toString() === id : false;
      } else if (type === "default_soil_information") {
        const { id } = useParams();
        const isSoil = window.location.pathname
          .split("/")
          .includes("default_soil_informations");

        return isSoil ? itemId?.toString() === id : false;
      } else if (
        type &&
        [
          "universal-dataset",
          "etp-dataset",
          "production-dataset",
          "weather-dataset",
        ].includes(type)
      ) {
        const { id } = useParams();
        const isDataset = window.location.pathname
          .split("/")
          .includes("datasets");

        return isDataset ? itemId?.toString() === id : false;
      } else if (type === "simulation") {
        const { id, water_balance_id } = useParams();
        const isSimulationResult = window.location.pathname
          .split("/")
          .includes("water_balances");

        return isSimulationResult && water_balance_id
          ? itemId?.toString() === water_balance_id &&
              resultId?.toString() === id
          : false;
      } else if (type === "water_balance_batch_result") {
        const {
          result_id,
          batch_water_balance_id,
          simulation_water_balance_id,
        } = useParams();
        const pathname = window.location.pathname.split("/");

        const isWBResult =
          pathname.includes("batches_water_balance") &&
          pathname.includes("results") &&
          pathname.includes("simulations");

        return isWBResult &&
          result_id &&
          batch_water_balance_id &&
          simulation_water_balance_id
          ? itemId?.toString() === batch_water_balance_id &&
              resultId?.toString() === result_id &&
              wbResultId?.toString() === simulation_water_balance_id
          : false;
      } else return false;
    };

    return (
      <div ref={ref} className={styled.container}>
        {getIcon(icon)}
        <Component
          to={to as string}
          className={clsx(styled.label, {
            [styled.currentItem]: isCurrentItem(),
          })}
        >
          {name}
        </Component>
      </div>
    );
  }
);

export default AccordionItem;
