import { IFormik } from "components/common/FormikType";
import { selectOptionsText } from "components/common/select/selectOptionsText";
import { useOptionData } from "hooks/useOptionData";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getNavigationState } from "store/navigation/selectors";
import { getWaterBalanceItemState } from "store/water_balances/selectors";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { getWaterBalance } from "store/water_balances/actions";
import styled from "./styled.module.scss";
import TextSelect from "components/common/textSelect/TextSelect";
import { isEqual } from "lodash";
import commonMessages from "components/common/CommonMessages";
import Select from "components/common/select/Select";
import { FieldArray, FormikProvider } from "formik";
import parametersInterestMessages from "components/ParametersInterest/ParametersInterestMessages";
import { ReactComponent as Copy } from "assets/icons/copy.svg";
import { ReactComponent as Delete } from "assets/icons/delete.svg";
import { ReactComponent as Plus } from "assets/icons/plus-circle.svg";
import { ReactComponent as Close } from "assets/icons/close.svg";
import { ReactComponent as Play } from "assets/icons/play.svg";
import clsx from "clsx";
import Button from "components/common/button/Button";
import { ICON_NAMES_ENUM, Icon } from "components/common/icon/Icon";
import { SpanError } from "components/common/span-error/SpanError";
import BatchesWaterBalanceBodyMessages from "./BatchesWaterBalanceBodyMessages";
import {
  getBatchesWaterBalanceLoadingState,
  getBatchesWaterBalanceParameters,
} from "store/batches_water_balance/selectors";
import {
  IInterestNameVariantsWaterBalance,
  IInterestParamsWaterBalanceItem,
} from "components/WaterBalanceParametersInterest/useParamsList";
import WaterBalanceParametersInterest from "components/WaterBalanceParametersInterest/WaterBalanceParametersInterest";
import CommonMessages from "components/common/CommonMessages";
import Input from "components/common/input/Input";
import { IDbSteeringAlgorithm } from "pages/SteeringAlgorithmsCreate/SteeringAlgorithmsCreateTypes";
import { getAllFeatures } from "store/features/selector";
import UnSubscribeButton from "components/common/unSubscribe/UnSubscribeButton";
import ModalSubscribe from "components/ModalSubscribe/ModalSubsribe";
import { getSubscription } from "store/subscription/actions";

type PartialRecord<K extends keyof any, T> = {
  [P in K]?: T;
};

export type ParamsInterests = PartialRecord<
  IInterestNameVariantsWaterBalance,
  any
>;

export interface IBatchesWaterBalanceState {
  name: string;
  base_simulation_water_balance_id: number | null;
  frequency: string;
  interests: IInterestNameVariantsWaterBalance[];
  rows: ParamsInterests[];
}

interface IBatchesWaterBalanceBody {
  formik: IFormik<IBatchesWaterBalanceState>;
  onRunSimulation?: () => void;
  edit_page?: boolean;
}

const BatchesWaterBalanceBody = ({
  formik,
  onRunSimulation,
  edit_page = false,
}: IBatchesWaterBalanceBody) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { client_id, project_id } = useParams();
  const { frequencyOptions } = selectOptionsText();
  const simulation = useSelector(getWaterBalanceItemState);
  const interestParams = useSelector(getBatchesWaterBalanceParameters);
  const isLoading = useSelector(getBatchesWaterBalanceLoadingState);
  const features = useSelector(getAllFeatures);
  const {
    water_balances,
    crops,
    weather_datasets,
    production_datasets,
    etp_datasets,
    steering_algorithms,
    universal_datasets,
    soil_informations,
  } = useSelector(getNavigationState);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const simulationOptions = useOptionData(water_balances);
  const weatherDatasetOptions = useOptionData(weather_datasets);
  const productionDatasetOptions = useOptionData(production_datasets);
  const ETPDatasetOptions = useOptionData(etp_datasets);
  const cropOptions = useOptionData(crops);
  const soilInformationOptions = useOptionData(soil_informations);
  const universalDatasetOptions = useOptionData(universal_datasets);

  const simulationValues: ParamsInterests = useMemo(() => {
    if (simulation) {
      let lower = undefined;

      if (simulation.structure.structure_type == "fixed") {
        const angle = simulation.structure.static_angle;
        const panel_size = simulation.structure.panel_y / 2;
        const val1 =
          Math.round(
            (simulation.structure.panel_height +
              panel_size * Math.sin((angle * Math.PI) / 180)) *
              100
          ) / 100;
        const val2 =
          Math.round(
            (simulation.structure.panel_height -
              panel_size * Math.sin((angle * Math.PI) / 180)) *
              100
          ) / 100;
        lower = val1 < val2 ? val1 : val2;
        // const upper = val1 > val2 ? val1 : val2;
      }

      return {
        panel_height: simulation.structure.panel_height,
        panel_opacity: simulation.structure.panel_opacity,
        azimuth: simulation.structure.azimuth,
        panel_size: {
          x: simulation.structure.panel_x,
          y: simulation.structure.panel_y,
        },
        panel_width:
          simulation.structure.structure_type == "fixed"
            ? simulation.structure.panel_y
            : simulation.structure.panel_x,
        panel_tilt: simulation.structure.static_angle,
        panel_max_tilt: simulation.structure.tracking_max_angle,
        panel_length:
          simulation.structure.structure_type == "fixed"
            ? simulation.structure.panel_x
            : simulation.structure.panel_y,
        initial_offset: {
          x: simulation.structure.initial_offset_x,
          y: simulation.structure.initial_offset_y,
        },
        panels_number: {
          x: simulation.structure.panels_number_x,
          y: simulation.structure.panels_number_y,
        },
        panels_gap: {
          x: simulation.structure.panels_gap_x,
          y: simulation.structure.panels_gap_y,
        },
        lower_table_tip_height: lower,
        pitch_between_tables:
          simulation.structure.structure_type == "fixed"
            ? simulation.structure.panels_gap_y
            : simulation.structure.panels_gap_x,
        gap_between_adjacent_tables:
          simulation.structure.structure_type == "fixed"
            ? simulation.structure.panels_gap_x - simulation.structure.panel_x
            : simulation.structure.panels_gap_y - simulation.structure.panel_y,
        field_size: {
          x: simulation.structure.field_size_x,
          y: simulation.structure.field_size_y,
        },
        soil_information_id: simulation.water_balance.id,
        // weather_dataset_id: simulation.weather_dataset.id,
        // production_dataset_id: simulation.production_dataset.id,
        // ETP_dataset_id: simulation.ETP_dataset
        //   ? simulation.ETP_dataset.id
        //   : null,
        universal_dataset_id: simulation.universal_dataset
          ? simulation.universal_dataset.id
          : null,
        crop_id: simulation.crop_for_water_balance.id,
        steering_algorithm_id: simulation.steering_algorithm_for_water_balance
          ? simulation.steering_algorithm_for_water_balance.id
          : null,
      };
    }
    return {};
  }, [simulation]);

  useEffect(() => {
    if (formik.values.base_simulation_water_balance_id) {
      dispatch(
        getWaterBalance({
          clientId: client_id,
          projectId: project_id,
          waterBalanceId: formik.values.base_simulation_water_balance_id,
        })
      );
    }
  }, [client_id, project_id, formik.values.base_simulation_water_balance_id]);

  useEffect(() => {
    dispatch(getSubscription({ clientId: client_id }));
  }, [client_id, project_id]);

  const onSelectSimulation =
    (key: keyof IBatchesWaterBalanceState) => (option: any) => {
      formik.setFieldValue(key, option.value);
    };

  const onSelect = (key: keyof IBatchesWaterBalanceState) => (option: any) => {
    formik.setFieldValue(key, option.value);
  };

  const getCurrentField = useCallback(
    (value: IInterestNameVariantsWaterBalance, index: number) => {
      const interest = interestParams.find(
        ({ name }: IInterestParamsWaterBalanceItem) => name === value
      );

      if (!interest) return;

      const { type } = interest;

      if (type === "input") {
        const isError: boolean =
          typeof formik.errors.rows !== "string" &&
          !!formik.errors?.rows?.at(index) &&
          Object.hasOwn(formik.errors?.rows?.at(index) as object, value);

        return (
          <Input
            type="number"
            noBorder
            name={`rows.${index}.${value}`}
            value={formik.values.rows[index][value]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            isError={isError}
          />
        );
      }

      if (type === "point") {
        const isErrorX: boolean =
          typeof formik.errors.rows !== "string" &&
          !!formik.errors?.rows?.at(index) &&
          Object.hasOwn(formik.errors?.rows?.at(index) as object, value) &&
          // @ts-ignore
          Object.hasOwn(formik.errors?.rows?.at(index)[value] as object, "x");

        const isErrorY: boolean =
          typeof formik.errors.rows !== "string" &&
          !!formik.errors?.rows?.at(index) &&
          Object.hasOwn(formik.errors?.rows?.at(index) as object, value) &&
          // @ts-ignore
          Object.hasOwn(formik.errors?.rows?.at(index)[value] as object, "y");

        return (
          <div className={styled.row}>
            <Input
              className={styled.twoInput}
              type="number"
              noBorder
              beforeIcon="X"
              name={`rows.${index}.${value}.x`}
              value={formik.values.rows[index][value].x}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isError={isErrorX}
            />
            <Input
              className={styled.twoInput}
              type="number"
              noBorder
              beforeIcon="Y"
              name={`rows.${index}.${value}.y`}
              value={formik.values.rows[index][value].y}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isError={isErrorY}
            />
          </div>
        );
      }

      if (type === "crop") {
        const onChange = (e: any) => {
          const newValue = [...formik.values.rows];
          newValue[index][value] = e.value;
          if (formik.values.interests.includes("steering_algorithm_id"))
            newValue[index]["steering_algorithm_id"] = null;
          formik.setFieldValue("rows", newValue);
        };
        const selectedId = formik.values.rows[index][value];
        const selectedValue = cropOptions.find(
          (item: any) => item.value === selectedId
        );

        if (!selectedValue && formik.values.rows[index][value] !== null) {
          const newValue = [...formik.values.rows];
          newValue[index][value] = null;
          formik.setFieldValue("rows", newValue);
        }

        return (
          <TextSelect
            menuAnchor="left"
            options={cropOptions}
            value={selectedValue}
            onChange={onChange}
            menuPlacement="top"
            menuPortalTarget={document.querySelector("body")}
          />
        );
      }

      if (type === "soil_information") {
        const onChange = (e: any) => {
          const newValue = [...formik.values.rows];
          newValue[index][value] = e.value;
          formik.setFieldValue("rows", newValue);
        };
        const selectedId = formik.values.rows[index][value];
        const selectedValue = soilInformationOptions.find(
          (item: any) => item.value === selectedId
        );

        if (!selectedValue && formik.values.rows[index][value] !== null) {
          const newValue = [...formik.values.rows];
          newValue[index][value] = null;
          formik.setFieldValue("rows", newValue);
        }

        return (
          <TextSelect
            menuAnchor="left"
            options={soilInformationOptions}
            value={selectedValue}
            onChange={onChange}
            menuPlacement="top"
            menuPortalTarget={document.querySelector("body")}
          />
        );
      }

      if (type === "steering_algorithm") {
        const onChange = (e: any) => {
          const newValue = [...formik.values.rows];
          newValue[index][value] = e.value;
          formik.setFieldValue("rows", newValue);
        };
        const selectedId = formik.values.rows[index][value];

        const acctualCropId = formik.values.interests.find(
          (interest) => interest == "crop_id"
        )
          ? formik.values.rows[index]["crop_id"]
          : simulation
          ? simulation.crop_for_water_balance.id
          : null;

        var selectedValue = steering_algorithms
          .map((steering_algorithm: IDbSteeringAlgorithm) => ({
            value: steering_algorithm.id,
            label: steering_algorithm.name,
          }))
          .find((item: any) => item.value == selectedId);

        if (!selectedValue) selectedValue = { value: null, label: "None" };

        if (!selectedValue && formik.values.rows[index][value] !== null) {
          const newValue = [...formik.values.rows];
          newValue[index][value] = null;
          formik.setFieldValue("rows", newValue);
        }

        return (
          <TextSelect
            menuAnchor="left"
            options={[
              { value: null, label: "None" },
              ...steering_algorithms
                .filter(
                  (steering_algorithm: IDbSteeringAlgorithm) =>
                    steering_algorithm.crop_id == acctualCropId
                )
                .map((steering_algorithm: IDbSteeringAlgorithm) => ({
                  value: steering_algorithm.id,
                  label: steering_algorithm.name,
                })),
            ]}
            value={selectedValue}
            onChange={onChange}
            menuPlacement="top"
            menuPortalTarget={document.querySelector("body")}
          />
        );
      }

      if (type === "weather_dataset") {
        const onChange = (e: any) => {
          const newValue = [...formik.values.rows];
          newValue[index][value] = e.value;
          formik.setFieldValue("rows", newValue);
        };
        const selectedId = formik.values.rows[index][value];
        const selectedValue = weatherDatasetOptions.find(
          (item: any) => item.value === selectedId
        );

        if (!selectedValue && formik.values.rows[index][value] !== null) {
          const newValue = [...formik.values.rows];
          newValue[index][value] = null;
          formik.setFieldValue("rows", newValue);
        }

        return (
          <TextSelect
            menuAnchor="left"
            options={weatherDatasetOptions}
            value={selectedValue}
            onChange={onChange}
            menuPlacement="top"
            menuPortalTarget={document.querySelector("body")}
          />
        );
      }

      if (type === "production_dataset") {
        const onChange = (e: any) => {
          const newValue = [...formik.values.rows];
          newValue[index][value] = e.value;
          formik.setFieldValue("rows", newValue);
        };
        const selectedId = formik.values.rows[index][value];
        const selectedValue = productionDatasetOptions.find(
          (item: any) => item.value === selectedId
        );

        if (!selectedValue && formik.values.rows[index][value] !== null) {
          const newValue = [...formik.values.rows];
          newValue[index][value] = null;
          formik.setFieldValue("rows", newValue);
        }

        return (
          <TextSelect
            menuAnchor="left"
            options={productionDatasetOptions}
            value={selectedValue}
            onChange={onChange}
            menuPlacement="top"
            menuPortalTarget={document.querySelector("body")}
          />
        );
      }

      if (type === "ETP_dataset") {
        const onChange = (e: any) => {
          const newValue = [...formik.values.rows];
          newValue[index][value] = e.value;
          formik.setFieldValue("rows", newValue);
        };
        const selectedId = formik.values.rows[index][value];
        const selectedValue = ETPDatasetOptions.find(
          (item: any) => item.value === selectedId
        );

        if (!selectedValue && formik.values.rows[index][value] !== null) {
          const newValue = [...formik.values.rows];
          newValue[index][value] = null;
          formik.setFieldValue("rows", newValue);
        }

        return (
          <TextSelect
            menuAnchor="left"
            options={ETPDatasetOptions}
            value={selectedValue}
            onChange={onChange}
            menuPlacement="top"
            menuPortalTarget={document.querySelector("body")}
          />
        );
      }

      if (type === "universal_dataset") {
        const onChange = (e: any) => {
          const newValue = [...formik.values.rows];
          newValue[index][value] = e.value;
          formik.setFieldValue("rows", newValue);
        };
        const selectedId = formik.values.rows[index][value];
        const selectedValue = universalDatasetOptions.find(
          (item: any) => item.value === selectedId
        );

        if (!selectedValue && formik.values.rows[index][value] !== null) {
          const newValue = [...formik.values.rows];
          newValue[index][value] = null;
          formik.setFieldValue("rows", newValue);
        }

        return (
          <TextSelect
            menuAnchor="left"
            options={universalDatasetOptions}
            value={selectedValue}
            onChange={onChange}
            menuPlacement="top"
            menuPortalTarget={document.querySelector("body")}
          />
        );
      }
    },
    [formik, interestParams]
  );

  const checkValue = (
    index: number,
    item: IInterestNameVariantsWaterBalance
  ) => {
    return !isEqual(formik.values.rows[index][item], simulationValues[item]);
  };

  const onDeleteParam = (value: IInterestNameVariantsWaterBalance) => {
    formik.setFieldValue(
      "interests",
      formik.values.interests.filter((item) => item !== value)
    );

    const params_interests = formik.values.rows.map((item) => {
      delete item[value];
      return item;
    });

    if (
      params_interests.length &&
      Object.keys(params_interests[0]).length === 0
    ) {
      return formik.setFieldValue("rows", []);
    }

    formik.setFieldValue("rows", params_interests);
  };

  const onCheck = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = e.target;

    if (checked) {
      formik.setFieldValue("interests", [...formik.values.interests, value]);

      const params_interests = formik.values.rows.map((item) => ({
        ...item,
        [value]: simulationValues[value as IInterestNameVariantsWaterBalance],
      }));

      formik.setFieldValue("rows", params_interests);
    } else {
      onDeleteParam(value as IInterestNameVariantsWaterBalance);
    }
  };

  return (
    <div className={styled.grid}>
      <div className={styled.column}>
        <h2 className={styled.sectionTitle}>
          <FormattedMessage
            {...BatchesWaterBalanceBodyMessages.generalSetting}
          />
        </h2>

        <Select
          label={intl.formatMessage(
            BatchesWaterBalanceBodyMessages.baseWaterBalance
          )}
          options={simulationOptions}
          value={simulationOptions.find(
            (item: any) =>
              item.value === formik.values.base_simulation_water_balance_id
          )}
          onChange={onSelectSimulation("base_simulation_water_balance_id")}
          errorMessage={
            (formik.touched.base_simulation_water_balance_id &&
              formik.errors.base_simulation_water_balance_id) as string
          }
        />
        {/* <Select
          label={intl.formatMessage(BatchesWaterBalanceBodyMessages.frequency)}
          options={frequencyOptions}
          value={frequencyOptions.find(
            (item) => item.value === formik.values.frequency
          )}
          onChange={onSelect("frequency")}
        /> */}
        {formik.values.base_simulation_water_balance_id !== null &&
          simulation != null && (
            <WaterBalanceParametersInterest
              label={intl.formatMessage(
                BatchesWaterBalanceBodyMessages.interest
              )}
              values={formik.values.interests}
              onChange={onCheck}
              setTouched={() => formik.setFieldTouched("interests", true)}
              errorMessage={
                (formik.touched.interests && formik.errors.interests) as string
              }
              structure_type={simulation.structure.structure_type}
            />
          )}
      </div>

      {formik.values.interests.length ? (
        <>
          <h2 className={styled.sectionTitle}>
            <FormattedMessage {...commonMessages.simulations} />
          </h2>

          <div className={styled.tableContainer}>
            <FormikProvider value={formik}>
              <FieldArray
                name="rows"
                render={(arrayHelpers) => {
                  const { rows, interests } = formik.values;
                  return (
                    <div style={{ display: "flex" }}>
                      <table className={styled.dataTable}>
                        <thead>
                          <tr>
                            {interests.map((item, index) => (
                              <th key={index}>
                                <span className={styled.headerText}>
                                  <FormattedMessage
                                    {...parametersInterestMessages[item]}
                                  />
                                </span>

                                <button
                                  type="button"
                                  className={clsx(
                                    styled.button,
                                    styled.deleteColumn
                                  )}
                                  onClick={() => onDeleteParam(item)}
                                >
                                  <Close />
                                </button>
                              </th>
                            ))}
                            <th>
                              <Button
                                iconBefore={<Plus />}
                                onClick={() => {
                                  arrayHelpers.push(
                                    Object.fromEntries(
                                      interests.map((item) => [
                                        item,
                                        simulationValues[item],
                                      ])
                                    )
                                  );
                                }}
                              >
                                <FormattedMessage
                                  {...BatchesWaterBalanceBodyMessages.addRow}
                                />
                              </Button>
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {rows.map((row, index) => (
                            <tr key={index}>
                              {interests.map((item) => (
                                <td
                                  key={`${item}-${index}`}
                                  className={clsx({
                                    [styled.colorTd]: checkValue(index, item),
                                  })}
                                >
                                  {getCurrentField(item, index)}
                                </td>
                              ))}
                              <td>
                                <div className={styled.row}>
                                  <button
                                    type="button"
                                    className={styled.button}
                                    onClick={() => {
                                      arrayHelpers.insert(index, row);
                                    }}
                                  >
                                    <Copy />
                                  </button>
                                  <button
                                    type="button"
                                    onClick={() => arrayHelpers.remove(index)}
                                    className={clsx(
                                      styled.button,
                                      styled.deleteButton
                                    )}
                                  >
                                    <Delete />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <div className={styled["order-selector"]}>
                        {formik.values.rows.map((item, index) => (
                          <div
                            key={`${index}-selector`}
                            className={styled.item}
                          >
                            <button
                              className={clsx(styled.button)}
                              type="button"
                              style={{ transform: "rotateZ(180deg)" }}
                              onClick={() => {
                                const temp = formik.values.rows;

                                temp.splice(index, 1);
                                temp.splice(index - 1, 0, item);

                                arrayHelpers.form.setFieldValue("rows", temp);
                              }}
                              disabled={!index}
                            >
                              <Icon name={ICON_NAMES_ENUM.arrow_down} />
                            </button>
                            <button
                              className={clsx(styled.button)}
                              type="button"
                              onClick={() => {
                                const temp = formik.values.rows;

                                temp.splice(index + 2, 0, item);
                                temp.splice(index, 1);

                                arrayHelpers.form.setFieldValue("rows", temp);
                              }}
                              disabled={index == formik.values.rows.length - 1}
                            >
                              <Icon name={ICON_NAMES_ENUM.arrow_down} />
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                  );
                }}
              />
            </FormikProvider>

            <div className={styled.errorContainer}>
              {typeof formik.errors.rows === "string" && (
                <SpanError
                  errorMessage={
                    (formik.touched.rows && formik.errors.rows) as string
                  }
                />
              )}

              {typeof formik.errors.rows === "object" &&
                formik.errors.rows.map((row, index) => {
                  if (!row) return;

                  const errors = Object.entries(row);

                  return errors.map((error) => {
                    if (typeof error[1] === "string") {
                      return (
                        <SpanError
                          key={`${index}-${error[0]}`}
                          errorMessage={`Row ${index + 1} -> ${error[0]} -> ${
                            error[1]
                          }`}
                        />
                      );
                    } else {
                      const errors = Object.entries(error[1]);
                      return errors.map((subError) => (
                        <SpanError
                          key={`${index}-${error[0]}-${subError[0]}`}
                          errorMessage={`Row ${index + 1} -> ${error[0]} -> ${
                            subError[0]
                          } -> ${subError[1]}`}
                        />
                      ));
                    }
                  });
                })}
            </div>
          </div>

          {features && features.batchs ? (
            <Button
              disabled={isLoading || !onRunSimulation}
              isLoading={isLoading}
              onClick={onRunSimulation}
              iconBefore={<Play />}
              className={styled.simulationButton}
            >
              <FormattedMessage {...CommonMessages.run} />
            </Button>
          ) : (
            <UnSubscribeButton
              iconBefore={<Play />}
              onClick={() => setIsModalOpen(!isModalOpen)}
              className={styled.simulationButton}
            >
              <FormattedMessage {...commonMessages.run} />
            </UnSubscribeButton>
          )}
        </>
      ) : null}
      <ModalSubscribe isOpen={isModalOpen} setOpen={setIsModalOpen} />
    </div>
  );
};

export default BatchesWaterBalanceBody;
