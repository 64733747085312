import { useEffect, useState } from "react";
import styled from "./Crops.module.scss";
import { FormattedMessage, useIntl } from "react-intl";
import commonMessages from "components/common/CommonMessages";
import Button from "components/common/button/Button";
import UnSubscribeButton from "components/common/unSubscribe/UnSubscribeButton";
import { ReactComponent as Plus } from "assets/icons/plus.svg";
import { ROUTES } from "constants/routes";
import { useNavigate, useParams } from "react-router-dom";
import { getCropsListState } from "store/crops/selectors";
import { useDispatch, useSelector } from "react-redux";
import {
  getCrops,
  deleteCrop,
  copyCrop,
  copyCropToOtherProject,
} from "store/crops/actions";
import MainContent from "components/MainContent/MainContent";
import {
  LeaveModalEnum,
  LeaveModalWindow,
} from "components/LeaveModalWindow/LeaveModalWindow";
import CropsCard from "components/CropsCard/CropsCard";
import { ICON_NAMES_ENUM, Icon } from "components/common/icon/Icon";
import CommonMessages from "components/common/CommonMessages";
import Select from "components/common/select/Select";
import { useOptionData } from "hooks/useOptionData";
import { Modal } from "react-bootstrap";
import { getProjectsListState } from "store/projects/selectors";
import { getAllFeatures } from "store/features/selector";
import { successNotifications } from "utils/successNotifications";
import ModalSubscribe from "components/ModalSubscribe/ModalSubsribe";
import { getSubscription } from "store/subscription/actions";

export interface ICrops {
  created_at: string;
  deleted: boolean;
  id: number;
  name: string;
  rendement: string;
  temperature_threshold: number;
  cropType: string;
  periods: Array<{
    name: string;
    end_date?: string;
    start_date?: string;
    start_degree_day?: number;
    end_degree_day?: number;
    sowing_date?: string;
    base_degree_day?: number;
    id: number;
    rank: number;
    crop_coefficient: number;
    crop_height: number;
  }>;
  updated_at: string;
}

function Crops() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { client_id, project_id } = useParams();
  const crops = useSelector(getCropsListState);
  const features = useSelector(getAllFeatures);
  const [show, onShow] = useState(false);
  const [currentId, setCurrentId] = useState(NaN);
  const [copyToOtherProjectId, setCopyToOtherProjectId] = useState<
    number | null
  >(null);
  const projectList = useSelector(getProjectsListState);
  const projectOptions = useOptionData(projectList);
  const [projectId, setProjectId] = useState(0);
  const intl = useIntl();
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    dispatch(getCrops({ clientId: client_id, projectId: project_id }));
    dispatch(getSubscription({ clientId: client_id }));
  }, [client_id, project_id]);

  const onDeleteCrop = () => {
    dispatch(
      deleteCrop({
        clientId: client_id,
        projectId: project_id,
        cropId: currentId,
        callback: () => onShow(false),
      })
    );
  };

  const onShowWarning = (id: number) => {
    setCurrentId(id);
    onShow(true);
  };

  const onCopy = (id: string | number) => () => {
    dispatch(
      copyCrop({
        clientId: client_id,
        projectId: project_id,
        cropId: id,
      })
    );
  };

  const onCreateSteeringForCrop = (id: string | number) => () => {
    navigate(
      ROUTES.STEERING_ALGORITHM_CREATE_FROM_CROP.replace(
        ":client_id",
        client_id as string
      )
        .replace(":project_id", project_id as string)
        .replace(":id", id as string)
    );
  };

  const onCreateNavigate = () => {
    navigate(
      ROUTES.CROPS_CREATE.replace(":client_id", client_id as string).replace(
        ":project_id",
        project_id as string
      )
    );
  };
  const onCreateFromCropNavigate = () => {
    navigate(
      ROUTES.CROPS_CREATE_FROM_TEMPLATE.replace(
        ":client_id",
        client_id as string
      ).replace(":project_id", project_id as string)
    );
  };
  const onEditNavigate = (id: number) => {
    navigate(
      ROUTES.CROPS_EDIT.replace(":client_id", client_id as string)
        .replace(":project_id", project_id as string)
        .replace(":id", String(id))
    );
  };

  return (
    <MainContent
      header={
        <>
          <h2 className={styled.title}>
            <FormattedMessage {...commonMessages.crops} />
          </h2>
          <div className={styled.flex}>
            <Button iconBefore={<Plus />} onClick={onCreateFromCropNavigate}>
              <FormattedMessage {...commonMessages.createFromTemplate} />
            </Button>
            {features && features.create_crop ? (
              <Button iconBefore={<Plus />} onClick={onCreateNavigate}>
                <FormattedMessage {...commonMessages.createNew} />
              </Button>
            ) : (
              <UnSubscribeButton
                iconBefore={<Plus />}
                onClick={() => setIsModalOpen(!isModalOpen)}
              >
                <FormattedMessage {...commonMessages.createNew} />
              </UnSubscribeButton>
            )}
          </div>
        </>
      }
    >
      <div className={styled.list}>
        {crops.map((crop: ICrops) => (
          <CropsCard
            key={crop.id}
            name={crop.name}
            date={crop.created_at}
            onEdit={() => onEditNavigate(crop.id)}
            onDelete={() => onShowWarning(crop.id)}
            onCopy={onCopy(crop.id)}
            onCreateSteeringAlgorithm={onCreateSteeringForCrop(crop.id)}
            onCopyToOtherProject={() => {
              setCopyToOtherProjectId(crop.id);
            }}
          />
        ))}
        <LeaveModalWindow
          type={LeaveModalEnum.DELETE}
          onDelete={onDeleteCrop}
          show={show}
          onHide={() => onShow(false)}
        />
        <Modal
          show={copyToOtherProjectId != null}
          onHide={() => setCopyToOtherProjectId(null)}
          centered
        >
          <div className={styled.container}>
            <Icon name={ICON_NAMES_ENUM.warning} />
            <h3 className={styled.modal_title}>
              <FormattedMessage {...CommonMessages.copy_to_other_project} />
            </h3>
            <Select
              label={"Select project"}
              options={projectOptions}
              onChange={(e: any) => {
                setProjectId(e.value);
              }}
            />
            <div className={styled.row}>
              <Button
                onClick={() => {
                  dispatch(
                    copyCropToOtherProject({
                      clientId: client_id,
                      projectId: project_id,
                      cropId: copyToOtherProjectId,
                      projectToId: projectId,
                      callback: () => {
                        successNotifications({
                          title: intl.formatMessage(
                            CommonMessages.copy_to_other_project
                          ),
                          message: intl.formatMessage(
                            CommonMessages.successCopyToOtherProject,
                            {
                              objet_type: intl.formatMessage(
                                CommonMessages.crop
                              ),
                              copied_project: projectList.find(
                                (elem: any) => elem.id === projectId
                              ).name,
                            }
                          ),
                        });
                        setCopyToOtherProjectId(null);
                      },
                    })
                  );
                }}
              >
                Copy
              </Button>
              <Button
                type="submit"
                onClick={() => {
                  setCopyToOtherProjectId(null);
                }}
              >
                <FormattedMessage {...CommonMessages.cancel} />
              </Button>
            </div>
          </div>
        </Modal>
      </div>
      <ModalSubscribe isOpen={isModalOpen} setOpen={setIsModalOpen} />
    </MainContent>
  );
}

export { Crops };
