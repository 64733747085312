import { useEffect, useMemo, useState } from "react";
import styled from "./styled.module.scss";
import clsx from "clsx";
import AnalysisContent from "./AnalysisContent";
import SimulationConfigContent from "./SimulationConfigContent";
import WaterbalanceContent from "./WaterbalanceContent";
import ThermalStress from "./ThermalStress";
import GrasslandYieldContent from "./GrasslandYieldContent";
import IrradianceContent from "./IrradianceContent";
import ProductionContent from "./ProductionContent";
import { useSearchParams } from "react-router-dom";
import { getAllFeatures } from "store/features/selector";
import { useSelector } from "react-redux";
import ModalSubscribe from "components/ModalSubscribe/ModalSubsribe";
import { useLocation } from "react-router-dom";
import AnimalWelfareContent from "./AnimalWelfareContent";

class SimulationResultMenuProps {
  result: any;
}

const WaterBalanceResultMenu = ({ result }: SimulationResultMenuProps) => {
  const [itemList, setItemList] = useState([
    "Simulation Parameters",
    "Analysis",
    "Irradiance",
    "Production",
    "Water Balance",
    "Thermal Stress",
  ]);
  const contents = useMemo(() => {
    return {
      ["Simulation Parameters"]: <SimulationConfigContent result={result} />,
      ["Analysis"]: <AnalysisContent result={result} />,
      ["Irradiance"]: <IrradianceContent result={result} />,
      ["Production"]: <ProductionContent result={result} />,
      ["Water Balance"]: <WaterbalanceContent result={result} />,
      ["Thermal Stress"]: <ThermalStress result={result} />,
      ["Grassland Yield"]: <GrasslandYieldContent result={result} />,
      ["Animal Welfare"]: <AnimalWelfareContent result={result} />,
    };
  }, [result]);
  const [itemChecked, setItemChecked] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [itemSelected, setItemSelected] = useState(itemList[0]);
  const [searchParams, setSearchParams] = useSearchParams();
  const feature = useSelector(getAllFeatures);

  useEffect(() => {
    if (
      result.simulation.grassland_primary_yield_calculation &&
      !itemList.find((title) => title == "Grassland Yield")
    )
      setItemList([...itemList, "Grassland Yield", "Animal Welfare"]);
    setItemChecked(true);
  }, [result]);

  useEffect(() => {
    if (!itemChecked) return;
    const menu = searchParams.get("menu");

    if (!itemList.find((title) => title == menu))
      setSearchParams({ menu: "Simulation Parameters" });
    else setItemSelected(menu!);
  }, [itemChecked]);

  const onSelectItem = (item: string) => {
    if (item === "Irradiance" && !feature.irradiance_simulation)
      setIsOpenModal(true);
    else if (item === "Production" && !feature.prod_simulation)
      setIsOpenModal(true);
    else if (item === "Water Balance" && !feature.water_simulation)
      setIsOpenModal(true);
    else if (item === "Thermal Stress" && !feature.thermal_simulation)
      setIsOpenModal(true);
    else if (item === "Grassland Yield" && !feature.yield_simulation)
      setIsOpenModal(true);
    else if (item === "Animal Welfare" && !feature.animal_welfare_simulation)
      setIsOpenModal(true);
    else {
      setItemSelected(item);
      setSearchParams({ menu: item });
    }
  };

  return (
    <div className={styled.main_container}>
      <div className={styled.item_list}>
        {itemList.map((value) => (
          <button
            id={"item" + value}
            onClick={(e) => onSelectItem(value)}
            className={clsx(styled.item, {
              [styled.active]: itemSelected === value,
            })}
          >
            {value}
          </button>
        ))}
      </div>
      <div className={styled.content}>
        {contents[itemSelected as keyof typeof contents] || (
          <>Content Not Implemented</>
        )}
      </div>
      <ModalSubscribe isOpen={isOpenModal} setOpen={setIsOpenModal} />
    </div>
  );
};

export default WaterBalanceResultMenu;
