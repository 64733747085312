import { useEffect, useState } from "react";
import styled from "./SteeringAlgorithmsCreate.module.scss";
import EditName from "components/EditName/EditName";
import Button from "components/common/button/Button";
import commonMessages from "components/common/CommonMessages";
import { FormattedMessage, useIntl } from "react-intl";
import steeringAlgorithmCreateMessages from "pages/SteeringAlgorithmsCreate/SteeringAlgorithmsCreateMessages";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "constants/routes";
import { Icon, ICON_NAMES_ENUM } from "components/common/icon/Icon";
import clsx from "clsx";
import {
  SteeringAlgorithmsCreateHook,
  getInitialFormValue,
} from "./SteeringAlgorithmsCreateHook";
import MainContent from "components/MainContent/MainContent";
import AddModalRowSteeringAlgorithmsTableMessages from "components/AddModalRowSteeringAlgorithmsTable/AddModalRowSteeringAlgorithmsTableMessages";
import { usePrompt } from "hooks/usePromt";
import { isEqual } from "lodash";
import { SpanError } from "components/common/span-error/SpanError";
import Select from "components/common/select/Select";
import { useDispatch, useSelector } from "react-redux";
import { ICrops } from "pages/Crops/Crops";
import { getNavigationState } from "store/navigation/selectors";
import useSingeData from "components/SimulationBody/useSingeData";
import { AddModalRowSteeringAlgorithmsTable } from "components/AddModalRowSteeringAlgorithmsTable/AddModalRowSteeringAlgorithmsTable";
import { ReactComponent as Edit } from "assets/icons/edit.svg";
import { ReactComponent as Delete } from "assets/icons/delete.svg";
import { IRowState, IRowType } from "./SteeringAlgorithmsCreateTypes";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import CropsCreateMessages from "pages/CropsCreate/CropCreateMessages";
import Input from "components/common/input/Input";
import { getAllFeatures } from "store/features/selector";
import { errorNotifications } from "utils/errorNotifications";
import { Loader } from "@react-three/drei";
import { getSubscription } from "store/subscription/actions";
function SteeringAlgorithmsCreate() {
  const dispatch = useDispatch();
  const { client_id, project_id, id } = useParams();
  const { crops } = useSelector(getNavigationState);
  const navigate = useNavigate();
  const intl = useIntl();
  const features = useSelector(getAllFeatures);
  const [isDisplayed, setIsDisplayed] = useState(false);
  const {
    models: { IsOpenModal, isEditFunc, currentRow, formik },
    commands: {
      resetForm,
      onSaveRow,
      onDelete,
      onAddNewRow,
      onCopyRow,
      onDeleteRow,
      onEditSave,
      onEditField,
      onHideModal,
    },
  } = SteeringAlgorithmsCreateHook();

  const monthList = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const displayDate = (date: string | undefined) => {
    if (!date) return "";
    const month = monthList[Number(date.substring(5).split("-")[0]) - 1];
    return `${date.substring(5).split("-")[1]}  ${month}`;
  };

  const { crop, getCrop } = useSingeData();

  usePrompt(
    !isEqual(formik.values, getInitialFormValue(intl)) && !formik.isSubmitting
  );

  const [periodToAdd, setPeriodToAdd] = useState(0);

  const addNewRow = (period_id: number) => {
    setPeriodToAdd(period_id);
    onAddNewRow();
  };

  useEffect(() => {
    if (crops.find((crop: ICrops) => crop.id == formik.values.crop_id)) {
      resetForm();
      getCrop(formik.values.crop_id);
    }
  }, [formik.values.crop_id]);

  useEffect(() => {
    if (id && crops.filter((crop: { id: number }) => crop.id == Number(id))) {
      formik.setFieldValue("crop_id", id);
      getCrop(id);
    }
  }, [id]);

  useEffect(() => {
    if (features)
      if (!features.custom_steering) {
        errorNotifications(
          "You don't have the right underwriting plan to create custom steering."
        );
        navigate(
          ROUTES.STEERING_ALGORITHM.replace(
            ":client_id",
            client_id as string
          ).replace(":project_id", project_id as string)
        );
      } else setIsDisplayed(true);
  }, [features]);

  useEffect(() => {
    dispatch(getSubscription({ clientId: client_id }));
  }, [client_id, project_id]);

  const checkSave = (): boolean => {
    if (!formik.values.crop_id) return true;
    if (!crops.find((crop: any) => crop.id == formik.values.crop_id))
      return true;
    return false;
  };

  if (isDisplayed === true)
    return (
      <div>
        <form onSubmit={formik.handleSubmit}>
          <MainContent
            header={
              <>
                <EditName
                  autoFocus
                  onFocus={(event) => {
                    event.target.select();
                  }}
                  placeholder={intl.formatMessage(
                    steeringAlgorithmCreateMessages.steeringAlgorithmName
                  )}
                  name="name"
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  errorMessage={formik.touched.name ? formik.errors.name : ""}
                  onBlur={formik.handleBlur}
                />
                <div className={styled.flex}>
                  <Button
                    variant="text"
                    onClick={() => {
                      navigate(
                        ROUTES.STEERING_ALGORITHM.replace(
                          ":client_id",
                          client_id as string
                        ).replace(":project_id", project_id as string)
                      );
                    }}
                  >
                    <FormattedMessage {...commonMessages.cancel} />
                  </Button>
                  <Button
                    iconBefore={<Icon name={ICON_NAMES_ENUM.save} />}
                    type="submit"
                    isLoading={formik.isSubmitting}
                    disabled={formik.isSubmitting}
                  >
                    <FormattedMessage {...commonMessages.save} />
                  </Button>
                </div>
              </>
            }
          >
            <div className={styled.list}>
              <div>
                <h2 className={styled["section-title"]}>
                  <FormattedMessage
                    {...steeringAlgorithmCreateMessages.selectCrop}
                  />
                </h2>

                <Select
                  label={"Select Crop"}
                  options={crops.map((crop: ICrops) => ({
                    value: crop.id,
                    label: crop.name,
                  }))}
                  value={{
                    value: formik.values.crop_id,
                    label: crops.find(
                      (crop: ICrops) => crop.id == formik.values.crop_id
                    )
                      ? crops.find(
                          (crop: ICrops) => crop.id == formik.values.crop_id
                        ).name
                      : "",
                  }}
                  onChange={(e: any) => {
                    formik.setFieldValue("crop_id", e.value);
                  }}
                  errorMessage={
                    formik.touched.crop_id ? formik.errors.crop_id : ""
                  }
                  isSearchable={false}
                  menuHeight={400}
                />
              </div>
              {crop && !crop.periods[0].start_date && (
                <div className={styled.degreeDaysCalculation}>
                  <h2 className={styled["section-title"]}>
                    <FormattedMessage
                      {...CropsCreateMessages.degreeDaysCalculation}
                    />
                  </h2>
                  <div className={styled.flex}>
                    <div className={styled.degreeInfoElement}>
                      <Tooltip
                        title={intl.formatMessage(
                          CropsCreateMessages.sowingDateHelp
                        )}
                        placement="top"
                      >
                        <h3 className={styled["selector-title"]}>
                          <FormattedMessage
                            {...CropsCreateMessages.sowingDate}
                          />
                          <div className={styled["help-icon"]}>
                            <Icon
                              name={ICON_NAMES_ENUM.help_circle}
                              className={styled.file__icon}
                            />
                          </div>
                        </h3>
                      </Tooltip>
                      <Input
                        id="sowing_date"
                        disabled
                        onChange={() => {}}
                        value={displayDate(crop.periods[0]!.sowing_date)}
                      />
                    </div>
                    <div className={styled.degreeInfoElement}>
                      <h3 className={styled["selector-title"]}>
                        <FormattedMessage
                          {...CropsCreateMessages.baseTemperature}
                        />
                      </h3>
                      <Input
                        type="number"
                        disabled
                        integerNumber
                        id="base_degree_day"
                        value={crop.periods[0]!.base_degree_day}
                      />
                    </div>
                  </div>
                </div>
              )}
              {crop && (
                <div className={styled["table-container"]}>
                  <table
                    className={clsx(styled.table, {
                      [styled.error]: formik.touched.row && formik.errors.row,
                    })}
                  >
                    <thead>
                      <tr>
                        <th>
                          <div className={styled.title}>
                            <FormattedMessage
                              {...steeringAlgorithmCreateMessages.stageName}
                            />
                          </div>
                        </th>
                        <th>
                          <div className={styled.title}>
                            {crop.periods[0].start_date != null ? (
                              <FormattedMessage
                                {...steeringAlgorithmCreateMessages.periodStart}
                              />
                            ) : (
                              <FormattedMessage
                                {...CropsCreateMessages.startDegreeDay}
                              />
                            )}
                          </div>
                        </th>
                        <th>
                          <div className={styled.title}>
                            {crop.periods[0].start_date != null ? (
                              <FormattedMessage
                                {...steeringAlgorithmCreateMessages.periodEnd}
                              />
                            ) : (
                              <FormattedMessage
                                {...CropsCreateMessages.endDegreeDay}
                              />
                            )}
                          </div>
                        </th>
                        <th>
                          <div className={styled.title}>
                            <FormattedMessage
                              {...steeringAlgorithmCreateMessages.rules}
                            />
                          </div>
                        </th>
                        <th>
                          <div className={styled.title}> </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {crop.periods.map((period) => (
                        <tr key={period.id.toString()}>
                          <td>
                            <div className={styled["td-element"]}>
                              {period.name}
                            </div>
                          </td>
                          <td>
                            <div className={styled["td-element"]}>
                              {crop.periods[0].start_date != null
                                ? displayDate(period.start_date)
                                : period.start_degree_day}
                            </div>
                          </td>
                          <td>
                            <div className={styled["td-element"]}>
                              {crop.periods[0].start_date != null
                                ? displayDate(period.end_date)
                                : period.end_degree_day}
                            </div>
                          </td>

                          {
                            <td>
                              <div className={styled["td-list"]}>
                                {formik.values.row
                                  .filter((row) => row.period_id == period.id)
                                  .map((row, index) => {
                                    var ret;
                                    switch (row.type) {
                                      case IRowType.time_slot:
                                        {
                                          ret = (
                                            <li key={`${period.id}+${index}`}>
                                              {`Max ${
                                                row.state ==
                                                IRowState.max_irradiance
                                                  ? "irradiance"
                                                  : "shade"
                                              } between ${
                                                row.value.hourStart < 12
                                                  ? row.value.hourStart == 0
                                                    ? "12AM"
                                                    : `${row.value.hourStart}AM`
                                                  : row.value.hourStart == 12
                                                  ? "12PM"
                                                  : `${
                                                      row.value.hourStart - 12
                                                    }PM`
                                              } and ${
                                                row.value.hourEnd < 12
                                                  ? row.value.hourEnd == 0
                                                    ? "12AM"
                                                    : `${row.value.hourEnd}AM`
                                                  : row.value.hourEnd == 12
                                                  ? "12PM"
                                                  : `${
                                                      row.value.hourEnd - 12
                                                    }PM`
                                              }.`}
                                              <button
                                                className={styled.button}
                                                onClick={() => onEditField(row)}
                                                type="button"
                                              >
                                                <Edit />
                                              </button>
                                              <span
                                                className={styled.vertical}
                                              />
                                              <button
                                                type="button"
                                                className={clsx(
                                                  styled.button,
                                                  styled.red
                                                )}
                                                onClick={() => onDeleteRow(row)}
                                              >
                                                <Delete />
                                              </button>
                                            </li>
                                          );
                                        }
                                        break;
                                      case IRowType.irradiance_fraction:
                                        {
                                          ret = (
                                            <li key={`${period.id}+${index}`}>
                                              {`Irradiance fraction of ${row.value.objective}%`}
                                              <button
                                                className={styled.button}
                                                onClick={() => onEditField(row)}
                                                type="button"
                                              >
                                                <Edit />
                                              </button>
                                              <span
                                                className={styled.vertical}
                                              />
                                              <button
                                                type="button"
                                                className={clsx(
                                                  styled.button,
                                                  styled.red
                                                )}
                                                onClick={() => onDeleteRow(row)}
                                              >
                                                <Delete />
                                              </button>
                                            </li>
                                          );
                                        }
                                        break;
                                      case IRowType.dli_threshold:
                                        {
                                          ret = (
                                            <li key={`${period.id}+${index}`}>
                                              {`DLI threshold of ${row.value.objective}`}
                                              <button
                                                className={styled.button}
                                                onClick={() => onEditField(row)}
                                                type="button"
                                              >
                                                <Edit />
                                              </button>
                                              <span
                                                className={styled.vertical}
                                              />
                                              <button
                                                type="button"
                                                className={clsx(
                                                  styled.button,
                                                  styled.red
                                                )}
                                                onClick={() => onDeleteRow(row)}
                                              >
                                                <Delete />
                                              </button>
                                            </li>
                                          );
                                        }
                                        break;
                                    }
                                    return ret;
                                  })}
                              </div>
                            </td>
                          }
                          <td>
                            <div className={styled["td-element"]}>
                              <button
                                className={styled.button}
                                onClick={() => addNewRow(period.id)}
                                type="button"
                              >
                                <Icon name={ICON_NAMES_ENUM.plus_circle} />
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
              {crop && (
                <SpanError
                  errorMessage={
                    formik.touched.row && (formik.errors.row as string)
                  }
                />
              )}
              <AddModalRowSteeringAlgorithmsTable
                title={
                  isEditFunc
                    ? intl.formatMessage(
                        AddModalRowSteeringAlgorithmsTableMessages.editRow
                      )
                    : intl.formatMessage(
                        AddModalRowSteeringAlgorithmsTableMessages.addRow
                      )
                }
                isEdit={isEditFunc}
                show={IsOpenModal}
                onHide={onHideModal}
                onSave={onSaveRow}
                onEdit={onEditSave}
                rows={formik.values.row}
                currentRow={currentRow}
                currentPeriodId={periodToAdd}
              />
            </div>
          </MainContent>
        </form>
      </div>
    );
  else return <Loader />;
}

export { SteeringAlgorithmsCreate };
