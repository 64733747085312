import routine from 'store/services/routine';

export const getCrops = routine('GET_CROPS');
export const getSingleCrop = routine('GET_SINGLE_CROPS');
export const getSimulationCrop = routine('GET_SIMULATION_CROP');
export const getSingleCopyCrop = routine('GET_SINGLE_COPY_CROPS');
export const createCrop = routine('CREATE_CROP');
export const copyCrop = routine('COPY_CROP');
export const updateCrop = routine('UPDATE_CROP');
export const deleteCrop = routine('DELETE_CROP');
export const copyCropToOtherProject = routine('COPY_CROP_TO_OTHER_PROJECT');
