import { getCropPack, setCropPack, editCropPack } from "./actions";

const INITIAL_STATE = {
  cropPack: null,
  isLoading: false,
};

const cropPackReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case getCropPack.TRIGGER: {
      return {
        ...state,
        cropPack: null,
        isLoading: true,
      };
    }

    case getCropPack.SUCCESS: {
      return {
        ...state,
        cropPack: payload,
        isLoading: false,
      };
    }

    case getCropPack.FULFILL: {
      return {
        ...state,
      };
    }

    case getCropPack.FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case editCropPack.TRIGGER: {
      return {
        ...state,
        cropPack: null,
        isLoading: true,
      };
    }

    case editCropPack.SUCCESS: {
      return {
        ...state,
        cropPack: payload,
        isLoading: false,
      };
    }

    case editCropPack.FULFILL: {
      return {
        ...state,
      };
    }

    case editCropPack.FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case setCropPack.FULFILL: {
      return {
        ...state,
        cropPack: payload.crop_packs,
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default cropPackReducer;
