import { put, takeLatest, call } from "redux-saga/effects";
// actions
import { getCropPack as action } from "store/crop_pack/actions";
// api
import { getCropPack as api } from "store/crop_pack/api";
import { errorNotifications } from "utils/errorNotifications";

function* saga({ payload }) {
  try {
    yield put(action.request());

    const response = yield call(api, payload);

    yield put(action.success(response));
  } catch (error) {
    yield call(errorNotifications, error.response.data.data);
  } finally {
    yield put(action.fulfill());
  }
}

export default function* getCropPack() {
  yield takeLatest(action.TRIGGER, saga);
}
