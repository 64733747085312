import { getNavigation } from "store/navigation/actions";
import {
  createStructure,
  updateStructure,
  deleteStructure,
  copyStructure,
} from "store/structures/actions";
import {
  createCrop,
  deleteCrop,
  updateCrop,
  copyCrop,
} from "store/crops/actions";
import {
  createSimulation,
  deleteSimulation,
  updateSimulation,
  runSimulation,
  copySimulation,
  deleteSimulationResult,
} from "store/simulations/actions";
import {
  createWaterBalance,
  deleteWaterBalance,
  updateWaterBalance,
  runWaterBalance,
  copyWaterBalance,
  deleteWaterBalanceResult,
} from "store/water_balances/actions";
import {
  createDataset,
  createDatasetFromAPI,
  createDatasetFromOpen,
  updateDataset,
  deleteDataset,
  copyDataset,
} from "store/datasets/actions";
import {
  createBatch,
  updateBatch,
  deleteBatch,
  copyBatch,
  runBatchSimulation,
  deleteBatchResult,
} from "store/batches/actions";
import {
  createBatchWaterBalance,
  updateBatchWaterBalance,
  deleteBatchWaterBalance,
  copyBatchWaterBalance,
  runBatchWaterBalanceSimulation,
  deleteBatchWaterBalanceResult,
} from "store/batches_water_balance/actions";
import {
  createSteeringAlgorithm,
  deleteSteeringAlgorithm,
  updateSteeringAlgorithm,
  copySteeringAlgorithm,
} from "store/steering_algorithms/actions";

import {
  createSoilInformation,
  deleteSoilInformation,
  updateSoilInformation,
  copySoilInformation,
  createCurrentSoilInformationByLocalization,
} from "store/soil_informations/actions";
import {
  createSizingOptimization,
  deleteSizingOptimization,
  updateSizingOptimization,
  copySizingOptimization,
  runSizingOptimizationSimulation,
} from "store/sizing_optimizations/actions";
import { logout } from "store/user/actions";

const INITIAL_STATE = {
  isLoading: false,
  structures: [],
  crops: [],
  weather_datasets: [],
  production_datasets: [],
  etp_datasets: [],
  universal_datasets: [],
  simulations: [],
  batches: [],
  batches_water_balance: [],
  steering_algorithms: [],
  soil_informations: [],
  water_balances: [],
  sizing_optimizations: [],
};

const navigationReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case logout.SUCCESS: {
      return {
        ...INITIAL_STATE,
      };
    }

    case getNavigation.TRIGGER: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case getNavigation.SUCCESS: {
      const {
        weatherDatasets,
        productionDatasets,
        etpDatasets,
        universalDatasets,
      } = payload.datasets.reduce(
        (result, item) => {
          if (item.type === "weather") {
            result.weatherDatasets.push(item);
          } else if (item.type === "ETP") {
            result.etpDatasets.push(item);
          } else if (item.type === "production") {
            result.productionDatasets.push(item);
          } else {
            result.universalDatasets.push(item);
          }
          return result;
        },
        {
          weatherDatasets: [],
          productionDatasets: [],
          etpDatasets: [],
          universalDatasets: [],
        }
      );

      const soils = payload.soil_informations.sort((a, b) => {
        if (b.type === "default" && a.type !== "default") return -1;
        if (a.type === "default" && b.type !== "default") return 1;
        return 0;
      });
      return {
        ...state,
        structures: payload.structures,
        crops: payload.crops,
        weather_datasets: weatherDatasets,
        production_datasets: productionDatasets,
        etp_datasets: etpDatasets,
        universal_datasets: universalDatasets,
        simulations: payload.simulations,
        steering_algorithms: payload.steering_algorithms,
        soil_informations: soils,
        water_balances: payload.water_balances,
        batches: payload.batches,
        batches_water_balance: payload.batches_water_balance,
        sizing_optimizations: payload.sizing_optimizations,
      };
    }

    case getNavigation.FULFILL: {
      return {
        ...state,
        isLoading: false,
      };
    }

    // region Structures
    case createStructure.SUCCESS:
    case copyStructure.SUCCESS: {
      return {
        ...state,
        structures: [
          ...state.structures,
          {
            id: payload.id,
            name: payload.name,
          },
        ],
      };
    }

    case deleteStructure.SUCCESS: {
      const structures = state.structures.filter(({ id }) => id !== payload.id);

      return {
        ...state,
        structures,
      };
    }

    case updateStructure.SUCCESS: {
      const newList = [...state.structures];
      const index = newList.findIndex(({ id }) => id === payload.id);
      newList[index] = {
        id: payload.id,
        name: payload.name,
      };

      return {
        ...state,
        structures: newList,
      };
    }
    // endregion

    // region Crops
    case createCrop.SUCCESS: {
      const new_steering_algorithms = [];

      for (let index = 0; index < payload.steering.length; index++) {
        const element = payload.steering[index];
        new_steering_algorithms.push({
          id: element.id,
          name: element.name,
          crop_id: element.crop_id,
        });
      }

      return {
        ...state,
        crops: [
          ...state.crops,
          {
            id: payload.crop.id,
            name: payload.crop.name,
          },
        ],
        steering_algorithms: [
          ...state.steering_algorithms,
          ...new_steering_algorithms,
        ],
      };
    }

    case copyCrop.SUCCESS: {
      return {
        ...state,
        crops: [
          ...state.crops,
          {
            id: payload.id,
            name: payload.name,
          },
        ],
      };
    }

    case deleteCrop.SUCCESS: {
      const crops = state.crops.filter(({ id }) => id !== payload.id);
      const steering_algorithms = state.steering_algorithms.filter(
        ({ crop_id }) => crop_id !== payload.id
      );

      return {
        ...state,
        crops,
        steering_algorithms,
      };
    }

    case updateCrop.SUCCESS: {
      const newList = [...state.crops];
      const index = newList.findIndex(({ id }) => id === payload.id);
      newList[index] = {
        id: payload.id,
        name: payload.name,
      };

      return {
        ...state,
        crops: newList,
      };
    }
    // endregion

    // region Simulations
    case createSimulation.SUCCESS:
    case copySimulation.SUCCESS: {
      return {
        ...state,
        simulations: [
          ...state.simulations,
          {
            id: payload.id,
            name: payload.name,
            results: [],
          },
        ],
      };
    }

    case deleteSimulation.SUCCESS: {
      const simulations = state.simulations.filter(
        ({ id }) => id !== payload.id
      );

      return {
        ...state,
        simulations,
      };
    }

    case updateSimulation.SUCCESS: {
      const newList = [...state.simulations];
      const index = newList.findIndex(({ id }) => id === payload.id);
      newList[index] = {
        ...newList[index],
        id: payload.id,
        name: payload.name,
      };

      return {
        ...state,
        simulations: newList,
      };
    }

    case runSimulation.SUCCESS: {
      const newList = [...state.simulations];
      const index = newList.findIndex(
        ({ id }) => id.toString() === payload.simulationId.toString()
      );
      const newResults = [...newList[index].results];
      newResults.push({
        name: payload.name,
        id: payload.id,
      });
      newList[index] = {
        id: newList[index].id,
        name: newList[index].name,
        results: newResults,
      };
      return {
        ...state,
        simulations: newList,
      };
    }

    case deleteSimulationResult.SUCCESS: {
      const newList = [...state.simulations];
      const index = newList.findIndex(
        ({ id }) => id.toString() === payload.simulationId
      );
      const newResults = newList[index].results.filter(
        ({ id }) => id.toString() !== payload.resultId
      );
      newList[index] = {
        id: newList[index].id,
        name: newList[index].name,
        results: newResults,
      };
      return {
        ...state,
        simulations: newList,
      };
    }
    // endregion

    // region WaterBalances
    case createWaterBalance.SUCCESS:
    case copyWaterBalance.SUCCESS: {
      return {
        ...state,
        water_balances: [
          ...state.water_balances,
          {
            id: payload.id,
            name: payload.name,
            results: [],
          },
        ],
      };
    }

    case deleteWaterBalance.SUCCESS: {
      const water_balances = state.water_balances.filter(
        ({ id }) => id !== payload.id
      );

      return {
        ...state,
        water_balances,
      };
    }

    case updateWaterBalance.SUCCESS: {
      const newList = [...state.water_balances];
      const index = newList.findIndex(({ id }) => id === payload.id);
      newList[index] = {
        ...newList[index],
        id: payload.id,
        name: payload.name,
      };

      return {
        ...state,
        water_balances: newList,
      };
    }

    case runWaterBalance.SUCCESS: {
      const newList = [...state.water_balances];
      const index = newList.findIndex(
        ({ id }) => id.toString() === payload.waterBalanceId.toString()
      );
      const newResults = [...newList[index].results];
      newResults.push({
        name: payload.name,
        id: payload.id,
      });
      newList[index] = {
        id: newList[index].id,
        name: newList[index].name,
        results: newResults,
      };
      return {
        ...state,
        water_balances: newList,
      };
    }

    case deleteWaterBalanceResult.SUCCESS: {
      const newList = [...state.water_balances];
      const index = newList.findIndex(
        ({ id }) => id.toString() === payload.simulationId
      );
      const newResults = newList[index].results.filter(
        ({ id }) => id.toString() !== payload.resultId
      );
      newList[index] = {
        id: newList[index].id,
        name: newList[index].name,
        results: newResults,
      };
      return {
        ...state,
        water_balances: newList,
      };
    }
    // endregion

    // region Datasets
    case createDatasetFromAPI.SUCCESS:
    case createDatasetFromOpen.SUCCESS: {
      const weather_datasets = [...state.weather_datasets];
      const production_datasets = [...state.production_datasets];
      const etp_datasets = [...state.etp_datasets];
      const universal_datasets = [...state.universal_datasets];

      universal_datasets.push({
        id: payload.id,
        name: payload.name,
        type: payload.dataset_type,
      });

      return {
        ...state,
        weather_datasets,
        production_datasets,
        etp_datasets,
        universal_datasets,
      };
    }

    case createDataset.SUCCESS:
    case copyDataset.SUCCESS: {
      const weather_datasets = [...state.weather_datasets];
      const production_datasets = [...state.production_datasets];
      const etp_datasets = [...state.etp_datasets];
      const universal_datasets = [...state.universal_datasets];
      if (payload.dataset_type === "weather") {
        weather_datasets.push({
          id: payload.id,
          name: payload.name,
          type: payload.dataset_type,
        });
      }
      if (payload.dataset_type === "production") {
        production_datasets.push({
          id: payload.id,
          name: payload.name,
          type: payload.dataset_type,
        });
      }
      if (payload.dataset_type === "ETP") {
        etp_datasets.push({
          id: payload.id,
          name: payload.name,
          type: payload.dataset_type,
        });
      }
      if (payload.dataset_type === "universal") {
        universal_datasets.push({
          id: payload.id,
          name: payload.name,
          type: payload.dataset_type,
        });
      }

      return {
        ...state,
        weather_datasets,
        production_datasets,
        etp_datasets,
        universal_datasets,
      };
    }

    case deleteDataset.SUCCESS: {
      if (payload.dataset_type === "weather") {
        const weather_datasets = state.weather_datasets.filter(
          ({ id }) => id !== payload.id
        );
        return {
          ...state,
          weather_datasets,
        };
      }
      if (payload.dataset_type === "production") {
        const production_datasets = state.production_datasets.filter(
          ({ id }) => id !== payload.id
        );
        return {
          ...state,
          production_datasets,
        };
      }
      if (payload.dataset_type === "ETP") {
        const etp_datasets = state.etp_datasets.filter(
          ({ id }) => id !== payload.id
        );
        return {
          ...state,
          etp_datasets,
        };
      }

      return state;
    }

    case updateDataset.SUCCESS: {
      if (payload.dataset_type === "weather") {
        const newList = [...state.weather_datasets];
        const index = newList.findIndex(({ id }) => id === payload.id);
        newList[index] = {
          id: payload.id,
          name: payload.name,
          type: payload.dataset_type,
        };
        return {
          ...state,
          weather_datasets: newList,
        };
      }
      if (payload.dataset_type === "production") {
        const newList = [...state.production_datasets];
        const index = newList.findIndex(({ id }) => id === payload.id);
        newList[index] = {
          id: payload.id,
          name: payload.name,
          type: payload.dataset_type,
        };
        return {
          ...state,
          production_datasets: newList,
        };
      }
      if (payload.dataset_type === "ETP") {
        const newList = [...state.etp_datasets];
        const index = newList.findIndex(({ id }) => id === payload.id);
        newList[index] = {
          id: payload.id,
          name: payload.name,
          type: payload.dataset_type,
        };
        return {
          ...state,
          etp_datasets: newList,
        };
      }
      if (payload.dataset_type === "universal") {
        const universal_datasets = [...state.universal_datasets];

        const index = universal_datasets.findIndex(
          ({ id }) => id === payload.id
        );
        universal_datasets[index] = {
          id: payload.id,
          name: payload.name,
          type: payload.dataset_type,
        };
        return {
          ...state,
          universal_datasets: universal_datasets,
        };
      }

      return state;
    }
    // endregion

    // region Batches
    case createBatch.SUCCESS:
    case copyBatch.SUCCESS: {
      return {
        ...state,
        batches: [
          ...state.batches,
          {
            id: payload.id,
            name: payload.name,
            results: [],
          },
        ],
      };
    }

    case deleteBatch.SUCCESS: {
      const batches = state.batches.filter(({ id }) => id !== payload.id);

      return {
        ...state,
        batches,
      };
    }

    case updateBatch.SUCCESS: {
      const newList = [...state.batches];
      const index = newList.findIndex(({ id }) => id === payload.id);
      newList[index] = {
        ...newList[index],
        id: payload.id,
        name: payload.name,
      };

      return {
        ...state,
        batches: newList,
      };
    }

    case runBatchSimulation.SUCCESS: {
      const newList = [...state.batches];
      const index = newList.findIndex(
        ({ id }) => id.toString() === payload.batchId.toString()
      );
      const newResults = [...newList[index]?.results];

      const rows = payload.rows.map(({ simulation_result }) => ({
        name: simulation_result.name,
        id: simulation_result.id,
      }));

      newResults.push({
        name: payload.name,
        id: payload.id,
        simulations: rows,
      });
      newList[index] = {
        id: newList[index].id,
        name: newList[index].name,
        results: newResults,
      };
      return {
        ...state,
        batches: newList,
      };
    }

    case deleteBatchResult.SUCCESS: {
      const newList = [...state.batches];
      const index = newList.findIndex(
        ({ id }) => id.toString() === payload.batchId
      );
      const newResults = newList[index].results.filter(
        ({ id }) => id.toString() !== payload.resultId
      );
      newList[index] = {
        id: newList[index].id,
        name: newList[index].name,
        results: newResults,
      };
      return {
        ...state,
        batches: newList,
      };
    }
    // endregion

    // region Batch Water Balances
    case createBatchWaterBalance.SUCCESS:
    case copyBatchWaterBalance.SUCCESS: {
      return {
        ...state,
        batches_water_balance: [
          ...state.batches_water_balance,
          {
            id: payload.id,
            name: payload.name,
            results: [],
          },
        ],
      };
    }

    case deleteBatchWaterBalance.SUCCESS: {
      const batches_water_balance = state.batches_water_balance.filter(
        ({ id }) => id !== payload.id
      );

      return {
        ...state,
        batches_water_balance,
      };
    }

    case updateBatchWaterBalance.SUCCESS: {
      const newList = [...state.batches_water_balance];
      const index = newList.findIndex(({ id }) => id === payload.id);
      newList[index] = {
        ...newList[index],
        id: payload.id,
        name: payload.name,
      };

      return {
        ...state,
        batches_water_balance: newList,
      };
    }

    case runBatchWaterBalanceSimulation.SUCCESS: {
      const newList = [...state.batches_water_balance];
      const index = newList.findIndex(
        ({ id }) => id.toString() === payload.batchWaterBalanceId.toString()
      );
      const newResults = [...newList[index]?.results];

      const rows = payload.rows.map(({ simulation_water_balance_result }) => ({
        name: simulation_water_balance_result.name,
        id: simulation_water_balance_result.id,
      }));

      newResults.push({
        name: payload.name,
        id: payload.id,
        simulations_water_balance: rows,
      });
      newList[index] = {
        id: newList[index].id,
        name: newList[index].name,
        results: newResults,
      };
      return {
        ...state,
        batches_water_balance: newList,
      };
    }

    case deleteBatchWaterBalanceResult.SUCCESS: {
      const newList = [...state.batches];
      const index = newList.findIndex(
        ({ id }) => id.toString() === payload.batchWaterBalanceId
      );
      const newResults = newList[index].results.filter(
        ({ id }) => id.toString() !== payload.resultId
      );
      newList[index] = {
        id: newList[index].id,
        name: newList[index].name,
        results: newResults,
      };
      return {
        ...state,
        batches_water_balance: newList,
      };
    }
    // endregion

    // region Steering Algorithm
    case createSteeringAlgorithm.SUCCESS:
    case copySteeringAlgorithm.SUCCESS: {
      return {
        ...state,
        steering_algorithms: [
          ...state.steering_algorithms,
          {
            id: payload.id,
            name: payload.name,
            crop_id: payload.crop_id,
          },
        ],
      };
    }

    case deleteSteeringAlgorithm.SUCCESS: {
      const steering_algorithms = state.steering_algorithms.filter(
        ({ id }) => id !== payload.id
      );

      return {
        ...state,
        steering_algorithms,
      };
    }

    case updateSteeringAlgorithm.SUCCESS: {
      const newList = [...state.steering_algorithms];
      const index = newList.findIndex(({ id }) => id === payload.id);
      newList[index] = {
        id: payload.id,
        name: payload.name,
        crop_id: payload.crop_id,
      };

      return {
        ...state,
        steering_algorithms: newList,
      };
    }
    // endregion

    // region Soil Information
    case createSoilInformation.SUCCESS:
    case createCurrentSoilInformationByLocalization.SUCCESS:
    case copySoilInformation.SUCCESS: {
      return {
        ...state,
        soil_informations: [...state.soil_informations, payload],
      };
    }

    case deleteSoilInformation.SUCCESS: {
      const soil_informations = state.soil_informations.filter(
        ({ id }) => id !== payload.id
      );

      return {
        ...state,
        soil_informations,
      };
    }

    case updateSoilInformation.SUCCESS: {
      const newList = [...state.soil_informations];
      const index = newList.findIndex(({ id }) => id === payload.id);
      newList[index] = {
        ...newList[index],
        id: payload.id,
        name: payload.name,
      };

      return {
        ...state,
        soil_informations: newList,
      };
    }
    // endregion

    // region Sizing Optimization
    case createSizingOptimization.SUCCESS:
    case copySizingOptimization.SUCCESS: {
      return {
        ...state,
        sizing_optimizations: [
          ...state.sizing_optimizations,
          {
            id: payload.id,
            name: payload.name,
            sizing_optimization_batches: [],
          },
        ],
      };
    }

    case deleteSizingOptimization.SUCCESS: {
      const sizing_optimizations = state.sizing_optimizations.filter(
        ({ id }) => id !== payload.id
      );

      return {
        ...state,
        sizing_optimizations,
      };
    }

    case updateSizingOptimization.SUCCESS: {
      const newList = [...state.sizing_optimizations];
      const index = newList.findIndex(({ id }) => id === payload.id);
      newList[index] = {
        ...newList[index],
        id: payload.id,
        name: payload.name,
      };

      return {
        ...state,
        sizing_optimizations: newList,
      };
    }

    case runSizingOptimizationSimulation.SUCCESS: {
      const newList = [...state.sizing_optimizations];
      const index = newList.findIndex(
        ({ id }) => id.toString() === payload.sizing_optimization_id.toString()
      );
      const newResults = [...newList[index]?.sizing_optimization_batches];

      newResults.push({
        id: payload.id,
        name: payload.name,
        sizing_optimization_batch_rows:
          payload.sizing_optimization_batch_rows.map((row) => ({
            id: row.batch_result.id,
            base_batch_id: row.batch_result.base_batch_id,
            name: row.batch_result.name,
            batch_result: row.batch_result.rows.map((item) => ({
              id: item.simulation_result.id,
              name: item.simulation_result.name,
            })),
          })),
      });

      newList[index] = {
        id: newList[index].id,
        name: newList[index].name,
        sizing_optimization_batches: newResults,
      };
      return {
        ...state,
        sizing_optimizations: newList,
      };
    }
    // endregion

    default: {
      return state;
    }
  }
};

export default navigationReducer;
