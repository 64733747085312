import { all, fork } from "redux-saga/effects";
// sagas
import getCrops from "store/crops/sagas/getCrops";
import createCrop from "store/crops/sagas/createCrop";
import getSingleCrop from "store/crops/sagas/getSingleCrop";
import updateCrop from "store/crops/sagas/updateCrop";
import deleteCrop from "store/crops/sagas/deleteCrop";
import copyCrop from "store/crops/sagas/copyCrop";
import copyCropToOtherProject from "store/crops/sagas/copyCropToOtherProject";

export default function* auth() {
  yield all([
    fork(getCrops),
    fork(createCrop),
    fork(getSingleCrop),
    fork(updateCrop),
    fork(deleteCrop),
    fork(copyCrop),
    fork(copyCropToOtherProject),
  ]);
}
