import { defineMessages } from "react-intl";

export default defineMessages({
  selectTemplateType: {
    id: "CropTemplateModalMessages.selectTemplateType",
    defaultMessage: "Select Template Type",
  },
  selectTemplate: {
    id: "CropTemplateModalMessages.selectTemplate",
    defaultMessage: "Select Template",
  },
  cropDate: {
    id: "CropTemplateModalMessages.cropDate",
    defaultMessage: "Date crops",
  },
  cropDegree: {
    id: "CropTemplateModalMessages.cropDegree",
    defaultMessage: "Degree days crops",
  },
  editYear: {
    id: "CropTemplateModalMessages.editYear",
    defaultMessage: "Edit Year",
  },
  stageName: {
    id: "CropTemplateModalMessages.stageName",
    defaultMessage: "Stage Name",
  },
  cropHeight: {
    id: "CropTemplateModalMessages.cropHeight",
    defaultMessage: "Crop Height",
  },
  startDate: {
    id: "CropTemplateModalMessages.startDate",
    defaultMessage: "Start Date",
  },
  endDate: {
    id: "CropTemplateModalMessages.endDate",
    defaultMessage: "End Date",
  },
  startDegreeDay: {
    id: "CropTemplateModalMessages.startDegreeDay",
    defaultMessage: "Start Degree Day",
  },
  endDegreeDay: {
    id: "CropTemplateModalMessages.endDegreeDay",
    defaultMessage: "End Degree Day",
  },
  sowingDate: {
    id: "CropTemplateModalMessages.sowingDate",
    defaultMessage: "Sowing Date",
  },
  baseTemperature: {
    id: "CropTemplateModalMessages.baseTemperature",
    defaultMessage: "Base Temperature",
  },
  culturalCoefficient: {
    id: "CropTemplateModalMessages.culturalCoefficient",
    defaultMessage: "Cultural Coefficient",
  },
});