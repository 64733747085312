import React, { useEffect, useState } from "react";
import styled from "./styled.module.scss";
import Button from "components/common/button/Button";
import { FormattedMessage } from "react-intl";
import MainContent from "components/MainContent/MainContent";
import { ReactComponent as Plus } from "assets/icons/plus.svg";
import settingUsersMessages from "pages/SettingUsers/SettingUsersMessages";
import SettingUserCard, {
  IUser,
} from "components/SettingUserCard/SettingUserCard";
import InviteUserModal from "components/InviteUserModal/InviteUserModal";
import DeleteUserModal from "components/DeleteUserModal/DeleteUserModal";
import ChangeUserRoleModal from "components/ChangeUserRoleModal/ChangeUserRoleModal";
import ChangeUserStatusModal from "components/ChangeUserStatusModal/ChangeUserStatusModal";
import { useDispatch, useSelector } from "react-redux";
import { getUsersDataState } from "store/users/selectors";
import { useParams } from "react-router-dom";
import {
  activateUser,
  deactivateUser,
  deleteUser,
  getUsers,
  updateUser,
} from "store/users/actions";
import { getUserDataState } from "store/user/selectors";

interface IChangeRoleState {
  id: number;
  role: string;
}

const SettingUsers = () => {
  const dispatch = useDispatch();
  const { client_id } = useParams();
  const user = useSelector(getUserDataState);
  const users: IUser[] = useSelector(getUsersDataState);
  const [isInvite, setIsInvite] = useState(false);
  const [deleteId, setDeleteId] = useState<null | number>(null);
  const [changeRole, setChangeRole] = useState<null | IChangeRoleState>(null);
  const [changeStatusId, setChangeStatusId] = useState<null | number>(null);

  useEffect(() => {
    dispatch(getUsers(client_id));
  }, [client_id]);

  const onInvite = () => setIsInvite(true);
  const onCancelInvite = () => setIsInvite(false);

  const onDelete = (id: number) => () => setDeleteId(id);
  const onCancelDelete = () => setDeleteId(null);
  const onSubmitDelete = () => {
    dispatch(
      deleteUser({
        client_id,
        user_id: deleteId,
      })
    );

    onCancelDelete();
  };

  const onChangeRole = (id: number) => (role: string) => {
    setChangeRole({ id, role });
  };
  const onCancelChangeRole = () => setChangeRole(null);
  const onSubmitChangeRole = () => {
    dispatch(
      updateUser({
        client_id,
        user_id: changeRole?.id,
        role: changeRole?.role,
      })
    );

    onCancelChangeRole();
  };

  const onChangeStatus = (id: number) => () => setChangeStatusId(id);
  const onCancelChangeStatus = () => setChangeStatusId(null);
  const onSubmitChangeStatus = () => {
    const { is_active } = users.find(
      ({ id }) => id === changeStatusId
    ) as IUser;

    if (is_active) {
      dispatch(
        deactivateUser({
          client_id,
          user_id: changeStatusId,
        })
      );
    } else {
      dispatch(
        activateUser({
          client_id,
          user_id: changeStatusId,
        })
      );
    }

    onCancelChangeStatus();
  };

  return (
    <MainContent
      header={
        <>
          <h2 className={styled.headerTitle}>
            <FormattedMessage {...settingUsersMessages.userManagement} />
          </h2>

          <Button iconBefore={<Plus />} onClick={onInvite}>
            <FormattedMessage {...settingUsersMessages.inviteNewUser} />
          </Button>
        </>
      }
    >
      <div className={styled.list}>
        {users.map((item, index) => (
          <SettingUserCard
            user={item}
            key={index}
            onChangeRole={onChangeRole(item.id)}
            onChangeStatus={onChangeStatus(item.id)}
            onDelete={onDelete(item.id)}
            isYou={item.id === user?.id}
          />
        ))}
      </div>

      <InviteUserModal show={isInvite} onHide={onCancelInvite} />
      <DeleteUserModal
        show={!!deleteId}
        onSubmit={onSubmitDelete}
        onCancel={onCancelDelete}
        email={users.find(({ id }) => id === deleteId)?.email as string}
      />
      <ChangeUserRoleModal
        show={!!changeRole}
        onSubmit={onSubmitChangeRole}
        onCancel={onCancelChangeRole}
        email={users.find(({ id }) => id === changeRole?.id)?.email as string}
      />
      <ChangeUserStatusModal
        show={!!changeStatusId}
        onSubmit={onSubmitChangeStatus}
        onCancel={onCancelChangeStatus}
        email={users.find(({ id }) => id === changeStatusId)?.email as string}
      />
    </MainContent>
  );
};

export default SettingUsers;
